import { Button, Form, Typography } from "antd";
import React, { useEffect, useState } from "react";
import FormDOB from "../../components/FormDOB/FormDOB";
import FormInput from "../../components/FormInput/FormInput";
import { NUMBER_REGEX } from "../../constants/AppConstants";
import dayjs from "dayjs";
import "./LoanDetails.scss";
import { isRazorPay } from "../../services/Utility";

const { Text } = Typography;

interface IPanVerification {
  panDetails: any;
  contactDetails: any;
  actionPanContinue: Function;
  complementorUserId?:any
}

const PanVerification: React.FunctionComponent<IPanVerification> = (
  props: IPanVerification
) => {
  const { panDetails, contactDetails, actionPanContinue ,complementorUserId } = props;

  const [form] = Form.useForm() as any;

  const [availabelFields, setAvailabelFields] = useState([] as string[]);
  const [currentPosition, setCurrentPosition] = useState(0);

  useEffect(() => {
    console.log("panDetails model: ", panDetails);
    console.log("contactDetails model: ", contactDetails);

    let dob = dayjs(contactDetails?.dateOfBirth).format("YYYY-MM-DD");
    let availabelField = [] as string[];

    if (panDetails?.name != contactDetails?.firstName) {
      availabelField.push("name");
      const arr = panDetails?.name?.split(" ");
      const name = contactDetails?.firstName?.split(" ");
      name?.map((item: any) => {
        if ((item?.trim()?.length ?? 0) > 0 && arr?.includes(item?.toUpperCase())) {
          form.setFieldsValue({
            panName: panDetails?.name,
          });
        }
      });
    }
    if ((panDetails?.dob?.length ?? 0) != 0 && dob != panDetails?.dob) {
      availabelField.push("dob");
    }
    // if (contactDetails?.city != panDetails?.address?.city) {
    //   availabelField.push("city");
    // }
    // if (contactDetails?.pinCode != panDetails?.address?.pinCode) {
    //   availabelField.push("pinCode");
    // }

    setAvailabelFields(availabelField);
  }, []);

  const checkPanName = (_: any, value: any) => {
    if ((value?.length ?? 0) == 0) {
      return Promise.resolve();
    }
    if (value == panDetails?.name) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(`Name as per PAN Card does not match`));
    }
  };

  const checkDOB = (_: any, value: any) => {
    if (value == "" || value == null) {
      return Promise.resolve();
    }
    let dob = dayjs(value).format("YYYY-MM-DD");
    if (dob == panDetails?.dob) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(`DOB as per PAN Card does not match`));
    }
  };

  const checkCity = (_: any, value: any) => {
    if ((value?.length ?? 0) == 0) {
      return Promise.resolve();
    }
    if (value == panDetails?.address?.city) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(`City as per PAN Card does not match`));
    }
  };

  const checkPinCode = (_: any, value: any) => {
    if ((value?.length ?? 0) == 0) {
      return Promise.resolve();
    }
    if (value == panDetails?.address?.pinCode) {
      return Promise.resolve();
    } else {
      return Promise.reject(
        new Error(`Pincode as per PAN Card does not match`)
      );
    }
  };

  const actionOnFinish = () => {
    if (currentPosition == availabelFields?.length - 1) {
      actionPanContinue();
    } else {
      setCurrentPosition(currentPosition + 1);
    }
  };

  const renderForm = () => {
    if (availabelFields?.at(currentPosition) == "dob") {
      return (
        <FormDOB 
        className={isRazorPay(complementorUserId) ? 'razorPay_input' : ''}      
          name="dob"
          label={"DOB"}
          isRequired
          onChange={(day, month, year) => {
            // setDOB(`${year}-${month}-${day}`);
          }}
          rules={[{ validator: checkDOB }]}
        />
      );
    } else if (availabelFields?.at(currentPosition) == "city") {
      return (
        <FormInput
        className={isRazorPay(complementorUserId) ? 'razorPay_input' : ''} 
          name="city"
          label="City"
          isRequired
          rules={[{ validator: checkCity }]}
        />
      );
    } else if (availabelFields?.at(currentPosition) == "pinCode") {
      return (
        <FormInput
         className={isRazorPay(complementorUserId) ? 'razorPay_input' : ''} 
          name="pinCode"
          label="Pin Code"
          inputMode="numeric"
          isRequired
          rules={[
            {
              pattern: NUMBER_REGEX,
              message: "Please enter only number",
            },
            { validator: checkPinCode },
          ]}
        />
      );
    } else {
      return (
        <FormInput
          className={isRazorPay(complementorUserId) ? 'razorPay_input' : ''} 
          name="panName"
          label={"Name as per PAN Card"}
          isRequired
          isUpperCase
          rules={[{ validator: checkPanName }] }
        />
      );
    }
  };

  const renderMessage = () => {
    if (availabelFields?.at(currentPosition) == "dob") {
      return "PAN and profile DOB mismatch. Please enter your DOB as per your PAN card";
    } else if (availabelFields?.at(currentPosition) == "city") {
      return "PAN and profile city mismatch. Please enter your city as per your PAN card";
    } else if (availabelFields?.at(currentPosition) == "pinCode") {
      return "PAN and profile pin code mismatch. Please enter your pin code as per your PAN card";
    } else {
      return "PAN and profile name mismatch. Please enter your name as per your PAN card";
    }
  };

  return (
    <div style={{ display: "grid" }}  className={isRazorPay(complementorUserId) ? 'razorpay_pan_msg' : ''}>
      <Text >
        {renderMessage()}
      </Text>

      <Form
        layout="vertical"
        autoComplete="off"
        name="leadForm"
        style={{ paddingBlock: "20px", marginInline: "0px" }}
        form={form}
        onFinish={() => actionOnFinish()}
      >
        {renderForm()}
        <Button type="primary" htmlType="submit" size="large" className={isRazorPay(complementorUserId) ? 'razorPay_btn' : ''}>
          Save
        </Button>
      </Form>
    </div>
  );
};

export default PanVerification;
