import { Button, Card, List, Modal, Typography } from "antd";
import React from "react";
import "./BuyLeads.scss";

const { Title } = Typography;

interface ICouponModal {
    openCouponModal: boolean;
    setOpenCouponModal: any;
    setFetchDataApply: any;
    isMobile: boolean;
}

const CouponModal: React.FunctionComponent<ICouponModal> = (props: ICouponModal) => {
    const { openCouponModal, setOpenCouponModal, setFetchDataApply, isMobile } = props

    const apiData: any = [
        {
            couponCode: "LUCKY2",
            discountApplicable: "20%",
            maxDiscount: "1000"
        },
        {
            couponCode: "LUCKY5",
            discountApplicable: "50%",
            maxDiscount: "1500"
        },
        {
            couponCode: "LUCKY5",
            discountApplicable: "20%",
            maxDiscount: "1500"
        },
        {
            couponCode: "LUCKY5",
            discountApplicable: "60%",
            maxDiscount: "1200"
        },
        {
            couponCode: "LUCKY5",
            discountApplicable: "70%",
            maxDiscount: "1900"
        },
        {
            couponCode: "LUCKY5",
            discountApplicable: "80%",
            maxDiscount: "1800"
        },
        {
            couponCode: "LUCKY2",
            discountApplicable: "20%",
            maxDiscount: "1000"
        },
        {
            couponCode: "LUCKY5",
            discountApplicable: "50%",
            maxDiscount: "1600"
        },
    ]

    const onApply = (value) => {
        setFetchDataApply(value);
        setOpenCouponModal(false)
    }
    return (
        <>
            {/* <div className="couponsModal"> */}
            <Modal
                open={openCouponModal}
                width={isMobile ? "100%" : "45%"}
                closable={true}
                centered
                className="couponsModal"
                footer={null}
                onCancel={() => setOpenCouponModal(false)}
            >
                <Title level={4} className="modal-title">Available  Coupons</Title>
                <div className="coupon-list">
                    <List
                        grid={{ gutter: 24, column: 1, xs: 1, sm: 1, md: 1, lg: 1 }}
                        dataSource={apiData}
                        style={{ height: "80vh", overflowY: "scroll" }}
                        renderItem={(item: any) =>
                        (
                            <List.Item>
                                <Card className="modal-card">
                                    <div className="modal-list-item">
                                        <div>
                                            <h5 className="modal-item-header">{item.couponCode}</h5>
                                            <a>Discount Applicable:{item.discountApplicable} or Max Discount Rs.{item.maxDiscount}</a>
                                        </div>
                                        <div className={isMobile ? "mobileView-apply-but" : "apply-but-div"}>
                                            <Button onClick={() => onApply(item)} type="link" className="apply-btn"><b>APPLY</b></Button>
                                        </div>
                                    </div>
                                </Card>
                            </List.Item>
                        )}
                    />
                </div>
            </Modal>
            {/* </div> */}
        </>
    );
};

export default CouponModal;