import {
  Button,
  List,
  Modal,
  notification,
  Popconfirm,
  Spin,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import React, { useEffect, useState } from "react";
import addFile from "../../assets/add-file.svg";
import FormIcons from "../../components/FormIcons/FormIcons";
import API_SERVICE from "../../services/api-service";
import {
  downloadDocument,
  isRazorPay,
  updateDropOfStage,
} from "../../services/Utility";
import { stages } from "../complementor-widget/ComplementorWidget";
import addFileWhite from "../../assets/add-document-white.svg";
import "./BankStatement.scss";

const { Text } = Typography;
const { Dragger } = Upload;

interface IBankStatement {
  selectedStage: number;
  leadDetail: any;
  setSelectedStage: Function;
  loanType: String;
  setSelectedStageName: React.Dispatch<React.SetStateAction<stages>>;
  setIsBack: Function;
  clickedSubmitTask: Function;
  taskDefinitionKey: string;
  getPreviousTask: Function;
  addGTag: Function;
  isV2: boolean;
  complementorUserId?: any;
}

const BankStatement: React.FunctionComponent<IBankStatement> = (
  props: IBankStatement
) => {
  const {
    selectedStage,
    setSelectedStage,
    isV2,
    leadDetail,
    loanType,
    setSelectedStageName,
    setIsBack,
    clickedSubmitTask,
    taskDefinitionKey,
    getPreviousTask,
    addGTag,
    complementorUserId,
  } = props;

  const [loader, setLoader] = useState(false);
  const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null as any);
  const [fileList, setFileList] = useState([] as any);
  const [isOpenVerify, setIsOpenVerify] = useState(false);
  const [finBoxURL, setFinBoxURL] = useState("");
  const [finBoxEntityId, setFinBoxEntityId] = useState("");
  const [isFinBox, setIsFinbox] = useState(true);
  const [isNextDisable, setIsNextDisable] = useState(true);

  const uploadProps: UploadProps = {
    accept: "image/png, image/jpeg, application/pdf",
    listType: "text",
    onRemove: (file) => { },
    beforeUpload: (file) => {
      return false;
    },
    onChange: (files) => {
      const status = files?.file?.status ?? "";
      if (status !== "removed") {
        // setFileList([...fileList, files.file]);
        onUpload(files.file);
      } else {
        // setFileList([]);
      }
    },
    fileList: [],
  };

  const onUpload = (acceptedFiles: any) => {
    const allowedAttechmentTypes = [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
    ];
    console.log(
      "uploadedFile: ",
      allowedAttechmentTypes.indexOf(acceptedFiles?.type)
    );
    if (allowedAttechmentTypes.indexOf(acceptedFiles?.type) === -1) {
      notification.error({
        message: "Please attach only image(jpg/jpeg/png) or pdf file.",
      });
      return;
    }

    console.log("acceptedFiles: ---", acceptedFiles);
    setUploadedFile(acceptedFiles);
    uploadDocumentHandler(acceptedFiles);
  };

  const uploadDocumentHandler = (file: any) => {
    console.log("file: ---- ", file);
    const formData = new FormData();
    formData.append("file", file);
    setLoader(true);
    API_SERVICE.fileUpload(formData)
      .then(({ data }) => {
        setIsNextDisable(false);
        if (data) {
          uploadDocumentFile(data, file);
        }
      })
      .catch((e: any) => {
        setLoader(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const uploadDocumentFile = (imageUrl: any, file: any) => {
    const payload = {
      documentURL: imageUrl,
      documentName: file.name,
      documentType: isV2 ? "BANK_STATEMENT" : "BANK_STATEMENT",
      documentSubType: "BANK_STATEMENT",
    };
    setLoader(true);
    API_SERVICE.addDocument(payload, leadDetail?.caseDetails?.documentSetId)
      .then(({ data }) => {
        if (data) {
          if (!isV2) {
            updateDropOfStage("BANK_STATEMENT_UPLOADED");
          }

          if (!isDocumentUploaded) {
            setIsDocumentUploaded(true);
            updateCaseStatus();
          }
          setFileList([...fileList, data?.payload]);
        }
      })
      .catch((e: any) => {
        setLoader(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const updateCaseStatus = () => {
    let params = {
      comments: "DOCUMENTATION",
      status: "DOCUMENTATION",
    };

    API_SERVICE.updateCaseStatus(leadDetail?.caseDetails?.caseId, params)
      .then((data) => {
        if (data) {
          console.log("data case status ", data);
        }
      })
      .catch((e: any) => {
        console.log("data case error ", e);
      });
  };

  const onDelete = (doc: any) => {
    console.log("doc", doc);
    setLoader(true);
    API_SERVICE.deleteDocument(doc.businessId, doc.businessDocumentId)
      .then(({ data }) => {
        setLoader(false);
        if (data) {
          const tmpDocumentList = fileList?.filter((docItem: any) => {
            return docItem?.businessDocumentId !== doc.businessDocumentId;
          });
          setFileList(tmpDocumentList);
          if ((tmpDocumentList?.length ?? 0) === 0 && !isV2) {
            updateDropOfStage("INCOME_COMPLETED");
          }
          if (!isDocumentUploaded) {
            setIsDocumentUploaded(true);
          }
        }
      })
      .catch((e: any) => {
        setLoader(false);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getDocumentList = () => {
    const documentSetId = leadDetail?.caseDetails?.documentSetId;
    API_SERVICE.getDocumentList(documentSetId)
      .then(({ data }) => {
        if (data) {
          if (data?.payload?.length > 0) {
            let filtered = data?.payload?.filter(
              (doc: any) => doc.documentSubType === "BANK_STATEMENT"
            );
            setFileList(filtered);
            setIsNextDisable(false);
          } else {
            setFileList([]);
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getFinboxURL = () => {
    localStorage.setItem("getAnalysis", "false");
    API_SERVICE.getFinboxURL(leadDetail?.caseDetails?.caseId ?? "caseId")
      .then(({ data }) => {
        if (data) {
          setFinBoxURL(data?.redirect_url);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const handleMessage = (event: any) => {
    if (event?.data?.type === "finbox-bankconnect") {
      console.log("Event -> ", event);
      if (event?.data?.status === "exit") {
        setFinBoxURL("");
      } else if (event?.data?.status === "success") {
        setFinBoxURL("");
        const entityId =
          (finBoxEntityId?.length ?? 0) > 0
            ? finBoxEntityId
            : event?.data?.payload?.entityId;
        setFinBoxEntityId(entityId);
        const getAnalysis = localStorage.getItem("getAnalysis");
        if (getAnalysis !== "true") {
          localStorage.setItem("getAnalysis", "true");
          setLoader(true);
          API_SERVICE.getAnalysis(
            entityId,
            leadDetail?.caseDetails?.caseId ?? ""
          )
            .then(({ data }) => {
              if (!isV2) {
                updateDropOfStage("BANK_STATEMENT_UPLOADED");
              }
              getDocumentList();
              updateCaseStatus();
            })
            .catch((e: any) => {
              notification.error({ message: API_SERVICE.handleErrors(e) });
            })
            .finally(() => {
              setLoader(false);
            });
        }
      }
    }
  };

  useEffect(() => {
    setIsFinbox(
      !["user_task_upload_bank_statements", "documentation_user_task"].includes(
        taskDefinitionKey
      )
    );
    // if (API_BASE_URL.includes("uat")) {
    //   setIsFinbox(taskDefinitionKey != "user_task_upload_bank_statements");
    // } else {
    //   setIsFinbox(false);
    // }
    getDocumentList();
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const getShowFinboxStatus = () => {
    setLoader(true);
    API_SERVICE.getShowFinboxStatus()
      .then(({ data }) => {
        if (data?.payload) {
          getFinboxURL();
        } else {
          getAnalysisCheck();
        }
      }).catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoader(false);
      });
  }

  const renderFinBox = () => {
    return (
      <Modal
        // title="Upload New Document:"
        centered
        // width={400}
        // onCancel={() => setFinBoxURL("")}
        closable={false}
        footer={null}
        open={finBoxURL.length > 0}
        className="modal-finbox"
      >
        <iframe
          aria-hidden="false"
          frameBorder="0"
          style={{ height: "500px", width: "100%" }}
          src={finBoxURL}
          onDragExit={() => {
            console.log("On exit");
          }}
        />
      </Modal>
    );
  };

  const renderVerifyModel = () => {
    return (
      <Modal
        className={isRazorPay(complementorUserId) ? "razor_pay_modal" : ""}
        title="Do you want to upload another bank statement?"
        open={isOpenVerify}
        footer={[
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className={
                isRazorPay(complementorUserId) ? "razorPay_btn_cancel" : ""
              }
              key="back"
              style={{ paddingInline: "30px" }}
              onClick={() => {
                setIsOpenVerify(false);
                setSelectedStage(selectedStage + 1);
                if (
                  leadDetail?.loanType != "Home Loan" &&
                  (leadDetail?.applicationSubType === "SALARIED" ||
                    leadDetail?.loanType === "Personal Loan")
                  // || !API_BASE_URL.includes("uat")
                ) {
                  setSelectedStageName("offer");
                } else {
                  clickedSubmitTask({});
                }
                addGTag("upload document");
              }}
            >
              No
            </Button>
            <Button
              className={isRazorPay(complementorUserId) ? "razorPay_btn" : ""}
              key="submit"
              type="primary"
              style={{ paddingInline: "30px" }}
              onClick={() => {
                if (isFinBox) {
                 getShowFinboxStatus();
                }
                setIsOpenVerify(false);
              }}
            >
              Yes
            </Button>
          </div>,
        ]}
        width={400}
        centered
        closable={false}
      >
        {/* <Text>Please ensure you entered the correct mobile number</Text> */}
      </Modal>
    );
  };

  const renderUploadView = () => {
    return (
      <>
        <p className="ant-upload-drag-icon">
          {
            <img
              src={isRazorPay(complementorUserId) ? addFileWhite : addFile}
              alt=""
              className="icon"
            />
          }
        </p>
        <p
          className={
            isRazorPay(complementorUserId) ? "razorPay-uploadText" : ""
          }
          style={{
            paddingInline: "15px",
            maxWidth: "400px",
            whiteSpace: "break-spaces",
          }}
        >
          {loanType === "PERSONAL_LOAN_ONLY"
            ? "Click here to upload your bank statement for the account where your salary is credited for the last 12 months"
            : "Click here to upload your bank statement for the last 12 months."}
        </p>
      </>
    );
  };

  const getAnalysisCheck = () => {
    setLoader(true);
    API_SERVICE.getAnalysisCheck(leadDetail?.caseDetails?.caseId ?? "caseId")
      .then(({ data }) => {
        if (!isV2) {
          updateDropOfStage("BANK_STATEMENT_UPLOADED");
        }
        getDocumentList();
        updateCaseStatus();
        setIsNextDisable(false);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setLoader(false));
  };

  const onOpenUpload = () => {
    getShowFinboxStatus();
  };

  return (
    <>
      {finBoxURL.length > 0 && renderFinBox()}
      {renderVerifyModel()}
      <div className={"main-bank-Statements-div" + (isV2 ? " v2" : "")}>
        {isFinBox ? (
          <Button className="div-UploadView" onClick={() => onOpenUpload()}>
            {renderUploadView()}
          </Button>
        ) : (
          <Dragger {...uploadProps}>{renderUploadView()}</Dragger>
        )}

        {fileList?.length > 0 && (
          <List
            dataSource={fileList}
            renderItem={(document: any) => (
              <List.Item className="list-item">
                <div className="item-statements">
                  <div className="icon-text">
                    {isRazorPay(complementorUserId) ? (
                      <FormIcons type="fileWhite" />
                    ) : (
                      <FormIcons type="file" />
                    )}
                    <Text
                      className={
                        "ml-6" +
                        (isRazorPay(complementorUserId)
                          ? " razorPay-uploadDoc-text"
                          : "")
                      }
                    >
                      {document.documentName ?? "documentName"}
                    </Text>
                  </div>
                  <div className="df">
                    <>
                      <Button
                        type="text"
                        onClick={() => {
                          downloadDocument(
                            document.businessId,
                            document.businessDocumentId
                          );
                        }}
                        icon={
                          isRazorPay(complementorUserId) ? (
                            <FormIcons type="downloadWhite" />
                          ) : (
                            <FormIcons type="download" />
                          )
                        }
                      />
                      <Popconfirm
                        title="Are you sure you want to delete this document?"
                        onConfirm={(event) => {
                          console.log("Delet");
                          onDelete(document);
                        }}
                      >
                        <Button
                          type="text"
                          icon={<FormIcons type="delete" />}
                        />
                      </Popconfirm>
                    </>
                  </div>
                </div>
              </List.Item>
            )}
          />
        )}
        <div
          className={
            "bank-Statements-btn-next-view" +
            (isRazorPay(complementorUserId)
              ? " razorpay-bank-Statements-btn-next-view"
              : "")
          }
        >
          <Button
            type="default"
            htmlType="button"
            size="large"
            // style={{ display: "none" }}
            onClick={() => {
              if (
                leadDetail?.applicationSubType === "SALARIED" ||
                leadDetail?.loanType === "Personal Loan"
                // || !API_BASE_URL.includes("uat")
              ) {
                setIsBack(true);
                setSelectedStage(selectedStage - 1);
                setSelectedStageName("loanDetail");
              } else {
                getPreviousTask({ loanType: leadDetail?.loanType });
              }
            }}
          >
            Back
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            disabled={
              (isFinBox)
                ? isNextDisable
                : fileList.length <= 0
            }
            onClick={() => {
              setIsBack(false);
              // setSelectedStage(selectedStage + 1);
              setIsOpenVerify(true);
              // setSelectedStageName("offer");
            }}
          >
            Next
          </Button>
        </div>
      </div>

      {loader && (
        <div
          className={
            "loader-view" + (isRazorPay(complementorUserId) ? " razorpay" : "")
          }
        >
          <Spin
            tip="We are processing your request, please wait"
            size="large"
          />
        </div>
      )}
    </>
  );
};

export default BankStatement;
