import { Button, Card, Col, Form, Row, notification } from "antd";
import React from "react";
import FormInput from "../../components/FormInput/FormInput";
import FormTextArea from "../../components/FormTextArea/FormTextArea";
import API_SERVICE from "../../services/api-service";
import { GST_REGEX, PHONE_NUMBER_REGEX } from "../../constants/AppConstants";
import { stages } from "../complementor-widget/ComplementorWidget";
import "./BuyLeads.scss";

interface IBillingDetails {
  isMobile: boolean;
  costPerLead: any;
  numberOfLeads: any;
  updateTransactionList: any;
  setSelectedStageName: React.Dispatch<React.SetStateAction<stages>>;
}

const BillingDetails: React.FunctionComponent<IBillingDetails> = (
  props: IBillingDetails
) => {
  const {
    isMobile,
    costPerLead,
    numberOfLeads,
    updateTransactionList,
    setSelectedStageName,
  } = props;

  const userStr = localStorage.getItem("user");
  const user = JSON.parse(userStr as string);

  const billingDetailsList = (value) => {
    console.log("value: ", value);
    const payload = {
      ...updateTransactionList,
      dropOffStage: "BILLING_DETAILS_SUBMITTED",
      billingDetails: value,
    };

    API_SERVICE.updateTransaction(user.buyLeadsTransactionId, payload)
      .then(({ data }) => {
        setSelectedStageName("buyLeadOffer");
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const renderBillingDetails = () => {
    return (
      <Card title="Billing Details" className="billing-details">
        <FormInput
          label="Name of Business"
          name="nameOfBusiness"
          placeholder="Enter Business Name"
          isRequired
        />

        <FormTextArea
          label="Billing Address"
          name="billingAddress"
          placeholder="Enter Billing Address"
          isRequired
        />

        <FormInput
          label="GST No"
          name="gstNumber"
          placeholder="Enter GST No"
          rules={[
            {
              pattern: GST_REGEX,
              message: "Please enter valid GST No",
            },
          ]}
        />

        <FormInput
          label="Phone Number"
          name="phoneNumber"
          placeholder="Enter Phone Number"
          isRequired
          rules={[
            {
              pattern: PHONE_NUMBER_REGEX,
              message: "Please enter valid Phone Number",
            },
          ]}
        />

        <FormInput
          label="Email ID"
          name="email"
          placeholder="Enter Email ID"
          isRequired
          rules={[{ type: "email", message: "Please enter valid Email Id." }]}
        />

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          style={{ paddingInline: "60px" }}
        >
          Submit
        </Button>
      </Card>
    );
  };

  const price = numberOfLeads * costPerLead;
  const GSTCount = price * (18 / 100);
  const total = price + Math.round(GSTCount);

  const renderPaymentSummary = () => {
    return (
      <Card title="Payment Summary">
        <div className="billing-div">
          <div className="billing-price-style">
            <a>No. of Leads</a>
            <a>{numberOfLeads}</a>
          </div>
          <hr className="devider-space" />
          <div className="billing-price-style">
            <a>Price</a> <a>Rs.{price}</a>
          </div>
          <hr className="devider-space" />
          <div className="billing-price-style">
            <a>GST(18%)</a>
            <a>+ Rs.{Math.round(GSTCount)}</a>
          </div>
          <hr className="devider-space" />
          <b>
            <div className="total-price-style">
              <a>Final Price</a>
              <a>Rs.{total}</a>
            </div>
          </b>
        </div>
      </Card>
    );
  };

  return (
    <>
      <div>
        <Form layout="vertical" onFinish={billingDetailsList}>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col lg={8} md={10} sm={24} xs={24}>
              {renderBillingDetails()}
            </Col>
            <Col
              lg={5}
              md={7}
              sm={24}
              xs={24}
              style={{ marginLeft: isMobile ? "" : "15px" }}
            >
              {renderPaymentSummary()}
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default BillingDetails;
