import { BrowserRouter, Route, Switch } from "react-router-dom";
import ComplementorWidget from "./screens/complementor-widget/ComplementorWidget";
import Start from "./screens/start/start";
import "./styles/app.scss";
import "./styles/_antd.scss";
import ReactGA from "react-ga4";

function App() {
  const TRACKING_ID = "G-HXYF73RDGX";
  ReactGA.initialize(TRACKING_ID);

  ReactGA.initialize([
    {
      trackingId: "G-HXYF73RDGX",
    },
  ]);

  return (
    <div style={{ height: "100vh" }}>
      {/* <ComplementorWidget /> */}
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Start />
          </Route>
          <Route exact path="/complementor-widget">
            <ComplementorWidget />
          </Route>
          <Route path="/complementor-widget/:type/:id">
            <ComplementorWidget />
          </Route>
          <Route path="/complementor-widget/:type">
            <ComplementorWidget />
          </Route>
          <Route path="/:type/:id">
            <ComplementorWidget />
          </Route>
          <Route path="/:type">
            <ComplementorWidget />
          </Route>
          <Route path="*">
            <Start />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
