/* eslint-disable import/no-anonymous-default-export */


// OLD DEV/UAT BusinessId DO NOT USE OF NOW.
//export const BUSINESS_ID = "BU7132242844";

// export const API_BASE_URL = "https://uat.oneflo.in:"; // BU6098926543 //For Dev/UAT
// export const BUSINESS_ID = "BU6098926543"; // New Business id For Dev/UAT

// export const API_BASE_URL = "https://preprod.oneflo.in:"; // BU6098926543 //For PreProd
// export const BUSINESS_ID = "BU6098926543";

export const API_BASE_URL = "https://oneflo.in:"; // BU6098926543 // For Prod
export const BUSINESS_ID = "BU6098926543";

// const DSA_API_URL = API_BASE_URL + "9090/api/dsa/";
// const COMMON_API_URL = API_BASE_URL + "9089/api/";
// const LENDER_ECO_SYSTEM_API_URL = API_BASE_URL + "9092/api/";

const DSA_API_URL = process.env.REACT_APP_DSA_API_URL;
const COMMON_API_URL = process.env.REACT_APP_COMMON_API_URL;
const LENDER_ECO_SYSTEM_API_URL = process.env.REACT_APP_LENDER_ECO_SYSTEM_API_URL;


const CLIENT_NAME = "Client Name";
const IMAGE_BASE_URL = "";
export default {
    ApiUrlOne: DSA_API_URL,
    ApiUrlTwo: COMMON_API_URL,
    ApiUrlThree: LENDER_ECO_SYSTEM_API_URL,
    // ApiUrlFour: COMMON_API_URL,
    clientName: CLIENT_NAME,
    ImageBaseUrl: IMAGE_BASE_URL,
};
