import React, { useEffect, useState } from "react";
import "formiojs/dist/formio.builder.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
// @ts-ignore
import { Form } from "react-formio";
import { stages } from "../complementor-widget/ComplementorWidget";
import { Modal, notification } from "antd";
import debounce from "lodash/debounce";
import PanVerification from "../loan-details/PanVerification";
import moment from "moment";
import EdotLoanAmount from "./edit-loan-amount";
import { isRazorPay, numberWithCommas } from "../../services/Utility";
import jsonFile from "./user_task_borrower_details.json";
import dayjs from "dayjs";
import API_SERVICE from "../../services/api-service";
import { PAN_REGEX } from "../../constants/AppConstants";
import "./low-code.scss";

interface ILowCode {
  setSelectedStageName: React.Dispatch<React.SetStateAction<stages>>;
  clickedSubmitTask: Function;
  taskDefinitionKey: any;
  finalJSONSchema: any;
  setShowList: Function;
  getPreviousTask: Function;
  finalFormData: any;
  addGTag: Function;
  leadDetail: any;
  complementorUserId: string;
  setLoader: Function;
}

const LowCode: React.FunctionComponent<ILowCode> = (props: ILowCode) => {
  const {
    setSelectedStageName,
    clickedSubmitTask,
    taskDefinitionKey,
    finalJSONSchema,
    setShowList,
    getPreviousTask,
    finalFormData,
    addGTag,
    leadDetail,
    complementorUserId,
    setLoader,
  } = props;

  const [formData, setFormData] = useState({} as any);
  const [isShowPanVerification, setIsShowPanVerification] = useState(false);
  const [panDetails, setPanDetails] = useState({} as any);
  const [editLoanAmountModal, setEditLoanAmountModal] = useState(false);
  const [showOfferInfo, setShowOfferInfo] = useState(false);

  useEffect(() => {
    if (taskDefinitionKey === "user_task_loan_details_hl") {
      setLoanDetail();
    } else if (taskDefinitionKey === "user_task_salary_details_pl") {
      setSalaryDetail();
    } else if (taskDefinitionKey === "user_task_employer_details_bnpl") {
      setEmployerDetails();
    } else if (
      ["user_task_offer_details", "user_task_offer_details_hl"].includes(
        taskDefinitionKey
      )
    ) {
      setOfferData();
    } else if (taskDefinitionKey === "user_task_business_details") {
      setBusinessData();
    }  else if (taskDefinitionKey === "user_task_borrower_and_business_details") {
      setBusinessAndpersonalDetails();
    }
    else {
      setPersonalData();
    }
  }, []);

  useEffect(() => {
    if (taskDefinitionKey === "user_task_loan_details_hl") {
      setLoanDetail();
    } else if (taskDefinitionKey === "user_task_salary_details_pl") {
      setSalaryDetail();
    } else if (
      ["user_task_offer_details", "user_task_offer_details_hl", "user_task_bureau_offer_bil"].includes(
        taskDefinitionKey
      )
    ) {
      setOfferData();
    } else if (taskDefinitionKey === "user_task_business_details") {
      setBusinessData();
    } else if (taskDefinitionKey === "user_task_employer_details_bnpl") {
      setEmployerDetails();
    } else if (taskDefinitionKey === "user_task_borrower_and_business_details") {
      setBusinessAndpersonalDetails();
    } else {
      setPersonalData();
    }
  }, [finalFormData, finalJSONSchema]);

  const setEmployerDetails = () => {
    const leadDataTmp = leadDetail ?? finalFormData;
    const loanDetails =
      leadDetail?.caseDetails?.loanDetails ??
      finalFormData?.caseDetails?.loanDetails;

    const monthlyInHandSalary = loanDetails?.monthlyInHandSalary ?? "";
    const bnplLoanDetails =
      leadDataTmp?.dsaCaseAdditionalFileds?.bnplLoanDetails;

    setFormData({
      applicationSubType:
        leadDataTmp?.applicationSubTypeKey ??
        leadDataTmp?.applicationSubType ??
        "",
      entityOrEmployerName:
        leadDataTmp?.entityOrEmployerName ??
        bnplLoanDetails?.entityOrEmployerName ??
        "",
      monthlyInHandSalary: monthlyInHandSalary == 0 ? "" : monthlyInHandSalary,
      officePincode:
        leadDataTmp?.officePincode ?? bnplLoanDetails?.officePinCode ?? "",
      bnplApplicantType: bnplLoanDetails?.bnplApplicantType ?? "",
      studentName: bnplLoanDetails?.studentFullName ?? "",
      studentDOB: bnplLoanDetails?.studentDOB ?? "",
      instituteName: bnplLoanDetails?.instituteName ?? "",
      branchName: bnplLoanDetails?.branchName ?? "",
      courseName: bnplLoanDetails?.courseName ?? "",
      orderAmount: loanDetails?.loanAmount ?? "",
    });
  };

  const setLoanDetail = () => {
    const loanDetails =
      leadDetail?.caseDetails?.loanDetails ??
      finalFormData?.caseDetails?.loanDetails;

    const currentEmis = loanDetails?.currentEmis ?? "";
    const loanAmount = loanDetails?.loanAmount ?? "";
    const monthlyInHandSalary = loanDetails?.monthlyInHandSalary ?? "";
    const costOfProperty = loanDetails?.costOfProperty ?? "";

    setFormData({
      entityOrEmployerName: leadDetail?.entityOrEmployerName ?? "",
      monthlyInHandSalary: monthlyInHandSalary == 0 ? "" : monthlyInHandSalary,
      loanAmount: loanAmount == 0 ? "" : loanAmount,
      monthlyEmi: currentEmis == 0 ? "" : currentEmis,
      costOfProperty: costOfProperty == 0 ? "" : costOfProperty,
      propertyIdentified: costOfProperty > 0 ? "yes" : "no",
    });
  };

  const setSalaryDetail = () => {
    let contactDetails = leadDetail?.contactDetails;
    const loanDetails =
      leadDetail?.caseDetails?.loanDetails ??
      finalFormData?.caseDetails?.loanDetails;

    const currentEmis = loanDetails?.currentEmis ?? "";
    const loanAmount = loanDetails?.loanAmount ?? "";
    const monthlyInHandSalary = loanDetails?.monthlyInHandSalary ?? "";

    setFormData({
      dateOfBirth: contactDetails?.dateOfBirth ?? "",
      entityOrEmployerName: leadDetail?.entityOrEmployerName ?? "",
      monthlyInHandSalary: monthlyInHandSalary == 0 ? "" : monthlyInHandSalary,
      loanAmount: loanAmount == 0 ? "" : loanAmount,
      monthlyEmi: currentEmis == 0 ? "" : currentEmis,
      officePincode: leadDetail?.officePincode ?? "",
    });
  };

  const setOfferData = () => {
    const loanType = leadDetail?.loanType ?? finalFormData?.loanType;

    const tenure =
      loanType == "Home Loan"
        ? finalFormData?.tenureInYears
        : finalFormData?.tenureInMonths;

    setFormData({
      youAreEligibleForLoan: `You are eligible for a maximum loan of ₹${numberWithCommas(
        finalFormData?.maxAllowedLoanAmount ?? 0
      )}`,
      loanAmount: `Rs. ${numberWithCommas(finalFormData?.loanAmount ?? 0)}`,
      rateOfInterest: finalFormData?.interest + "%",
      emi: `Rs. ${numberWithCommas(finalFormData?.totalEMI)}`,
      tenure: tenure + (loanType == "Home Loan" ? " Years" : " Months"),
    });
  };

  const setPersonalData = () => {
    let contactDetails = leadDetail?.contactDetails;
    let applicationSubType =
      leadDetail?.applicationSubTypeKey ?? leadDetail?.applicationSubType;
    let address = leadDetail?.address ?? finalFormData?.address;

    const loanDetails =
      leadDetail?.loanDetails ?? leadDetail?.caseDetails?.loanDetails;
    let isConsent = loanDetails?.consentDetails?.isConsent;

    setFormData({
      firstName: contactDetails?.firstName ?? "",
      mobileNumber: contactDetails?.mobileNumber ?? "",
    });

    console.log("finalFormData:++++++ ", finalFormData);
    if (
      [
        "user_task_borrower_details_pl",
        "user_task_borrower_details_bnpl",
        "user_task_borrower_details_hl",
      ].includes(taskDefinitionKey) &&
      Object.keys(finalFormData).length !== 0 &&
      Object.keys(leadDetail ?? {}).length === 0
    ) {
      console.log("resert contactDetails ");
      applicationSubType = finalFormData?.applicationSubType;
      if (Object.keys(finalFormData?.contactDetails).length !== 0) {
        contactDetails = finalFormData?.contactDetails;
      }
      if (
        Object.keys(finalFormData?.caseDetails?.loanDetails?.consentDetails)
          .length !== 0
      ) {
        isConsent =
          finalFormData?.caseDetails?.loanDetails?.consentDetails?.isConsent;
      }
    }

    const pan =
      (contactDetails?.pan?.length ?? 0) === 0 ||
      contactDetails?.pan === "PENDING"
        ? ""
        : contactDetails.pan;

    const tmpFormData = {
      ...formData,
      applicationSubType: applicationSubType ?? "",
      firstName: contactDetails?.firstName ?? "",
      mobileNumber: contactDetails?.mobileNumber ?? "",
      emailId: contactDetails?.emailId ?? "",
      gender: contactDetails?.gender ?? "",
      pan: pan ?? "",
      dateOfBirth: contactDetails?.dateOfBirth ?? "",
      city: address?.city ?? "",
      pinCode: address?.pinCode ?? "",
      panName: pan ? contactDetails?.firstName ?? "" : "",
      isConsent: isConsent ?? false,
    };

    localStorage.setItem("emailLowCode", contactDetails?.emailId ?? "");
    localStorage.setItem("genderLowCode", contactDetails?.gender ?? "");

    setFormData(tmpFormData);

    if (
      pan &&
      !["user_task_borrower_details_bnpl"].includes(taskDefinitionKey)
    ) {
      getPanDetails(pan, tmpFormData);
    }
  };

  const setBusinessData = () => {
    let contactDetails = leadDetail?.contactDetails;
    let address = leadDetail?.address;
    let formDataTmp = leadDetail;

    // if (Object.keys(finalFormData).length !== 0) {
    //   formDataTmp = finalFormData;
    //   if (Object.keys(finalFormData?.contactDetails).length !== 0) {
    //     contactDetails = finalFormData?.contactDetails;
    //   }
    //   if (Object.keys(finalFormData?.address).length !== 0) {
    //     address = finalFormData?.address;
    //   }
    // }

    const pan =
      (contactDetails?.pan?.length ?? 0) === 0 ||
      contactDetails?.pan === "PENDING"
        ? ""
        : contactDetails.pan;

    const loanAmount = formDataTmp?.caseDetails?.loanDetails?.loanAmount ?? "";
    const businessTurnOver = formDataTmp?.businessTurnOver ?? "";
    const monthlyEmi = formDataTmp?.monthlyEmi ?? "";

    setFormData({
      ...formData,
      city: address.city ?? address?.city ?? "",
      dateOfBirth: contactDetails?.dateOfBirth ?? "",
      pinCode: address.pinCode ?? "",
      pan: pan ?? "",
      applicationSubType:
        formDataTmp?.applicationSubTypeKey ??
        formDataTmp?.applicationSubType ??
        "",
      entityOrEmployerName: formDataTmp?.entityOrEmployerName ?? "",
      businessVintage: formDataTmp?.businessVintage ?? "",
      businessProfile: formDataTmp?.businessProfile ?? undefined,
      businessTurnOver: businessTurnOver == 0 ? "" : businessTurnOver,
      monthlyEmi: monthlyEmi == 0 ? "" : monthlyEmi,
      loanAmount: loanAmount == 0 ? "" : loanAmount,
      propertyOwnership:
        formDataTmp?.caseDetails?.propertyOwnership ?? undefined,
    });
  };

  const setBusinessAndpersonalDetails = () => {
    let contactDetails = leadDetail?.contactDetails;
    let applicationSubType =
      leadDetail?.applicationSubTypeKey ?? leadDetail?.applicationSubType;
    let address = leadDetail?.address ?? finalFormData?.address;

    const pan =
      (contactDetails?.pan?.length ?? 0) === 0 ||
      contactDetails?.pan === "PENDING"
        ? ""
        : contactDetails.pan;

    console.log('leadDetail', leadDetail);
    const tempFormData = {
      applicationSubType: applicationSubType ?? "",
      firstName: contactDetails?.firstName ?? "",
      mobileNumber: contactDetails?.mobileNumber ?? "",
      emailId: contactDetails?.emailId ?? "",
      pinCode: leadDetail?.officePincode,
      pan: pan ?? "",
      dateOfBirth1: contactDetails?.dateOfBirth,
      dateOfBirth: contactDetails?.dateOfBirth,
      entityOrEmployerName: leadDetail?.entityOrEmployerName ?? "",
      businessProfile: leadDetail?.businessProfile ?? "",
      businessVintage: leadDetail?.businessVintage ?? "",
      loanAmount: leadDetail?.caseDetails?.loanDetails?.loanAmount == 0 ? null : leadDetail?.caseDetails?.loanDetails?.loanAmount,
    }
    setFormData(tempFormData);
  }

  const createPayload = (formData: any) => {
    console.log("formData: ", formData);

    const leadDataTmp = leadDetail ?? finalFormData;

    const caseDetails = leadDataTmp?.caseDetails ?? finalFormData?.caseDetails;
    const address = leadDataTmp?.address ?? finalFormData?.address;

    const loanType = leadDataTmp?.loanType ?? finalFormData?.loanType;
    const loanProductId =
      leadDataTmp?.loanProductId ?? finalFormData?.loanProductId;
    const connectorId =
      leadDataTmp?.connectorId ??
      caseDetails?.connectorDetails?.userDetails?.userId ??
      complementorUserId;
    const assignToUserId =
      leadDataTmp?.assignToUserId ??
      leadDataTmp?.assignedToUserId ??
      caseDetails?.assignedToUserDetails?.userId ??
      "";
    const businessVintage = formData?.businessVintage;
    const dob =
      (formData?.dateOfBirth?.length ?? 0) > 0
        ? formData?.dateOfBirth ?? ""
        : leadDataTmp?.contactDetails?.dateOfBirth ?? "";

    const bnplLoanDetails =
      leadDataTmp?.dsaCaseAdditionalFileds?.bnplLoanDetails;

    const studendDOB =
      formData?.studentDOB ?? bnplLoanDetails?.studentDOB ?? "";

    const data = {
      dsaCaseId: caseDetails?.caseId ?? "",
      loanType: loanType,
      loanProductId: loanProductId,
      sourceOfCase: "CONNECTOR",
      address: {
        address: "",
        area: formData.city ?? address?.city,
        city: formData.city ?? address?.city,
        country: "",
        geoCode: "",
        pinCode: formData.pinCode ?? address?.pinCode,
        state: formData?.state ?? address?.state ?? "",
      },
      applicationType:
        formData?.applicationSubType?.toUpperCase() === "SELF_EMPLOYED" ||
        formData?.applicationSubType?.toUpperCase() === "SELF EMPLOYED" ||
        formData?.applicationSubType?.toUpperCase() === "SALARIED" ||
        loanType == "Personal Loan" ||
        loanType == "Home Loan"
          ? "INDIVIDUAL"
          : "BUSINESS",
      applicationSubType:
        loanType == "Personal Loan" || loanType == "Home Loan"
          ? "SALARIED"
          : loanType == "Buy Now Pay Later"
          ? formData?.applicationSubType?.toUpperCase()?.replace(" ", "_")
          : formData.applicationSubType,
      businessProfile:
        (formData?.businessProfile?.length ?? 0) === 0
          ? null
          : formData?.businessProfile ?? null,
      businessTurnOver:
        formData.businessTurnOver ?? leadDataTmp?.businessTurnOver ?? null,
      businessVintage:
        (businessVintage ?? 0) != 0
          ? businessVintage
          : leadDataTmp?.businessVintage ?? null,
      monthlyEmi: formData.monthlyEmi ?? leadDataTmp?.monthlyEmi ?? null,
      comments: "",
      assignToUserId: assignToUserId,
      backOfficeUserId: "",
      officePincode: formData.officePincode ?? "",
      officeCity: formData.officeCity ?? "",
      industryType: formData?.industryType ?? null,
      currentWorkExperience: formData?.currentWorkExperience
        ? formData?.currentWorkExperience?.split("/").at(-1) +
          "-" +
          formData?.currentWorkExperience?.split("/").at(0)
        : null,
      totalWorkExperience: formData?.totalWorkExperience
        ? formData?.totalWorkExperience?.split("/").at(-1) +
          "-" +
          formData?.totalWorkExperience?.split("/").at(0)
        : null,
      commissionDetail: {
        connectorName: "",
        finalCommissionAmount: 0,
        finalCommissionPercentage: 0,
        tentativeCommissionAmount: 0,
        tentativeCommissionPercentage: 0,
      },
      connectorId: connectorId,
      dsaCustomerId: leadDataTmp?.dsaCustomerId,
      nationality: null,
      bureauScoreStatus: null,
      contactDetails: {
        gender: (formData?.gender?.length ?? 0) === 0 ? null : formData?.gender,
        firstName: formData.firstName,
        lastName: "",
        middleName: "",
        dateOfBirth: dob ? moment(dob).format("YYYY-MM-DD").toString() : "",
        emailId: formData.emailId,
        mobileNumber: leadDataTmp?.contactDetails?.mobileNumber,
        pan: formData.pan,
      },
      entityOrEmployerName: formData.entityOrEmployerName,
      loanDetails: {
        expectedInterestRate:
          caseDetails?.loanDetails?.expectedInterestRate ?? 0,
        loanAmount:
          formData?.loanAmount ??
          formData?.orderAmount ??
          caseDetails?.loanDetails?.loanAmount,
        loanTenureInYears:
          formData?.loanTenureInYears ??
          caseDetails?.loanDetails?.loanTenureInYears ??
          0,
        currentEmis:
          formData.currentEmis ??
          formData.monthlyEmi ??
          caseDetails?.loanDetails?.currentEmis,
        monthlyInHandSalary:
          formData?.monthlyInHandSalary ??
          formData?.salary ??
          caseDetails?.loanDetails?.monthlyInHandSalary,
        costOfProperty: formData?.costOfProperty,
        consentDetails: {
          consentDate:
            caseDetails?.loanDetails?.consentDetails?.consentDate ?? null,
          isConsent:
            formData?.isConsent ??
            caseDetails?.loanDetails?.consentDetails?.isConsent,
          ipAddress:
            caseDetails?.loanDetails?.consentDetails?.ipAddress ?? null,
          deviceType:
            caseDetails?.loanDetails?.consentDetails?.deviceType ?? null,
        },
        educationLoanDetails: {
          timeOfStudy: formData?.timeofStudy
            ? formData?.timeofStudy?.split("/").at(-1) +
              "-" +
              formData?.timeofStudy?.split("/").at(0)
            : null,
          placeOfStudy: formData?.placeOfStudy ?? null,
          admissionStatus: formData?.admissionStatus ?? null,
        },
      },
      propertyOwnership:
        (formData?.propertyOwnership?.length ?? 0) === 0
          ? null
          : formData?.propertyOwnership,
      dsaCaseAdditionalFileds: {
        bnplLoanDetails: {
          instituteName:
            formData?.instituteName ?? bnplLoanDetails?.instituteName ?? "",
          branchName: formData?.branchName ?? bnplLoanDetails?.branchName ?? "",
          courseName: formData?.courseName ?? bnplLoanDetails?.courseName ?? "",
          studentFullName:
            formData?.studentName ?? bnplLoanDetails?.studentFullName ?? "",
          studentDOB:
            (studendDOB?.length ?? 0) == 0
              ? ""
              : moment(studendDOB).format("yyyy-MM-DD"),
          bnplApplicantType:
            formData?.bnplApplicantType ??
            bnplLoanDetails?.bnplApplicantType ??
            null,
          officePinCode:
            formData?.officePincode ?? bnplLoanDetails?.officePinCode ?? "",
          entityOrEmployerName:
            formData?.entityOrEmployerName ??
            bnplLoanDetails?.entityOrEmployerName ??
            "",
        },
      },
    };
    return data;
  };

  const checkValidTimeofStudy = (e) => {
    const arr = e?.timeofStudy?.split("/");
    const date = arr?.at(0) + "-" + moment().format("DD") + "-" + arr?.at(-1);
    if (
      e?.timeofStudy &&
      !moment(date).isSameOrAfter(moment(moment().format("yyyy-MM-DD")))
    ) {
      notification.error({
        message:
          "Please Select Time of Study greater than or equal to current month",
      });
      return false;
    }
    return true;
  };

  const submitTask = (e: any) => {
    if (checkValidTimeofStudy(e)) {
      const payload = createPayload(e);
      console.log("payload: ", payload);
      clickedSubmitTask(payload);
    }
  };

  const checkPanDetail = (data) => {
    // if (panDetails == null || Object.keys(panDetails).length === 0) {
    //   return true;
    // }
    if ((data?.panName?.length ?? 0) == 0) {
      return true;
    }

    setPanDetails({
      pan: data?.pan,
      name: data?.panName,
      dob: data?.dobHidden,
    });
    let dob = dayjs(data?.dobHidden).format("YYYY-MM-DD");
    let dobData = dayjs(data?.dateOfBirth).format("YYYY-MM-DD");
    if (data?.firstName != data?.panName) {
      setIsShowPanVerification(true);
      return false;
    } else if ((data?.dobHidden?.length ?? 0) != 0 && dobData != dob) {
      setIsShowPanVerification(true);
      return false;
    }
    // else if (data?.city != panDetails?.address?.city) {
    //   setIsShowPanVerification(true);
    //   return false;
    // }
    // else if (data?.pinCode != panDetails?.address?.pinCode) {
    //   setIsShowPanVerification(true);
    //   return false;
    // }
    return true;
  };

  const handleFormSubmit = (e: any) => {
    console.log("handleFormSubmit: ", e);
    setFormData({ ...formData, ...e?.data });
    if (
      [
        "user_task_borrower_details",
        "user_task_borrower_details_bnpl",
        "user_task_borrower_details_pl",
        "user_task_borrower_details_hl",
      ].includes(taskDefinitionKey)
    ) {
      if (
        (e?.data?.applicationSubType === "SELF_EMPLOYED" ||
          e?.data?.applicationSubType === "SOLE_PROPRIETOR" ||
          [
            "user_task_borrower_details_bnpl",
            "user_task_borrower_details_pl",
            "user_task_borrower_details_hl",
          ].includes(taskDefinitionKey)) &&
        !checkPanDetail(e?.data)
      ) {
        if ((e?.data?.panName?.length ?? 0) == 0) {
          notification.error({ message: "Please enter valid pan" });
        } else {
          setPanDetails({
            pan: e?.data?.pan,
            name: e?.data?.panName,
            dob: e?.data?.dobHidden,
          });
          setIsShowPanVerification(true);
        }
      } else {
        submitTask({ ...formData, ...e?.data });
        addGTag("Personal Details Upload");
      }
    } else if (
      [
        "user_task_business_details",
        "user_task_salary_details_pl",
        "user_task_employer_details_bnpl",
        "user_task_loan_details_hl",
        "user_task_not_eligible_offer_details",
        "user_task_borrower_and_loan_details_el",
        "user_task_borrower_and_business_details",
      ].includes(taskDefinitionKey)
    ) {
      submitTask({ ...formData, ...e?.data });
      addGTag("Business Details Upload");
    } else if (
      ["user_task_offer_details", "user_task_offer_details_hl", "user_task_bureau_offer_bil"].includes(
        taskDefinitionKey
      )
    ) {
      // TODO:
      // const loanType = leadDetail?.loanType ?? finalFormData?.loanType;
      // if(loanType == "Home Loan" && finalFormData?.loanAmount > finalFormData?.maxAllowedLoanAmount ) {
      //   notification.error({ message: "Requested Loan amount should be less than eligible amount" });
      //   return;
      // }
      clickedSubmitTask({ isOfferReviewRequired: false });
      addGTag("Offer Details Upload");
    }
  };

  const handleCustomEvent = (customEvent: any) => {
    console.log("customEvent: ", customEvent);
    if (customEvent?.type === "eventFromBack") {
      console.log("customEvent: ", customEvent?.type);
      if (
        [
          "user_task_business_details",
          "user_task_salary_details_pl",
          "user_task_employer_details_bnpl",
          "user_task_loan_details_hl",
        ].includes(taskDefinitionKey)
      ) {
        getPreviousTask({
          loanType: leadDetail?.loanType ?? finalFormData?.loanType,
        });
      }
    } else if (customEvent?.type === "eventFromClose") {
      if (
        [
          "user_task_offer_details",
          "user_task_offer_details_hl",
          "user_task_bureau_offer_bil",
          "user_task_not_eligible_offer_details",
          "user_task_thank_you_page_el",
        ].includes(taskDefinitionKey)
      ) {
        setSelectedStageName("applicationDetails");
        setShowList(true);
      }
    } else if (customEvent.type === "edit-loan-amount") {
      setEditLoanAmountModal(true);
    } else if (customEvent.type === "info-loan-amount") {
      setShowOfferInfo(true);
    }
  };

  const setPanDetailToForm = (panDetail: any, dataTmp: any) => {
    console.log("Pan Detail: ", panDetail);

    const isTypeBusiness = taskDefinitionKey === "user_task_business_details";

    let dt = dayjs(panDetail?.dob, "YYYY-MM-DD");
    const diff = dayjs().diff(dt, "year");
    let dat = panDetail?.dob ? dayjs(panDetail?.dob).format("YYYY-MM-DD") : "";

    console.log("dat: ", dat);
    console.log("formData: ", formData);

    const emailLowCode = localStorage.getItem("emailLowCode") as any;
    const genderLowCode = localStorage.getItem("genderLowCode") as any;

    let tmpFormData = {
      ...formData,
      ...dataTmp,
      pan: panDetail?.pan ?? "",
      panName: panDetail?.name ?? "",
      dateOfBirth: dat ?? "",
      entityOrEmployerName: isTypeBusiness ? panDetail?.name : "",
      businessVintage: isTypeBusiness ? diff : "",
    };

    console.log("emailLowCode: ", emailLowCode);
    if ((emailLowCode?.length ?? 0) > 0) {
      tmpFormData = {
        ...tmpFormData,
        emailId: emailLowCode,
      };
    }

    console.log("genderLowCode: ", genderLowCode);
    if ((genderLowCode?.length ?? 0) > 0) {
      tmpFormData = {
        ...tmpFormData,
        gender: genderLowCode,
      };
    }

    setFormData(tmpFormData);
  };

  const getPanDetails = (pan: any, dataTmp: any) => {
    if (!pan || pan === "" || pan === "N/A") {
      return;
    }
    return;

    const params = {
      panNumber: pan,
      consent: "Y",
    };
    setLoader(true);
    API_SERVICE.getPANdobDetails(params)
      .then(({ data }) => {
        if (data?.payload?.result) {
          const panDetail = { ...data?.payload?.result, pan: pan };
          setPanDetailToForm(panDetail, dataTmp);
          setPanDetails(panDetail);
        }
      })
      .catch((e) => {
        setPanDetails({});
        setPanDetailToForm({ pan: pan }, dataTmp);
        // notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleOnChange = (submission: any) => {
    if ((submission?.changed?.component?.key ?? "") === "emailId") {
      let value = "" as string;
      value = submission?.changed?.value ?? "";
      console.log("handleOnChange emailId:  ", value);
      localStorage.setItem("emailLowCode", value);
    } else if ((submission?.changed?.component?.key ?? "") === "gender") {
      let value = "" as string;
      value = submission?.changed?.value ?? "";
      console.log("handleOnChange gender:  ", value);
      localStorage.setItem("genderLowCode", value);
    } else if (
      (submission?.changed?.component?.key ?? "") === "applicationSubType" &&
      !["user_task_employer_details_bnpl", "user_task_borrower_and_loan_details_el"].includes(taskDefinitionKey)
    ) {
      setFormData({
        ...formData,
        applicationSubType: submission.data.applicationSubType ?? "",
        emailId: submission.data.emailId ?? "",
      });
    } else if (
      (submission?.changed?.component?.key ?? "") === "pan1" &&
      !["user_task_borrower_details_bnpl"].includes(taskDefinitionKey)
    ) {
      const value = submission?.changed?.value;
      console.log("value:   ", value);
      const regex = new RegExp(PAN_REGEX);
      if (regex.test(submission?.changed?.value)) {
        if (submission?.changed?.value != panDetails?.pan) {
          console.log("getpan:   ", value);
          getPanDetails(submission?.changed?.value, submission?.data);
        } else {
          setPanDetailToForm(panDetails, submission?.data);
        }
      } else {
        // setPanDetails({});
        // setFormData({
        //   ...formData,
        //   ...submission?.data,
        //   pan: submission?.changed?.value,
        //   panName: "",
        //   city: "",
        //   pinCode: "",
        //   dateOfBirth: "",
        //   entityOrEmployerName: "",
        //   businessVintage: "",
        // });
      }
    }
  };

  const actionPanContinue = () => {
    // const address = panDetails?.address;
    let dat = dayjs(panDetails?.dob).format("YYYY-MM-DD");
    setFormData({
      ...formData,
      firstName: panDetails?.name ?? "",
      // city: address?.city,
      // pinCode: address?.pinCode,
      dateOfBirth:
        (panDetails?.dob?.length ?? 0) == 0 ? formData?.dateOfBirth : dat,
    });
    setIsShowPanVerification(false);
  };

  const actionCalculate = (value: any) => {
    const { caseDetails } = leadDetail;

    const payload = {
      requestedLoanAmount: value?.loanAmount,
      isOfferReviewRequired: true,
      caseId: caseDetails?.caseId,
    };

    clickedSubmitTask(payload);
    setEditLoanAmountModal(false);
  };

  const renderPan = () => {
    return (
      <Modal
        className={isRazorPay(complementorUserId) ? 'razor_pay_modal' : ''}
        title="Confirm Your Pan Details"
        centered
        width={400}
        onCancel={() => setIsShowPanVerification(false)}
        footer={null}
        open={isShowPanVerification}
      >
        <PanVerification
          panDetails={panDetails}
          contactDetails={formData}
          actionPanContinue={actionPanContinue}
          complementorUserId={complementorUserId}
        />
      </Modal>
    );
  };

  const renderEditLoanAmount = () => {
    return (
      <EdotLoanAmount
        editLoanAmountModal={editLoanAmountModal}
        setEditLoanAmountModal={setEditLoanAmountModal}
        loanAmount={finalFormData?.loanAmount}
        actionCalculate={actionCalculate}
        maxAllowedLoanAmount={finalFormData?.maxAllowedLoanAmount}
        complementorUserId={complementorUserId}
      />
    );
  };

  const renderInfo = () => {
    return (
      <Modal
        className={isRazorPay(complementorUserId) ? 'razor_pay_modal' : ''}
        title="Enter a loan amount of your choice to calculate your monthly EMI."
        centered
        width={400}
        onCancel={() => setShowOfferInfo(false)}
        footer={null}
        open={showOfferInfo}
      />
    );
  };

  const json = jsonFile.JSONSchema;
  return (
    <>
      {showOfferInfo && renderInfo()}
      {isShowPanVerification && renderPan()}
      {editLoanAmountModal && renderEditLoanAmount()}
      <div className={isRazorPay(complementorUserId) ? 'razorPay-formIo' : ''}>
        <Form
          submission={{ data: formData }}
          key={"jsonForm"}
          onSubmit={handleFormSubmit}
          onCustomEvent={handleCustomEvent}
          form={finalJSONSchema}
          jsonSchema={finalJSONSchema}
          onChange={debounce(handleOnChange, 1000)}
        />
      </div>
    </>
  );
};

export default LowCode;
