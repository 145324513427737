import { Button, List, notification, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import imageProcessing from "../../assets/processing.svg";
import imageThankYou from "../../assets/thank-you.svg";
import logoFibe from "../../assets/logo_fibe.png";
import logoFibeWhite from "../../assets/logo_fibe_white.png";
import logoCashE from "../../assets/logo_CashE.png";
import logoKreditbee from "../../assets/logo_Kreditbee.png";
import logoKreditbeeWhite from "../../assets/logo_Kreditbee_white.png";
import logoMoneywide from "../../assets/logo_Moneywide.png";
import logoMoneywideWhite from "../../assets/logo_Moneywide_white.png";
import API_SERVICE from "../../services/api-service";
import { isRazorPay, numberWithCommas, updateDropOfStage } from "../../services/Utility";
import { stages } from "../complementor-widget/ComplementorWidget";
import "./Offer.scss";

const { Title, Text, Link } = Typography;

interface IOffer {
  setSelectedStageName: React.Dispatch<React.SetStateAction<stages>>;
  setShowList: Function;
  leadDetail: any;
  earlySalaryResponse: any;
  loanType: String;
  complementorUserId?:any;
}

const Offer: React.FunctionComponent<IOffer> = (props: IOffer) => {
  const {
    setSelectedStageName,
    setShowList,
    leadDetail,
    earlySalaryResponse,
    loanType,
    complementorUserId,
  } = props;

  const [data, setData] = useState({} as any);

  useEffect(() => {
    if ((leadDetail?.caseDetails?.caseId?.length ?? 0) != 0) {
      getLoanOffers();
    }
  }, []);

  const getLoanOffers = () => {
    const caseId = leadDetail?.caseDetails?.caseId;
    API_SERVICE.getLoanOffers(caseId)
      .then(({ data }) => {
        if (data?.payload) {
          setData(data.payload);
          if ((data?.payload?.loanAmount ?? 0) > 0) {
            updateDropOfStage("OFFER_SHOWN");
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const inputItems = [
    // <Space>
    //   <Text>Requested Loan Amount:</Text>
    //   <Text>{`Rs. ${numberWithCommas(data?.requestedLoanAmount)}`}</Text>
    // </Space>,
    <Space>
      <Text>Loan Amount:</Text>
      <Text>{`Rs. ${numberWithCommas(data?.loanAmount)}`}</Text>
    </Space>,
    <Space>
      <Text>Rate of Interest:</Text>
      <Text>{`${data?.roi ?? "0"}%`}</Text>
    </Space>,
    <Space>
      <Text>EMI:</Text>
      <Text>{`Rs. ${numberWithCommas(data?.emi)}`}</Text>
    </Space>,
    <Space>
      <Text>Tenure:</Text>
      <Text>{`${data?.tenure ?? ""} Years`}</Text>
    </Space>,
  ];

  const { redirectionUrl, status, sanctionLimit, message, lenderName } =
    earlySalaryResponse ?? {};

  const renderLenderImage = () => {
    if (lenderName?.toLowerCase() == "cashe") {
      return logoCashE;
    } else if (lenderName?.toLowerCase() == "moneywide") {
      return isRazorPay(complementorUserId) ? logoMoneywideWhite : logoMoneywide;
    } else if (lenderName?.toLowerCase() == "kreditbee") {
      return isRazorPay(complementorUserId) ? logoKreditbeeWhite : logoKreditbee;
    } else if (lenderName?.toLowerCase() == "fibe") {
      return isRazorPay(complementorUserId) ? logoFibeWhite : logoFibe;
    }
    return "";
  };

  const acceptStatus = [
    "accepted",
    "accept",
    "created",
    "approved",
    "in_progress",
    "pending",
  ];

  const renderTextPL = () => {
    return (
      <Text
        style={{
          fontSize: "16px",
          maxWidth: "500px",
          display: "block",
          textAlign: "center",
        }}
        className={isRazorPay(complementorUserId) ? 'razorPay-uploadText ' : ''}
      >
        {status?.toLowerCase() !== "accepted" || (sanctionLimit ?? 0) == 0
          ? "You are eligible for a loan after uploading your bank statements. Please"
          : ""}
        <Link
          target="_blank"
          href={redirectionUrl}
          style={{ fontWeight: "bold", fontSize: "16px" }}
        >
          Click here
        </Link>{" "}
        <span className={isRazorPay(complementorUserId) ? 'razorPay-uploadText ' : ''}>for completing your application</span>
      </Text>
    );
  };

  const renderText = () => {
    return (
      <Text style={{ fontSize: "16px" }} className={isRazorPay(complementorUserId) ? 'razorPay-uploadText ' : ''}>
        {(sanctionLimit ?? 0) == 0 ? "You are almost done, " : ""}
        <Link
          target="_blank"
          href={redirectionUrl}
          style={{ fontWeight: "bold", fontSize: "16px" }}
        >
          Click here
        </Link>{" "}
        to complete your application
      </Text>
    );
  };

  const renderRedirectUrlView = () => {
    return (
      <>
        {acceptStatus.includes(status?.toLowerCase()) && (
          <Title level={4} className={isRazorPay(complementorUserId) ? 'razorPay-uploadText' : ''}>
            {sanctionLimit > 0
              ? `You are eligible for a Loan of Rs. ${numberWithCommas(
                  sanctionLimit
                )}`
              : message ?? ""}
          </Title>
        )}
        {[...acceptStatus, "success", "suspended"].includes(
          status?.toLowerCase()
        ) ? (
          <>
            {(redirectionUrl?.length ?? 0) > 0 && (
              <>
                {loanType == "PERSONAL_LOAN_ONLY"
                  ? renderTextPL()
                  : renderText()}
              </>
            )}
            {acceptStatus.includes(status?.toLowerCase()) &&
              renderLenderImage().length > 0 && (
                <div style={{ display: "flex" }}>
                  <Text style={{ marginTop: "43px", marginRight: "10px" }} className={isRazorPay(complementorUserId) ? 'razorPay-uploadText ' : ''}>
                    Offered By
                  </Text>
                  <img
                    src={renderLenderImage()}
                    alt=""
                    style={{
                      height: "110px",
                      width: "110px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
          </>
        ) : (
          <>
            <h2 style={{color:'white'}}>
              Thank You !
            </h2>
            <Text style={{ fontSize: "18px" }} className={isRazorPay(complementorUserId) ? 'razorPay-uploadText ' : ''}>
              For sharing your information, we will be in touch
              shortly.
            </Text>
            {loanType != "BUY_NOW_PAY_LATER" && (
              <div className="btn-next-view mt-20">
                <Button
                  className={isRazorPay(complementorUserId) ? 'razorpay-offer-default-btn ' : ''}
                  type="default"
                  htmlType="button"
                  size="large"
                  onClick={() => {
                    setShowList(true);
                  }}
                >
                  Close
                </Button>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className="processing-center-view">
      <Space className="main-processing">
        {!isRazorPay(complementorUserId) &&
          <img src={((data?.loanAmount ?? 0) > 0) ? imageProcessing : imageThankYou} alt="" className="image-processing" />
        }
        {(redirectionUrl?.length ?? 0) !== 0 || (status?.length ?? 0) !== 0 ? (
          renderRedirectUrlView()
        ) : (
          <>
            <Title level={4} className={(((data?.loanAmount ?? 0) > 0) ? "" : "thankyou-msg") + (isRazorPay(complementorUserId) ? ' razorPay-uploadText ' : '')}>
              {(data?.loanAmount ?? 0) > 0
                ? `You are eligible for a Loan of Rs. ${numberWithCommas(
                    data?.loanAmount
                  )}`
                : "We are processing your loan request and will be in touch shortly!."}
            </Title>
            {(data?.loanAmount ?? 0) > 0 && (
              <List
                grid={{ gutter: 5, column: 2, xs: 1, sm: 1, md: 1, lg: 2 }}
                dataSource={inputItems}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            )}
            <Text>Click continue to complete document upload</Text>
            <div className="btn-next-view mt-20">
              <Button
                className=""
                type="default"
                htmlType="button"
                size="large"
                onClick={() => {
                  setShowList(true);
                }}
              >
                Close
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                onClick={() => {
                  setSelectedStageName("applicationDetails");
                  updateDropOfStage("VIEW_APPLICATION");
                }}
              >
                Continue
              </Button>
            </div>
          </>
        )}
      </Space>
    </div>
  );
};

export default Offer;
