import React, { useCallback, useState, useEffect } from "react";
import {
  Button,
  Modal,
  notification,
  Typography,
  Form as ReactForm,
  Checkbox,
} from "antd";
import {
    NUMBER_REGEX,
    PHONE_NUMBER_REGEX,
    STRING_ONLY,
  } from "../../constants/AppConstants";
import FormCheckbox from "../../components/FormCheckbox/FormCheckbox";
import FormInput from "../../components/FormInput/FormInput";

interface IndiaMartNew {
    formOTP: any,
    onFinish: any,
    isNextDisable: any,
    // actionResend: any,
    loading: any,
    runTimer: any;
    setRunTimer: any;
    signUp: any;
    setIsTimeCalled: any;
    isTimeCalled: any;
    loader: any;
    // countDown: any;
    // setCountDown: any;
    // time: any;
}
const IndiaMartNew: React.FunctionComponent<IndiaMartNew> = ({
    formOTP,
    onFinish,
    isNextDisable,
    // actionResend,
    loading,
    runTimer,
    setRunTimer,
    signUp,
    setIsTimeCalled,
    isTimeCalled,
    loader,
    // countDown,
    // setCountDown,
    // time,
}) => {

  const time = 60 * 2 - 1;
  const [isTimeStoped, setIsTimeStoped] = useState(false);
  const [countDown, setCountDown] = React.useState(time);

  const seconds = String(countDown % 60).padStart(2, "0");
  const minutes = String(Math.floor(countDown / 60)).padStart(2, "0");


  //   const { type } = useParams() as any;

  useEffect(() => {
    formOTP?.setFieldsValue({
      otp: "",
    });
  }, []);
  useEffect(() => {
    let timerId = null as any;

    if (runTimer) {
      setCountDown(time);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown === 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setIsTimeStoped(true);
    }
  }, [countDown, runTimer]);

  useEffect(() => {
    if (isTimeCalled) {
      setCountDown(time); 
      setRunTimer(true);
      setIsTimeStoped(false);
      console.log('Count', countDown);
    }
  },[isTimeCalled])

  const actionResend = () => {
    console.log('countDown', countDown);
    if (countDown === 0) {
      const called = true;
      signUp(called);
      // getOtpMobile(formGetOtp.getFieldValue("mobile"));
    }
  };

  return (
      <ReactForm
        layout="vertical"
        autoComplete="off"
        name="leadFormVerifyOTP"
        style={{ width: '90%' }}
        className="form-persion"
        key="leadFormVerifyOTP"
        form={formOTP}
        onFinish={onFinish}
      >
        <FormInput
          name="otp"
          type="password"
          label="Enter OTP"
          placeholder=" Enter OTP"
          inputMode="numeric"
          className="otp-input"
          isRequired
          isDisabled={isNextDisable}
          rules={[
            {
              pattern: NUMBER_REGEX,
              message: "Please enter valid OTP",
            },
          ]}
        />

        {!isNextDisable && (
          <div>
            Didn't receive the OTP? &nbsp;
            <a onClick={actionResend} style={{ color: "#C71C8E" }}>
              {isTimeStoped ? "RESEND OTP" : `${minutes}:${seconds}`}

              <br />
            </a>
          </div>
        )}
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          className="btn-next"
          loading={loader}
          disabled={isNextDisable}
        >
          Continue
        </Button>
      </ReactForm>
  );
}

export default IndiaMartNew;
