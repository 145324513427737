import { Button, List, notification, Space, Typography, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import imageProcessing from "../../assets/processing.svg";
import logoFibe from "../../assets/logo_fibe.png";
import logoCashE from "../../assets/logo_CashE.png";
import logoKreditbee from "../../assets/logo_Kreditbee.png";
import logoMoneywide from "../../assets/logo_Moneywide.png";
import logoKotakMahindra from "../../assets/logo_kotakMahindra.png";
import logoIciciBank from "../../assets/logo_iciciBank.png";
import logoMahindraBank from "../../assets/logo_mahindraBankFinance.png";
import API_SERVICE from "../../services/api-service";
import { numberWithCommas, updateDropOfStage } from "../../services/Utility";
import { stages } from "../complementor-widget/ComplementorWidget";
import "./Offer.scss";

const { Title, Text, Link } = Typography;

interface IOffer {
  setSelectedStageName: React.Dispatch<React.SetStateAction<stages>>;
  setShowList: Function;
  leadDetail: any;
  earlySalaryResponse: any;
  loanType: String;
  clickedSubmitTask: any;
}

const OfferMultilender: React.FunctionComponent<IOffer> = (props: IOffer) => {
  const {
    setSelectedStageName,
    setShowList,
    leadDetail,
    earlySalaryResponse,
    loanType,
    clickedSubmitTask,
  } = props;

  const [data, setData] = useState({} as any);
  console.log("earlySalaryResponse", earlySalaryResponse);
  const {
    redirectionUrl,
    status,
    sanctionLimit,
    message,
    lenderName,
    maxAllowedLoanAmount,
    eligibleLenderLoanOffer,
    topEligibleLenderLoanOffer,
    isBureauOffer
  } = earlySalaryResponse ?? {};
  const renderFooterImages = () => {
    return (
      <div className="offerFooterImages">
        <div className="footerText">
          <span>Preferred Lenders:</span>
        </div>
        <div className="footerImages">
          <img
            src={logoKotakMahindra}
            alt=""
            style={{
              height: "40px",
              // width: "200px",
              margin: "15px",
              objectFit: "contain",
            }}
          />
          <img
            src={logoIciciBank}
            alt=""
            style={{
              height: "40px",
              // width: "200px",
              margin: "15px",
              objectFit: "contain",
            }}
          />
          <img
            src={logoMahindraBank}
            alt=""
            style={{
              height: "40px",
              width: "45%",
              margin: "15px",
              objectFit: "contain",
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
    <div className="offerScreen">
      <div className="processingImg"></div>
      <div className="remove-border-loan-eligiblity-title">
        <div className="offer-text">
          You're eligible for a loan upto <span className="offer-amount">&nbsp;₹ {numberWithCommas(Math.round(maxAllowedLoanAmount))}</span>
          {/* You are eligible for a maximum loan of up to Rs.{numberWithCommas(Math.round(maxAllowedLoanAmount))} */}
        </div>
      </div>
      <div className="offerSubHeader">
        Top offers for you
      </div>
      <div className="offerMainTable mt-1">
        <Row>
          <Col span={7}>Loan Amount</Col>
          <Col span={8}>EMI</Col>
          <Col span={3}>ROI</Col>
          <Col span={6}>Tenure</Col>
        </Row>
        <div className="offerScreenDetails">
          {topEligibleLenderLoanOffer?.map((items) => {
            return (
              <Row>
                <Col span={7}>
                  Rs.{numberWithCommas(Math.round(items?.calculatedLoanAmount))}
                </Col>
                <Col span={8}>
                  Rs.{numberWithCommas(Math.round(items?.totalEMI))}
                </Col>
                <Col span={3}>{items?.interest * 100}%</Col>
                <Col span={6}>{items?.tenureInMonths / 12} Years</Col>
              </Row>
            );
          })}
        </div>
      </div>
      <div
        className="offerSubHeader"
        style={{
          fontSize: "20px",
        }}
      >
        Click Continue to complete document upload
      </div>
      {isBureauOffer && (
      <div
        className="offerSubHeader1"
        style={{
          fontSize: "16px",
          margin: '10px',
          fontWeight: '500'
        }}
      >
        Upload your last 12 months bank statements for better offer
      </div>
      )}
      <div className="offerBtnSection">
        {isBureauOffer  &&
        <button
          className="btn btn-primary back_btn"
          onClick={() => {
            if (isBureauOffer) {
              clickedSubmitTask({
                isSKIPFinBoxBtnClicked: true
              })
            } else {
              setShowList(true);
            }
          }}
        >
          {isBureauOffer ? 'Keep Current Offer' : 'Close'}
        </button>
        }
        <button
          className="btn btn-primary next_btn"
          onClick={() => {
            clickedSubmitTask({});
            // setSelectedStageName("applicationDetails");
            // updateDropOfStage("VIEW_APPLICATION");
          }}
        >
          {isBureauOffer ? 'Upload Statement' : 'View Application Details'}
        </button>
      </div>
      {/* {renderFooterImages()} */}
    </div>
    {isBureauOffer && (
    <div>
      <div
        className="buero-offer"
      >
        Note: Calculations are based on available data points and are subject to change 
                   based on actual information provided.
      </div>
    </div>
    )}
    </>
  );
};

export default OfferMultilender;
