import { Radio, Form, Space } from "antd";
import "./FormRadioButton.scss";

const FromRadioButton = (props) => {
    const {
        onChange,
        radioList,
        className,
        name,
        defaultValue,
        rules,
        label,
        isRequired,
        isDisabled,
        requiredLabel,
    } = props;

    return (
        <>
            <Form.Item
                name={name}
                label={label}
                rules={[
                    {
                        required: isRequired,
                        message: `Please select your ${requiredLabel ?? label}.`,
                    },
                    ...(rules ?? []),
                ]}
            >
                <Radio.Group
                    defaultValue={defaultValue}
                    onChange={onChange}
                    className={className}
                    disabled={isDisabled}
                >
                    <Space direction="horizontal">
                        {radioList?.map((obj: any, i: number) => {
                            return (
                                <Radio key={i} value={obj.key}>
                                    {obj.value}
                                </Radio>
                            );
                        })}
                    </Space>
                </Radio.Group>
            </Form.Item>
        </>
    );
};

export default FromRadioButton;
