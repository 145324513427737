import {
  Button,
  Collapse,
  Form,
  List,
  Modal,
  notification,
  Radio,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import FormDOB from "../../components/FormDOB/FormDOB";
import FormInput from "../../components/FormInput/FormInput";
import FormSelect from "../../components/FormSelect/FormSelect";
import { NUMBER_REGEX, PAN_REGEX } from "../../constants/AppConstants";
import API_SERVICE from "../../services/api-service";
import { stages } from "../complementor-widget/ComplementorWidget";
import "./LoanDetails.scss";
import PanVerification from "./PanVerification";
import dayjs from "dayjs";
import { numberWithCommas, updateDropOfStage } from "../../services/Utility";
import FormInputNumber from "../../components/FormInputNumber/FormInputNumber";

const { Text } = Typography;
const { Panel } = Collapse;

interface ILoanDetails {
  selectedStage: number;
  leadDetail: any;
  setLeadDetail: Function;
  setBusinessType: Function;
  setSelectedStage: Function;
  loanType: String;
  setEarlySalaryResponse: Function;
  isBack: boolean;
  setSelectedStageName: React.Dispatch<React.SetStateAction<stages>>;
}

const LoanDetails: React.FunctionComponent<ILoanDetails> = (
  props: ILoanDetails
) => {
  const {
    selectedStage,
    setSelectedStage,
    leadDetail,
    setLeadDetail,
    setBusinessType,
    loanType,
    setSelectedStageName,
    isBack,
    setEarlySalaryResponse,
  } = props;

  const [form] = Form.useForm() as any;
  const [formApplicantType] = Form.useForm() as any;

  const [applicantTypes, setApplicantType] = useState([
    { key: "SOLE_PROPRIETOR", value: "Sole Proprietor" },
    { key: "PARTNERSHIP", value: "Partnership" },
    { key: "LLP", value: "Limited Liability Partnership" },
    { key: "HUF", value: "Hindu Undivided Family" },
    { key: "PVT", value: "Private Limited" },
    { key: "LTD", value: "Public Limited" },
    { key: "SELF_EMPLOYED", value: "Self-Employed Professional" },
  ] as any);

  const [businessTypes, setBusinessTypes] = useState([]);
  const [loginProfileType, setLoginProfileType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [selectedApplicantTypes, setSelectedApplicantTypes] = useState("");
  const [isShowPanVerification, setIsShowPanVerification] = useState(false);
  const [isBusinessDataSet, setIsBusinessDataSet] = useState(false);
  const [isPersonalDetailsAdded, setIsPersonalDetailsAdded] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [panDetails, setPanDetails] = useState({} as any);
  const [firstName, setFirstName] = useState("");
  const [dob, setDOB] = useState("");
  const [isPersionalLoan, setIsPersionalLoan] = useState(true);
  const [isDisplayedPersonalDetailPanel, setIsDisplayedPersonalDetailPanel] =
    useState(true);
  const [genders, setGenders] = useState([
    { key: "M", value: "Male" },
    { key: "F", value: "Female" },
    { key: "O", value: "Other" },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (loanType === "PERSONAL_LOAN_ONLY") {
      setIsPersionalLoan(true);
    } else {
      setIsPersionalLoan(false);
    }

    console.log("LeadDetails: ", leadDetail);
    setFirstName(leadDetail?.contactDetails?.firstName);
    getEnumValues();
    setFormData();
  }, []);

  useEffect(() => {
    if (isBack && activeKey === "2") {
      setIsDisplayedPersonalDetailPanel(false);
    }
  });

  const getEnumValues = () => {
    API_SERVICE.getEnumValues().then(({ data }) => {
      if (data) {
        localStorage.setItem("enumValues", JSON.stringify(data));
        setEnumValues();
      }
    });
  };

  const setEnumValues = () => {
    let enumValues = localStorage.getItem("enumValues") as any;
    if (enumValues) {
      enumValues = JSON.parse(enumValues);
      let filteredApplicationType = enumValues.find(
        (item: any) => item.propertyName === "APPLICATION_TYPE"
      );
      let filteredIndivType = enumValues.find(
        (item: any) => item.propertyName === "INDIVIDUAL_TYPE"
      );
      let filteredBusinessType = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_TYPE"
      );
      let filteredLoginProfileType = enumValues.find(
        (item: any) => item.propertyName === "BUSINESS_PROFILE"
      );
      // if (filteredApplicationType) {
      //   setApplicantType(filteredApplicationType.keyAndDisplayValueList);
      // }
      // if (filteredIndivType) {
      //   setIndividualType(filteredIndivType.keyAndDisplayValueList);
      // }
      if (filteredBusinessType) {
        setBusinessTypes(filteredBusinessType.keyAndDisplayValueList);
      }
      if (filteredLoginProfileType) {
        setLoginProfileType(filteredLoginProfileType.keyAndDisplayValueList);
      }
    }
  };

  const getPanDetails = (pan: any, isTypeBusiness: boolean) => {
    if (!pan || pan === "" || pan === "N/A") {
      return;
    }

    const params = {
      panNumber: pan,
      consent: "Y",
    };
    API_SERVICE.getPANdobDetails(params)
      .then(({ data }) => {
        if (data?.payload?.result) {
          const panDetail = {...data?.payload?.result, pan:pan};

          let dt = dayjs(panDetail?.dob, "YYYY-MM-DD");
          const diff = dayjs().diff(dt, "year");

          form.setFieldsValue({
            pan: panDetail?.pan,
            dob: dt,
            businessName: isTypeBusiness ? panDetail?.name : "",
            businessVintage: isTypeBusiness ? diff : "",
          });
          setDOB(panDetail?.dob);
          setPanDetails(panDetail);
        }
      })
      .catch((e) => {
        form.setFields([
          {
            name: "pan",
            errors: ["PAN not valid"],
          },
        ]);
      });
  };

  const verifyPanDetails = (pan: any) => {
    if (!pan || pan === "" || pan === "N/A") {
      return;
    }

    const params = {
      panNumber: pan,
      consent: "Y",
    };
    API_SERVICE.getPANdobDetails(params)
      .then(({ data }) => {
        if (data?.payload?.result) {
          const panDetail = {...data?.payload?.result, pan: pan};
          setPanDetails(panDetail);
        }
      })
      .catch((e) => {});
  };

  const setFormData = () => {
    const { contactDetails, address } = leadDetail;

    const type = leadDetail?.applicationSubTypeKey?.trim();

    const findType = applicantTypes?.find((obj: any) => {
      return obj.key === type || obj.value === type;
    });

    formApplicantType.setFieldsValue({
      applicantType: findType?.key,
    });

    const amount = leadDetail?.caseDetails?.loanDetails?.loanAmount;
    const currentEmis = leadDetail?.caseDetails?.loanDetails?.currentEmis ?? "";
    const monthlyInHandSalary =
      leadDetail?.caseDetails?.loanDetails?.monthlyInHandSalary ?? "";

    let dt = dayjs(contactDetails?.dateOfBirth, "YYYY-MM-DD");

    form.setFieldsValue({
      email: contactDetails?.emailId,
      dob: dt.isValid() ? dt : "",
      pan: contactDetails?.pan,
      gender: contactDetails?.gender,
      mobileNumber: contactDetails?.mobileNumber,
      city: address?.city,
      state: address?.state,
      pinCode: address?.pinCode,
      address1: address?.address,
      applicantType: leadDetail?.applicationType,
      applicationSubType: leadDetail?.applicationSubTypeKey,
      businessProfile: leadDetail?.businessProfile,
      businessTurnOver: leadDetail?.businessTurnOver,
      businessVintage: leadDetail?.businessVintage,
      loanAmount: amount === "0" ? "" : amount,
      businessName: leadDetail?.entityOrEmployerName,
      monthlyEmi: leadDetail?.monthlyEmi,
      currentEmis: currentEmis,
      monthlyInHandSalary: monthlyInHandSalary,
    });

    if (loanType === "PERSONAL_LOAN_ONLY") {
      setSelectedApplicantTypes("SALARIED");
      setIsBusiness(false);
    } else {
      setSelectedApplicantTypes(findType?.key ?? "");
      setIsBusiness(
        findType?.key !== "SELF_EMPLOYED" && findType?.key !== "SOLE_PROPRIETOR"
      );
    }

    const date = contactDetails?.dateOfBirth?.split("T") ?? [];
    if ((date.length ?? 0) > 0) {
      setDOB(date[0]);
    }

    if ((contactDetails?.pan?.length ?? 0) > 0) {
      // getPanDetails(contactDetails?.pan, findType?.key !== "SELF_EMPLOYED" && findType?.key !== "SOLE_PROPRIETOR");
      verifyPanDetails(contactDetails?.pan);
    }

    if (isBack) {
      setActiveKey("2");
      setIsPersonalDetailsAdded(true);
      setBusinessFormData();
    }
  };

  const setBusinessFormData = () => {
    if (!isBusinessDataSet) {
      const amount = leadDetail?.caseDetails?.loanDetails?.loanAmount;
      const currentEmis =
        leadDetail?.caseDetails?.loanDetails?.currentEmis ?? "";
      const monthlyInHandSalary =
        leadDetail?.caseDetails?.loanDetails?.monthlyInHandSalary ?? "";

      form.setFieldsValue({
        businessProfile: leadDetail?.businessProfile,
        businessTurnOver: leadDetail?.businessTurnOver,
        businessVintage: leadDetail?.businessVintage,
        loanAmount: amount === "0" ? "" : amount,
        businessName: leadDetail?.entityOrEmployerName,
        monthlyEmi: leadDetail?.monthlyEmi,
        employerName: leadDetail?.entityOrEmployerName,
        currentEmis: currentEmis,
        monthlyInHandSalary: monthlyInHandSalary,
      });
    }
    setIsBusinessDataSet(true);
  };

  const createPayload = (formData: any) => {
    console.log("formData", formData);
    const type1 = formData.businessProfile;
    const tmpBusinessProfile: any = loginProfileType?.find((obj: any) => {
      return obj.key === type1 || obj.value === type1;
    });

    const data = {
      loanType: leadDetail?.loanType,
      loanProductId: leadDetail?.loanProductId,
      sourceOfCase: "CONNECTOR",
      address: {
        address: "",
        area: formData.city,
        city: formData.city,
        country: "",
        geoCode: "",
        pinCode: formData.pinCode,
        state: "",
      },
      applicationType:
        !isBusiness &&
        (selectedApplicantTypes === "SELF_EMPLOYED" ||
          selectedApplicantTypes === "SALARIED")
          ? "INDIVIDUAL"
          : "BUSINESS",
      applicationSubType: selectedApplicantTypes,
      businessProfile: tmpBusinessProfile?.key ?? formData.businessProfile,
      businessTurnOver: formData.businessTurnOver,
      businessVintage: formData.businessVintage,
      officePincode: formData.officePinCode,
      monthlyEmi: formData.monthlyEmi,
      comments: "",
      assignToUserId:
        leadDetail?.assignToUserId ??
        leadDetail?.caseDetails?.assignedToUserDetails?.userId,
      backOfficeUserId: "",
      commissionDetail: {
        connectorName: "",
        finalCommissionAmount: 0,
        finalCommissionPercentage: 0,
        tentativeCommissionAmount: 0,
        tentativeCommissionPercentage: 0,
      },
      connectorId:
        leadDetail?.connectorId ??
        leadDetail?.caseDetails?.connectorDetails?.userDetails?.userId,
      customerId: leadDetail?.dsaCustomerId,
      nationality: null,
      bureauScoreStatus: null,
      contactDetails: {
        gender: formData.gender,
        firstName: firstName,
        lastName: "",
        middleName: "",
        dateOfBirth: formData.dob,
        emailId: formData.email,
        mobileNumber: leadDetail?.contactDetails?.mobileNumber,
        pan: formData.pan,
      },
      entityOrEmployerName: isPersionalLoan
        ? formData.employerName
        : formData.businessName,
      loanDetails: {
        expectedInterestRate:
          leadDetail?.caseDetails?.loanDetails?.expectedInterestRate ?? 0,
        loanAmount: formData.loanAmount,
        loanTenureInMonths:
          leadDetail?.caseDetails?.loanDetails?.loanTenureInMonths ?? 0,
        currentEmis: formData.currentEmis,
        monthlyInHandSalary: formData.monthlyInHandSalary,
      },
    };
    return data;
  };

  const checkPanDetails = () => {
    if (panDetails?.name !== firstName) {
      setIsShowPanVerification(true);
      return true;
    }
    return false;
  };

  const getLendersList = (officePinCode: any) => {
    API_SERVICE.getLenders({
      status: "Active",
      loanProductId: leadDetail?.loanProductId,
      pageNo: 1,
      pageSize: 200,
    })
      .then(({ data }) => {
        if (data?.payload) {
          const datas = data?.payload;
          const filteredLender = datas.filter(
            (lender: any) =>
              lender?.lenderName === "FIBE" ||
              lender?.lenderName?.includes("FIBE") ||
              lender?.lenderName?.toLowerCase().includes("fibe")
          );
          if ((filteredLender?.length ?? 0) > 0) {
            updateLenderDetails(
              "LENDER_SELECTION",
              filteredLender[0],
              officePinCode
            );
          } else {
            notification.error({ message: "Lender not found" });
          }
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const updateLenderDetails = (
    caseStatus: any,
    lender: any,
    officePinCode: any
  ) => {
    const payload = {
      lenderDetails: [
        {
          lenderInfoId: lender?.lenderInfoId,
          lenderName: lender?.lenderName,
          status: "ASSIGNED",
          submissionType: lender?.submissionType,
        },
      ],
    };
    API_SERVICE.updateLenderDetails(
      leadDetail?.caseDetails?.caseId,
      caseStatus,
      payload,
      leadDetail?.loanProductId
    )
      .then(({ data }) => {
        if (caseStatus === "LOGGED") {
          setSelectedStageName("offer");
        } else {
          updateLenderAdditonalField(lender, officePinCode);
        }
      })
      .catch((e: any) => {
        console.log("updateLenderDetails: ", e);
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const updateLenderAdditonalField = (lender: any, officePinCode: any) => {
    console.log("updateLenderAdditonalField Form: ", lender);

    setLoading(true);
    API_SERVICE.updateLenderAdditonalField(
      lender?.lenderInfoId,
      leadDetail?.caseDetails?.caseId,
      leadDetail?.loanProductId,
      { officepincode: officePinCode }
    )
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          // updateLenderDetails("LOGGED", lender, officePinCode);
          setSelectedStageName("offer");
          console.log("updateLenderAdditonalField Data: ", data);
          setEarlySalaryResponse(data?.payload?.earlySalaryResponse ?? {});
        }
      })
      .catch((e: any) => {
        setLoading(false);
        const msg = API_SERVICE.handleErrors(e);
        const arrMessage = msg.split(":");
        if ((arrMessage?.length ?? 0) > 1 && arrMessage?.at(0) === "MESSAGE") {
          setEarlySalaryResponse({ status: "error", message: arrMessage?.at(-1) });
          setSelectedStageName("offer");
        } else {
          notification.error({ message: msg });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [formValues, setFormValues] = useState({} as any);

  const onFinishForm = (values: any) => {
    setFormValues({ ...formValues, ...values });
    let temp: any = {};
    temp = { ...formValues, ...values };

    if (!isBusiness && checkPanDetails()) {
      return;
    }

    if (!isPersonalDetailsAdded) {
      setActiveKey("2");
      setIsDisplayedPersonalDetailPanel(false);
      setIsPersonalDetailsAdded(true);
      // const { contactDetails } = leadDetail;
      // if (isBusiness && (contactDetails?.pan?.length ?? 0) > 0) {
      //   getPanDetails(
      //     contactDetails?.pan,
      //     leadDetail?.applicationSubType !== "SELF_EMPLOYED"
      //   );
      // }
      setBusinessFormData();
      return;
    }

    const payload = createPayload(temp);
    setLoading(true);
    API_SERVICE.updateCase(leadDetail?.caseDetails?.caseId, payload)
      .then(({ data }) => {
        if (data) {
          // notification.success({ message: data.message });
          setLeadDetail(data?.payload);
          setSelectedStage(selectedStage + 1);
          if (
            leadDetail?.applicationSubType === "SALARIED" ||
            leadDetail?.loanType === "Personal Loan"
          ) {
            getLendersList(temp?.officePinCode);
          } else {
            setSelectedStage(3);
            setSelectedStageName("bankStatement");
          }
          // setSelectedStageName("bankStatement");
          setBusinessType(values?.applicationSubType ?? "");
          updateDropOfStage("INCOME_COMPLETED");

          let params = {
            comments: "PROSPECT",
            status: "PROSPECT",
          };

          API_SERVICE.updateCaseStatus(leadDetail?.caseDetails?.caseId, params)
            .then((data) => {
              if (data) {
              }
            })
            .catch((e: any) => {});
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderPan = () => {
    return (
      <FormInput
        name="pan"
        label={isBusiness ? "Business PAN" : "PAN"}
        isRequired
        isUpperCase
        isVerified={!(panDetails?.name !== firstName)}
        showVerification={!isBusiness}
        rules={[
          {
            pattern: PAN_REGEX,
            message: "Please enter valid PAN",
          },
        ]}
        onBlur={() => {
          if (!isBusiness && Object.keys(panDetails).length > 0) {
            checkPanDetails();
          }
        }}
        onChange={async () => {
          setPanDetails({});
          form
            .validateFields(["pan"])
            .then((vlaue: any) => {
              getPanDetails(vlaue?.pan, isBusiness);
            })
            .catch((err: any) => {
              setPanDetails({});
            });
        }}
      />
    );
  };

  const renderEmail = () => {
    return (
      <FormInput
        name="email"
        label="Email"
        tooltip="Please enter your email ID correctly as you will receive loan updates on this email ID."
        isRequired
        rules={[{ type: "email", message: "Please enter valid email." }]}
      />
    );
  };

  const renderDOB = () => {
    return (
      <FormDOB
        name="dob"
        label={isBusiness ? "Date of Incorporation" : "DOB"}
        isRequired
        defaultValue={dob}
        onChange={(day, month, year) => {
          setDOB(`${year}-${month}-${day}`);
        }}
      />
    );
  };

  const renderCity = () => {
    return <FormInput name="city" label="City" isRequired />;
  };

  const renderPinCode = () => {
    return (
      <FormInput
        name="pinCode"
        label="Pin Code"
        inputMode="numeric"
        isRequired
        rules={[
          {
            pattern: NUMBER_REGEX,
            message: "Please enter only number",
          },
        ]}
      />
    );
  };

  const renderOfficePinCode = () => {
    return (
      <FormInput
        name="officePinCode"
        label="Office Pin Code"
        inputMode="numeric"
        isRequired
        rules={[
          {
            pattern: NUMBER_REGEX,
            message: "Please enter only number",
          },
        ]}
      />
    );
  };

  const inputItemsPanDetail = [renderDOB(), renderCity(), renderPinCode()];

  const inputItemsPersonDetails = [
    renderEmail(),
    <Form.Item
      label="Gender"
      name="gender"
      rules={[{ required: true, message: "Please select gender" }]}
    >
      <Radio.Group className="custom-radio" name="loanTypes" defaultValue={1}>
        <Space direction="horizontal">
          {genders &&
            genders.map((gender: any, i: number) => (
              <Radio.Button key={i} value={gender.key}>
                {gender.value}
              </Radio.Button>
            ))}
        </Space>
      </Radio.Group>
    </Form.Item>,
    renderPan(),
    ...inputItemsPanDetail,
  ];

  const renderLoanAmount = () => {
    return (
      <FormInputNumber
        name="loanAmount"
        label="Required Loan Amount (INR)"
        formatter={(value: any) => numberWithCommas(value)}
        inputMode="numeric"
        isRequired
        rules={[
          {
            pattern: NUMBER_REGEX,
            message: "Please enter only number",
          },
        ]}
      />
    );
  };

  const renderMonthlyEmi = () => {
    return (
      <FormInputNumber
        name="monthlyEmi"
        label="Monthly Obligations (EMIs) (INR)"
        formatter={(value: any) => numberWithCommas(value)}
        inputMode="numeric"
        isRequired
        rules={[
          {
            pattern: NUMBER_REGEX,
            message: "Please enter only number",
          },
        ]}
      />
    );
  };

  const itemsLoanDetails = [
    <FormInput name="employerName" label="Employer Name" isRequired />,
    <FormInputNumber
      name="monthlyInHandSalary"
      label="Monthly In-hand Salary (INR)"
      formatter={(value: any) => numberWithCommas(value)}
      inputMode="numeric"
      isRequired
      rules={[
        {
          pattern: NUMBER_REGEX,
          message: "Please enter only number",
        },
      ]}
    />,
    renderLoanAmount(),
    <FormInputNumber
      name="currentEmis"
      label="Current EMIs (INR)"
      formatter={(value: any) => numberWithCommas(value)}
      inputMode="numeric"
      isRequired
      rules={[
        {
          pattern: NUMBER_REGEX,
          message: "Please enter only number",
        },
      ]}
    />,
    renderOfficePinCode(),
  ];

  const renderBusinessNameVintage = [
    <FormInput
      name="businessName"
      label="Business Name"
      isRequired
      isDisabled={
        selectedApplicantTypes !== "SELF_EMPLOYED" &&
        selectedApplicantTypes !== "SOLE_PROPRIETOR"
      }
    />,
    <FormInput
      name="businessVintage"
      label="No. of Years In Business"
      inputMode="numeric"
      isRequired
      rules={[
        {
          pattern: NUMBER_REGEX,
          message: "Please enter only number",
        },
      ]}
    />,
  ];

  const renderProfileTurnOverAmount = [
    <FormSelect
      name="businessProfile"
      label="Business Profile"
      isRequired
      options={loginProfileType}
    />,

    <FormInputNumber
      name="businessTurnOver"
      label="Business Turnover (Yearly) (INR)"
      formatter={(value: any) => numberWithCommas(value)}
      isRequired
      inputMode="numeric"
      rules={[
        {
          pattern: NUMBER_REGEX,
          message: "Please enter only number",
        },
      ]}
    />,
    renderMonthlyEmi(),
    renderLoanAmount(),
  ];

  const inputItemsBusiness = [
    renderPan(),
    renderDOB(),
    ...renderBusinessNameVintage,
    renderCity(),
    renderPinCode(),
    ...renderProfileTurnOverAmount,
  ];

  const inputItemsBusinessIndividual = [
    ...renderBusinessNameVintage,
    ...renderProfileTurnOverAmount,
  ];

  const personDetails = [
    <Text>{`Full Name: ${firstName}`}</Text>,
    <Text>{`Mobile Number: ${leadDetail?.contactDetails?.mobileNumber}`}</Text>,
  ];

  const actionPanContinue = () => {
    setFirstName(panDetails?.name);
    setIsShowPanVerification(false);
  };

  const renderPersonalDetails = () => {
    return (
      <>
        <div className="person-details-view">
          <List
            grid={{ gutter: 5, column: 2, xs: 1, sm: 1, md: 1, lg: 2 }}
            dataSource={personDetails}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </div>
        <Form
          layout="vertical"
          autoComplete="off"
          name="leadForm"
          style={{ paddingBlock: "20px", marginInline: "-20px" }}
          form={form}
          onFinish={onFinishForm}
        >
          <List
            grid={{ gutter: 5, column: 2, xs: 1, sm: 1, md: 1, lg: 2 }}
            dataSource={isBusiness ? [renderEmail()] : inputItemsPersonDetails}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />

          <div className="btn-next-view">
            <Button type="primary" htmlType="submit" size="large">
              Next
            </Button>
          </div>
        </Form>
      </>
    );
  };

  const renderBusinessDetails = () => {
    return (
      <>
        <Form
          layout="vertical"
          autoComplete="off"
          name="leadForm"
          style={{ paddingBlock: "20px", marginInline: "-20px" }}
          form={form}
          onFinish={onFinishForm}
        >
          <List
            grid={{ gutter: 5, column: 2, xs: 1, sm: 1, md: 1, lg: 2 }}
            dataSource={
              isPersionalLoan
                ? itemsLoanDetails
                : isBusiness
                ? inputItemsBusiness
                : inputItemsBusinessIndividual
            }
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />

          <div className="btn-next-view">
            <Button
              type="default"
              htmlType="button"
              size="large"
              onClick={() => {
                setActiveKey("1");
                setIsPersonalDetailsAdded(false);
                setIsDisplayedPersonalDetailPanel(true);
              }}
            >
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
            >
              Next
            </Button>
          </div>
        </Form>
      </>
    );
  };

  return (
    <>
      {isShowPanVerification && (
        <Modal
          title="Confirm Your Name"
          centered
          width={400}
          onCancel={() => setIsShowPanVerification(false)}
          footer={null}
          visible={isShowPanVerification}
        >
          <PanVerification
            panDetails={panDetails}
            contactDetails={leadDetail?.contactDetails}
            actionPanContinue={actionPanContinue}
          />
        </Modal>
      )}

      <div
        style={{
          display: "grid",
          // paddingInline: "50px",
        }}
      >
        {loanType !== "PERSONAL_LOAN_ONLY" && (
          <div className="applicant-type">
            <Form form={formApplicantType}>
              <FormSelect
                name="applicantType"
                label="Applicant Type"
                disabled={isPersonalDetailsAdded}
                options={applicantTypes}
                onChange={(value) => {
                  setIsDisplayedPersonalDetailPanel(true);
                  setSelectedApplicantTypes(value);
                  const tmpIsBusiness = !(
                    value === "SELF_EMPLOYED" || value === "SOLE_PROPRIETOR"
                  );
                  if (isBusiness !== tmpIsBusiness) {
                    setPanDetails({});
                    setIsBusinessDataSet(true);
                    form.resetFields([
                      "pan",
                      "businessName",
                      "city",
                      "pinCode",
                      "dob",
                      "businessVintage",
                    ]);
                  }
                  setIsBusiness(tmpIsBusiness);
                  setDOB("");
                }}
              />
            </Form>
          </div>
        )}

        {selectedApplicantTypes !== "" && (
          <Collapse
            accordion
            expandIconPosition="end"
            expandIcon={() => <></>}
            activeKey={activeKey}
          >
            {isDisplayedPersonalDetailPanel && (
              <Panel header={`Personal Details`} key={"1"}>
                {renderPersonalDetails()}
              </Panel>
            )}

            {isPersonalDetailsAdded && (
              <Panel
                header={isPersionalLoan ? "Salary Details" : "Business Details"}
                key={"2"}
              >
                {isPersonalDetailsAdded && renderBusinessDetails()}
              </Panel>
            )}
          </Collapse>
        )}
      </div>
    </>
  );
};

export default LoanDetails;
