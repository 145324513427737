import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  List,
  Modal,
  notification,
  Row,
  Space,
  Typography,
} from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import iconSearch from "../../assets/search.svg";
import iconSearchWhite from "../../assets/search-white.svg";
import { stages } from "../complementor-widget/ComplementorWidget";
import API_SERVICE from "../../services/api-service";
import FormIcons from "../../components/FormIcons/FormIcons";
import dayjs from "dayjs";
import { isRazorPay, numberWithCommas } from "../../services/Utility";
import { DownOutlined } from "@ant-design/icons";
import "./leadlist.scss";
import { useParams } from "react-router-dom";
import type { MenuProps } from "antd";
import { loanTypeList } from "../start/start";
import parameters from "../../services/parameters";
import moment from "moment";

const { Text } = Typography;
const { Title } = Typography;

interface ILeadList {
  isMobile: boolean;
  setSelectedStage: Function;
  setLeadDetail: Function;
  isV2: boolean;
  setShowList: Function;
  setLoanType: Function;
  complementorUserId: String;
  loanType: String;
  setSelectedStageName: React.Dispatch<React.SetStateAction<stages>>;
  setTaskDefinitionKey: Function;
  setTaskId: Function;
  setProductFormMetadata: Function;
  setFinalJSONSchema: Function;
  setFinalFormData: Function;
  setExtraData: Function,
  setComponentParameters: Function;
  addGTag: Function;
  setEarlySalaryResponse: Function;
  setIsV2: Function;
  setConnectorShortDetails: Function;
  utmDetailsDto: any;
  uploadLog: (message: string, params: any) => void;
}

const LeadList: React.FunctionComponent<ILeadList> = (props: ILeadList) => {
  const {
    isMobile,
    setShowList,
    setSelectedStage,
    setLeadDetail,
    isV2,
    setLoanType,
    complementorUserId,
    setSelectedStageName,
    setTaskDefinitionKey,
    setTaskId,
    setProductFormMetadata,
    setFinalJSONSchema,
    setFinalFormData,
    setExtraData,
    setComponentParameters,
    addGTag,
    setEarlySalaryResponse,
    loanType,
    setIsV2,
    setConnectorShortDetails,
    utmDetailsDto,
    uploadLog
  } = props;

  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [allLeads, setAllLeads] = useState<any>([]);
  const [isOpenVerify, setIsOpenVerify] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(50);
  const [selectLoan, setSelectLoan] = useState(null as any);
  const [ipAddress, setIPAddress] = useState('')

  const userStr = localStorage.getItem("user");
  const user = JSON.parse(userStr as string);

  const { type } = useParams() as any;

  useEffect(() => {
    const tmp =
      loanTypeList?.find((item: any) => item?.code == type)?.key ??
      "BUSINESS_INSTALLMENT_LOAN";
      if (!loanType) {
        setLoanType(tmp);
      }
    setConnectorShortDetails({});
    window.scrollTo(0, 0);
    getLeadList(currentPage, pageSize);
    getIPAddress();
  }, []);

  const getIPAddress = async () => {
    API_SERVICE.getIPData()
      .then(({ data }) => {
        setIPAddress(data.ip);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {});
  };

  const getJSONSchema = (jsonSchema: any) => {
    let str = jsonSchema;
    str = str?.replaceAll(/https:\/\/[^\/]+-distribution\.oneflotest\.in\/api\/dsa\//g, parameters.ApiUrlOne);
    str = str.replaceAll(/https:\/\/[^\/]+-common\.oneflotest\.in\/api\//g, parameters.ApiUrlTwo);
    str = str?.replaceAll(/https:\/\/[^\/]+-lender\.oneflotest\.in\/api\//g, parameters.ApiUrlThree);
    return JSON.parse(str);
  };

  const getNextTask = (leadDetail: any) => {

    setIsV2(leadDetail?.productVersion == "V2");
    if(leadDetail?.productVersion == "V2") {
      getNextTaskV2(leadDetail);
      return;
    }

    const { caseDetails, loanType } = leadDetail;

    API_SERVICE.getNextTask(
      caseDetails?.prospectId ?? "",
      caseDetails?.processInstanceId ?? "",
      complementorUserId,
      loanType == "Personal Loan"
    )
      .then((data) => {
        const keyTD = data?.data?.payload?.taskDefinitionKey ?? "";
        const jsonSchema = getJSONSchema(data?.data?.payload?.jsonSchema ?? "{}");
        setTaskDefinitionKey(keyTD);
        setTaskId(data?.data?.payload?.taskId);
        setFinalJSONSchema(jsonSchema);
        if (
          (keyTD === "user_task_upload_bank_statements_finbox" ||
            keyTD === "user_task_upload_bank_statements" ||
            keyTD === "user_task_application_details") &&
          (jsonSchema?.customUI ?? false)
        ) {
          if (keyTD === "user_task_application_details") {
            if (leadDetail?.loanType == "Home Loan") {
              const formData = JSON.parse(
                data?.data?.payload?.formData ?? "{}"
              );
              setFinalFormData(formData);
            }
            const formData = JSON.parse(
              data?.data?.payload?.formData ?? "{}"
            );
            if (leadDetail?.loanType == "Business Installment Loan") {
              console.log('formData', formData);
              setFinalFormData({ multilenderOffer : formData });
            }
            setSelectedStageName("applicationDetails");
          } else {
            setSelectedStage(3);
            setSelectedStageName("bankStatement");
          }
        } else {
          if(keyTD == "user_task_thank_you_page_bil" && complementorUserId !== 'CONN4128778344') {
            setEarlySalaryResponse({});
            setSelectedStageName("offer");
          } else if (
            ["user_task_offer_details", "user_task_offer_details_hl", "user_task_multi_lender_offer_details_bil", "user_task_bureau_offer_bil"].includes(
              keyTD
            ) &&
            (data?.data?.payload?.formData?.length ?? 0) > 0
          ) {
            const formData = JSON.parse(data?.data?.payload?.formData ?? "");
            console.log("loanType: ", leadDetail?.loanType);
            if (leadDetail?.loanType == "Personal Loan") {
              setEarlySalaryResponse(formData);
              setSelectedStageName("offer");
            } else if(keyTD === "user_task_multi_lender_offer_details_bil" || keyTD === "user_task_bureau_offer_bil") {
              if (keyTD === "user_task_bureau_offer_bil") {
                setEarlySalaryResponse({ topEligibleLenderLoanOffer: [formData], isBureauOffer: true, maxAllowedLoanAmount: formData?.calculatedLoanAmount });
              } else {
                setEarlySalaryResponse(formData);
              }
              setSelectedStageName("offerMultilender");
            } else {
              setFinalFormData(formData);
              setSelectedStageName("flowable");
              // setSelectedStageName("offerIndiaMart");
            }
          } else {
            setSelectedStageName("flowable");
          }
        }
        setShowList(false);
      })
      .catch((error) => {
        setShowList(true);
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
  };

  const getNextTaskV2 = (leadDetail: any) => {
    const { caseDetails } = leadDetail;

    const payload = {
      // clientType: "MOBILE",
      // eventName: "string",
      // gotoStep: "string",
      // key: "string",
      // parameters: {},
      processInstanceId: caseDetails?.processInstanceId ?? "",
      // taskCategory: "string",
      // workflowId: "string",
    };

    API_SERVICE.getNextTaskV2(payload)
      .then((data) => {
        const payload = data?.data?.payload;

        const keyTD = payload?.taskDefinitionKey ?? "";

        const jsonSchema = getJSONSchema(payload?.jsonSchema ?? "{}");

        setProductFormMetadata(payload?.productFormMetadata ?? {});
        setTaskDefinitionKey(keyTD);
        setTaskId(payload?.taskId);
        setFinalJSONSchema(jsonSchema);

        setExtraData(payload?.extraData ?? {})
        setComponentParameters(payload?.componentParameters ?? {})

        const formData = JSON.parse(payload?.formData ?? null);
        setFinalFormData(formData);

        if (keyTD === "offerComputation_offer_review_task") { 
          const formData = JSON.parse(payload?.formData ?? "{}");
          setFinalFormData(formData);
          setSelectedStageName("offerMultilenderV2");
        } else if(payload?.componentCode == "REACT_APPLICATION_REVIEW") {
          setSelectedStageName("applicationDetails");
        } else if(payload?.productFormMetadata?.actionId == "BANK_STATEMENT_ANALYSIS" || 
                  payload?.componentCode == "BANK_STATEMENT_UPLOAD"){
          setSelectedStage(3);
          setSelectedStageName("bankStatement");
        } else {
          setSelectedStageName("flowable");
        }
        setShowList(false);
      })
      .catch((error) => {
        setShowList(true);
        notification.error({ message: API_SERVICE.handleErrors(error) });
      });
  };

  const addCase = (type: string) => {
    setLoadingAdd(true);

    let searchParams = new URL(window.location.href).searchParams;
    const urlParams: any = {};
    searchParams?.forEach((value, key) => {
      urlParams[key] = value;
    });
    console.log("urlParams --- ", urlParams);

    const consentPayload = {
      ipAddress : ipAddress,
      timeStamp : moment().format("MMM DD, YYYY hh:mm"),
      device    : window.navigator.platform,
      tcMessage : "I agree to the T&C, Privacy policy and authorise OneInfinity and or  and hereby authorise OneInfinity and/or its representatives to call,email, Whats APP, or SMS me with reference to my loan application.This consent supersedes any DNC (do not call) registration by me."
    }

    const param = {
      utmDetailsDto: utmDetailsDto,
      overrideSourceConnectorId: urlParams?.sub_connector_id,
      consentPayload: JSON.stringify(consentPayload),
      workFlowType: "BORROWER_DIRECT",
    };

    uploadLog("Add Case Api on apply", param);
    
    API_SERVICE.addCase(type, complementorUserId, urlParams?.ldata, param)
      .then(({ data }) => {
        if (data) {
          uploadLog("Add Case Api on apply Response", {caseId: data?.payload?.caseDetails?.caseId});
          if (data?.payload) {
            localStorage.setItem(
              "prospectId",
              data?.payload?.caseDetails?.prospectId ?? ""
            );
            setLeadDetail(data?.payload);
            if (
              [
                "PERSONAL_LOAN_ONLY",
                "BUSINESS_INSTALLMENT_LOAN",
                "HOME_LOAN",
                "EDUCATION_LOAN",
                "BUY_NOW_PAY_LATER",
              ].includes(type)
            ) {
              getNextTask(data?.payload);
            } else {
              // setLombardLoanTypeL(type)
              setLoanType(type);
              setShowList(false);
              setSelectedStageName("iciciOffer");
            }
          } else {
            notification.error({ message: "No lead found" });
          }
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoadingAdd(false);
      });
  };

  const getLeadList = (
    pageNo: number = 1,
    pageSize: any = null,
    searchVal: any = null
  ) => {
    const params = {
      pageNo: pageNo,
      pageSize: pageSize,
      searchParam: searchVal,
    };
    setLoading(true);

    let searchParams = new URL(window.location.href).searchParams;
    const urlParams: any = {};
    searchParams?.forEach((value, key) => {
      urlParams[key] = value;
    });

    const id = (urlParams?.sub_connector_id?.length ?? 0) > 0 ? urlParams?.sub_connector_id : complementorUserId;
    const extraparam = `&sourceConnectorUserId=${id ?? ""}`
    API_SERVICE.getLeadList(user?.mobile, params, extraparam)
      .then(({ data }) => {
        if (data) {
          const leads = data?.payload?.content ?? [];
          if ((leads?.length ?? 0) > 0) {
            setTotalCount(data?.payload?.totalElements ?? 0);
            setAllLeads(leads);
          } else {
            notification.error({ message: "No lead found" });
          }
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "name",
      width: 130,
      ellipsis: true,
      render: (_: any, record: any) => {
        const { contactDetails } = record;
        return `${contactDetails?.firstName}`;
      },
    },
    {
      title: "Email",
      dataIndex: ["contactDetails", "emailId"],
      key: "email",
      width: 110,
      ellipsis: true,
    },
    {
      title: "Mobile Number",
      dataIndex: ["contactDetails", "mobileNumber"],
      key: "Mobile Number",
      ellipsis: true,
      width: 115,
    },
    {
      title: "Loan Type",
      dataIndex: "loanType",
      key: "loanType",
    },
    {
      title: "Loan Amount",
      dataIndex: ["loanDetails", "loanAmount"],
      key: "loanAmount",
      width: 105,
      render: (_: any, record: any) => {
        return `Rs. ${numberWithCommas(record.loanDetails.loanAmount)}`;
      },
    },
    {
      title: "Created",
      dataIndex: ["caseDetails", "creationDate"],
      key: "caseCreatedDate",
      width: 135,
      render: (_: any, record: any) =>
        dayjs(record?.caseDetails?.creationDate).format("MMM, DD, YYYY HH:mm"),
    },
    {
      title: "Status",
      dataIndex: ["caseDetails", "caseStatus"],
      key: "status",
      width: 80,
    },
    {
      title: "Edit / Resume",
      key: "action",
      fixed: "right",
      width: 60,
      render: (_: any, record: any) => {
        if (record?.loanType == "Personal Loan") {
          let dt = dayjs(record?.caseDetails?.creationDate, "YYYY-MM-DD");
          const diff = dayjs().diff(dt, "day");
          if (diff > 30) {
            return null;
          }
        }
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="small"
              type="text"
              onClick={() => actionEdit(record)}
              icon={isRazorPay(complementorUserId) ? <FormIcons type="editWhite" />:<FormIcons type="edit" />}
            />
          </div>
        );
      },
    },
  ];

  const actionEdit = (record: any) => {
    console.log("record: ", record?.loanType);
    localStorage.setItem("prospectId", record?.caseDetails?.prospectId ?? "");
    setLeadDetail(record);
    if (
      [
        "Two Wheeler Insurance",
        "Health Insurance",
        "Four Wheeler Insurance",
      ].includes(record?.loanType)
    ) {
      // setLombardLoanTypeL(record?.loanType)
      setLoanType(getKey(record?.loanType));
      setShowList(false);
      setSelectedStageName("iciciOffer");
    } else {
      const { applicationSubType, loanType } = record;
      if (loanType === "Buy Now Pay Later") {
        setLoanType("BUY_NOW_PAY_LATER");
      } else if (loanType === "Education Loan") {
        setLoanType("EDUCATION_LOAN");
      } else if (loanType === "Home Loan") {
        setLoanType("HOME_LOAN");
      } else if (
        applicationSubType === "SALARIED" ||
        loanType === "Personal Loan"
      ) {
        setLoanType("PERSONAL_LOAN_ONLY");
      } else {
        setLoanType("BUSINESS_INSTALLMENT_LOAN");
      }
      getNextTask(record);
      setSelectedStage(2);
    }
  };

  const getKey = (label: string) => {
    return loanTypeList?.find((item: any) => item?.label == label)?.key ?? "";
  };

  const getLabel = (key: string) => {
    return loanTypeList?.find((item: any) => item?.key == key)?.label ?? "";
  };

  const renderVerifyModel = () => {
    return (
      <Modal
        className={isRazorPay(complementorUserId) ? 'razor_pay_modal' : ''}
        title={`Yes, I want to apply for a ${getLabel(selectLoan)}.`}
        open={isOpenVerify}
        footer={[
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
             className={isRazorPay(complementorUserId) ? 'razorPay_btn_cancel' : ''}
              key="back"
              style={{ paddingInline: "30px" }}
              onClick={() => setIsOpenVerify(false)}
            >
              Cancel
            </Button>
            <Button
              className={isRazorPay(complementorUserId) ? 'razorPay_btn' : ''}
              key="submit"
              type="primary"
              style={{ paddingInline: "30px" }}
              onClick={() => {
                setIsOpenVerify(false);
                // const loanType = selectLoan == "EDUCATION_LOAN" ? "BUSINESS_INSTALLMENT_LOAN" : selectLoan;
                setLoanType(selectLoan);
                addCase(selectLoan);
                addGTag(`Apply for ${selectLoan}`);
                setFinalFormData({});
              }}
            >
              Continue
            </Button>
          </div>,
        ]}
        width={300}
        centered
        closable={false}
      />
    );
  };

  const handlePaginationChange = (current: any, pageSize: any) => {
    setCurrentPage(current);
    setPageSize(pageSize);
    getLeadList(current, pageSize);
  };

  const items: MenuProps["items"] = loanTypeList.filter(
    (item: any) =>  !["BUY_LEADS", "BUY_NOW_PAY_LATER"].includes(item.key)
  );

  const check30Days = (type) => {
    let isCreated = false;
    if ((allLeads?.length ?? 0) > 0) {
      const filtered = allLeads?.filter(
        (item: any) => item.loanType == getLabel(type)
      );
      for (let i = 0; i <= filtered?.length; i++) {
        const lead = filtered[i];
        let dt = dayjs(lead?.caseDetails?.creationDate, "YYYY-MM-DD");
        const diff = dayjs().diff(dt, "day");
        if (
          diff <= 30 &&
          !["9935353535", "9934343434", "9931313131", "9463348097"].includes(
            lead?.contactDetails?.mobileNumber
          )
        ) {
          isCreated = true;
          break;
        }
      }
      if (isCreated) {
        let msg = `You have applied for ${getLabel(type)} only once in 30 days`;
        if (type == "HOME_LOAN") {
          msg =
            "You have already applied for a home loan, please contact support team for details.";
        }
        notification.error({ message: msg });
        return false;
      }
    }

    return true;
  };

  const handleMenuClick = (value: any) => {
    if (check30Days(value.key)) {
      setSelectLoan(value.key);
      setIsOpenVerify(true);
    }
  };

  return (
    <>
      {renderVerifyModel()}
      <div
        style={{
          display: "grid",
          justifyContent: "center",
          paddingInline: "30px",
        }}
      >
        <Title level={3} className={"title"+(isRazorPay(complementorUserId) ? ' razorpay-leadList-title' : '')}>
          User Previous Details
        </Title>

        <Row gutter={24} className="lead-filter-row">
          <Col className="filter-bar left" span={isMobile ? 24 : 12}>
            <Form.Item name="searchBox">
              <Input
                size="large"
                className={isRazorPay(complementorUserId) ? 'search-box' : ''}
                placeholder="Search"
                // onChange={debounce(searchLeadHandler, 600)}
                allowClear
                // prefix={<FaSearch />}
                prefix ={<img src={isRazorPay(complementorUserId) ? iconSearchWhite:iconSearch} alt="" width={24} height={24} />}
              />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 7} className="filter-bar right mb-10">
            <Dropdown
              overlayClassName={isRazorPay(complementorUserId) ? 'razorpay-dropdown' : ''}
              menu={{ items, onClick: handleMenuClick }}
              placement="bottomRight"
              arrow={{ pointAtCenter: false }}
            >
              <Button
                className={isRazorPay(complementorUserId) ? 'razorPay_btn' : 'lead-list-btn'}
                type="primary"
                // style={{ width: "200px" }}
                size="large"
                loading={loadingAdd}
              >
                <div
                  style={{
                    width: "150px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  Apply for Loan
                  <DownOutlined />
                </div>
              </Button>
            </Dropdown>
          </Col>
        </Row>

        <Row gutter={24} style={{ marginTop: isMobile ? "20px" : "0px"}}>
          {isMobile ? (
            <List
              className={isRazorPay(complementorUserId) ? 'razorpay-movileView-leadPgination' : ''}
              style={{ width: "100%" }}
              grid={{ gutter: 24, column: 1, xs: 1, sm: 1, md: 1, lg: 1 }}
              pagination={{
                onChange: (page, pageSize) => {
                  handlePaginationChange(page, pageSize);
                },
                current: currentPage,
                pageSize: pageSize,
                total: totalCount,
              }}
              itemLayout="horizontal"
              dataSource={allLeads}
              renderItem={(item: any) => (
                <List.Item style={{ padding: "0px 12px" }}>
                  <Card className={isRazorPay(complementorUserId) ? "razorpay-mobileView-leadList" : '' }>
                    <Row gutter={24}>
                      <Col span={12}>
                        <label className="leadlist-title">Name</label>
                        <br />
                        {item.contactDetails.firstName}
                      </Col>
                      <Col span={12} className="text-right">
                        {item.loanType} | {item?.caseDetails?.caseStatus}
                      </Col>
                    </Row>
                    <Row className="title-space">
                      <Col span={12}>
                        <label className="leadlist-title">Email</label>
                        <br />
                        {item.contactDetails.emailId}
                      </Col>
                      <Col span={12} className="text-right">
                        <label className="leadlist-title">Mobile Number</label>
                        <br />
                        {item.contactDetails.mobileNumber}
                      </Col>
                    </Row>
                    <Row className="title-space">
                      <Col span={11}>
                        <label className="leadlist-title"> Loan Amount</label>
                        <br />
                        Rs. {numberWithCommas(item.loanDetails.loanAmount)}
                      </Col>
                      <Col span={9}>
                        <label className="leadlist-title">Create date</label>
                        <br />
                        {dayjs(item?.caseDetails?.creationDate).format(
                          "DD, MMM, YYYY "
                        )}
                      </Col>
                      <Col span={4} className="text-right">
                        <label className="leadlist-title">Action</label>
                        <br />
                        <Button
                          size="small"
                          type="text"
                          onClick={() => actionEdit(item)}
                          icon={isRazorPay(complementorUserId) ? <FormIcons type="editWhite" /> : <FormIcons type="edit" />}
                        />
                      </Col>
                    </Row>
                  </Card>
                </List.Item>
              )}
            />
          ) : (
            <Table
              className={isRazorPay(complementorUserId) ? 'razorPay_table' : ''}
              loading={loading}
              columns={columns}
              dataSource={allLeads}
              scroll={{ x: "max-content" }}
              onChange={(pagination: any) => {
                handlePaginationChange(pagination.current, pagination.pageSize);
              }}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalCount,
              }}
              size="small"
              rowClassName={isRazorPay(complementorUserId) ? 'razorPay_table' : ''}
            />
          )}
        </Row>
      </div>
    </>
  );
};

export default LeadList;
