export const API_CONFIG = {
    SingUp: 'free/user/signUpUser',
    verifyOtp: 'free/verify/otp?needApiIdAndKey=YES',
    enumValues: 'enum-values/free/?loggedInFrom=web',
    // getPANDetails: 'get/pan-profile/details',
    getPANdobDetails: 'get/pan-dob-status/details',
    leadList: 'list/leads/',
    updateCase: 'comp-update/case/',
    updateCaseStatus: 'comp-update/case/',
    addDocument: "document-upload/add/",
    showBankStatementAnalysis: 'bank/statementAnalysis/document/',
    showBankStatementAnalysisForEntityId: 'bank/statementAnalysis/entity/',
    deleteDoc: 'document-upload/delete/',
    companyDetails: 'get/company/details?cin=',
    documentHierarchyList: "document-upload/document-hierarchy/list",
    documentList: 'document-upload/documentset/',
    getMobileOtp: 'free/get/otp',
    getNextTask:'/flowable/get-next-task',
    submitTask:'submit-task'
};
