import React, { useEffect, useState } from "react";
import "formiojs/dist/formio.builder.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
// @ts-ignore
import { Form } from "react-formio";
import { stages } from "../complementor-widget/ComplementorWidget";
import { Modal, notification } from "antd";
import debounce from "lodash/debounce";
import PanVerification from "../loan-details/PanVerification";
import moment from "moment";
import { isRazorPay } from "../../services/Utility";
import jsonFile from "./user_task_borrower_details.json";
import dayjs from "dayjs";
import "./low-code.scss";

interface LowCodeV2 {
  setSelectedStageName: React.Dispatch<React.SetStateAction<stages>>;
  submitTaskV2: Function;
  taskDefinitionKey: any;
  finalJSONSchema: any;
  setShowList: Function;
  getPreviousTask: Function;
  finalFormData: any;
  addGTag: Function;
  leadDetail: any;
  complementorUserId: string;
  setLoader: Function;
}

const LowCodeV2: React.FunctionComponent<LowCodeV2> = (props: LowCodeV2) => {
  const {
    setSelectedStageName,
    submitTaskV2,
    taskDefinitionKey,
    finalJSONSchema,
    setShowList,
    getPreviousTask,
    finalFormData,
    addGTag,
    leadDetail,
    complementorUserId,
    setLoader,
  } = props;

  const [formData, setFormData] = useState({} as any);
  const [isShowPanVerification, setIsShowPanVerification] = useState(false);
  const [panDetails, setPanDetails] = useState({} as any);

  useEffect(() => {
    let removedNull = Object.fromEntries(Object.entries(finalFormData).filter(([_, v]) => v != null));
    setFormData(removedNull);
  }, []);

  const checkValidTimeofStudy = (e) => {
    const arr = e?.timeofStudy?.split("/");
    const date = arr?.at(0) + "-" + moment().format("DD") + "-" + arr?.at(-1);
    if (
      e?.timeofStudy &&
      !moment(date).isSameOrAfter(moment(moment().format("yyyy-MM-DD")))
    ) {
      notification.error({
        message:
          "Please Select Time of Study greater than or equal to current month",
      });
      return false;
    }
    return true;
  };

  const submitTask = (e: any) => {
    if (checkValidTimeofStudy(e)) {
      submitTaskV2(e);
    }
  };

  const checkPanDetail = (data) => {
    if ((data?.panName?.length ?? 0) == 0) {
      return true;
    }

    setPanDetails({
      pan: data?.pan,
      name: data?.panName,
      dob: data?.dobHidden,
    });
    let dob = dayjs(data?.dobHidden).format("YYYY-MM-DD");
    let dobData = dayjs(data?.dateOfBirth).format("YYYY-MM-DD");
    if (data?.applicantName != data?.panName) {
      setIsShowPanVerification(true);
      return false;
    } else if ((data?.dobHidden?.length ?? 0) != 0 && dobData != dob) {
      setIsShowPanVerification(true);
      return false;
    }
    return true;
  };

  const handleFormSubmit = (e: any) => {
    console.log("handleFormSubmit: ", e);
    setFormData({ ...formData, ...e?.data });
    if (
      ["complementorApplicationWorkflow_step_1_task"].includes(
        taskDefinitionKey
      )
    ) {
      if (
        (e?.data?.applicantSubType === "SALARIED" ||
          e?.data?.applicantSubType === "SELF_EMPLOYED" ||
          e?.data?.applicantSubType === "SOLE_PROPRIETOR") &&
        ["complementorApplicationWorkflow_step_1_task"].includes(
          taskDefinitionKey
        ) &&
        !checkPanDetail(e?.data)
      ) {
        if ((e?.data?.panName?.length ?? 0) == 0) {
          notification.error({ message: "Please enter valid pan" });
        } else {
          setPanDetails({
            pan: e?.data?.pan,
            name: e?.data?.panName,
            dob: e?.data?.dobHidden,
          });
          setIsShowPanVerification(true);
        }
      } else {
        submitTask({ ...formData, ...e?.data });
        addGTag("Personal Details Upload");
      }
    } else {
      submitTask({ ...formData, ...e?.data });
    }
  };

  const handleCustomEvent = (customEvent: any) => {
    if (customEvent?.type === "eventFromBack") {
      getPreviousTask({});
    } else if (customEvent?.type === "eventFromClose") {
      setSelectedStageName("applicationDetails");
      setShowList(true);
    }
  };

  const actionPanContinue = () => {
    let dat = dayjs(panDetails?.dob).format("YYYY-MM-DD");
    setFormData({
      ...formData,
      applicantName: panDetails?.name ?? "",
      dateOfBirth:
        (panDetails?.dob?.length ?? 0) == 0 ? formData?.dateOfBirth : dat,
    });
    setIsShowPanVerification(false);
  };

  const renderPan = () => {
    return (
      <Modal
        className={isRazorPay(complementorUserId) ? "razor_pay_modal" : ""}
        title="Confirm Your Pan Details"
        centered
        width={400}
        onCancel={() => setIsShowPanVerification(false)}
        footer={null}
        open={isShowPanVerification}
      >
        <PanVerification
          panDetails={panDetails}
          contactDetails={formData}
          actionPanContinue={actionPanContinue}
          complementorUserId={complementorUserId}
        />
      </Modal>
    );
  };

  const json = jsonFile.JSONSchema;
  return (
    <>
      {isShowPanVerification && renderPan()}
      <div
        className={
          "formio-div-v2 " +
          (isRazorPay(complementorUserId) ? "razorPay-formIo" : "")
        }
      >
        <Form
          submission={{ data: formData }}
          key={"jsonForm"}
          onSubmit={handleFormSubmit}
          onCustomEvent={handleCustomEvent}
          form={finalJSONSchema}
          jsonSchema={finalJSONSchema}
          // onChange={debounce(handleOnChange, 1000)}
        />
      </div>
    </>
  );
};

export default LowCodeV2;
