import React, { useCallback, useState, useEffect } from "react";
import { Form as FormIo } from "react-formio";
import debounce from "lodash/debounce";
import moment from "moment";
import { stages } from "./ComplementorWidget";
import imgLogo_IM_blue from "../../assets/logo_IM_blue.svg";
import imgBanner from "../../assets/banner.svg";
import iconVerify from "../../assets/verify.svg";
import iconEmail from "../../assets/email.svg";
import iconLoan from "../../assets/loan.svg";
import iconApproval from "../../assets/approval.svg";
import iconLowInterest from "../../assets/low-interest.svg";
import imgOfferUser from "../../assets/offer-user.svg";
import thankYouSvg from "../../assets/thank-you.svg";
import imgLogo from "../../assets/logo.svg";
import {
  Button,
  Modal,
  notification,
  Typography,
  Form as ReactForm,
  Checkbox,
  Spin,
} from "antd";
import FormCheckbox from "../../components/FormCheckbox/FormCheckbox";
import FormInput from "../../components/FormInput/FormInput";
import { loanTypeList } from "../start/start";
import dayjs from "dayjs";
import {
  NUMBER_REGEX,
  PHONE_NUMBER_REGEX,
  STRING_ONLY,
} from "../../constants/AppConstants";
import API_SERVICE from "../../services/api-service";

import "./IndiaMart.scss";
import { useParams } from "react-router-dom";
import IndiaMartFormIo from "./IndiaMartFormIo";
import formJson from "../low-code/user_task_borrower_and_business_details.json";
import IndiaMartNew from "./IndiaMartNew";
import IndiaMartGetOtp from "./IndiaMartGetOtp";

const { Text, Link, Paragraph } = Typography;
interface IIndiaMart {
  getAppContent: any;
  selectedStageName: stages;
  renderStage: any;
  showList: boolean;
  taskDefinitionKey: any;
  connectorShortDetails: any;
  getNextTask: any;
  finalJSONSchema: any;
  setSelectedStageName: any;
  clickedSubmitTask: any;
  setShowList: any;
  getPreviousTask: any;
  finalFormData: any;
  addGTag: any;
  leadDetail: any;
  complementorUserId: any;
  setLoader: any;
  setLeadDetail: any;
  loanType: any;
  loader: any;
  isPreviousTask: any;
  setIsPreviousTask: any;
  isMobile: any;
}

const IndiaMart: React.FunctionComponent<IIndiaMart> = (props: IIndiaMart) => {
  const {
    getAppContent,
    selectedStageName,
    renderStage,
    showList,
    taskDefinitionKey,
    connectorShortDetails,
    getNextTask,
    finalJSONSchema,
    setSelectedStageName,
    clickedSubmitTask,
    setShowList,
    getPreviousTask,
    finalFormData,
    addGTag,
    leadDetail,
    complementorUserId,
    setLoader,
    setLeadDetail,
    loanType,
    loader,
    isPreviousTask,
    setIsPreviousTask,
    isMobile,
  } = props;

  const time = 60 * 2 - 1;
  const [formData, setFormData] = useState({} as any);
  //
  const [formGetOtp] = ReactForm.useForm() as any;
  const [formOTP] = ReactForm.useForm() as any;
  const [formCheckbox] = ReactForm.useForm() as any;
  const [formContinue] = ReactForm.useForm() as any;
  const [loading, setLoading] = useState(false);
  const [loadingGetOTP, setLoadingGetOTP] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [isGetOtpDisable, setIsGetOtpDisable] = useState(true);
  const [isNextDisable, setIsNextDisable] = useState(true);
  const [countDown, setCountDown] = React.useState(time);
  const [runTimer, setRunTimer] = React.useState(false);
  const [isTimeCalled, setIsTimeCalled] = useState(false);

  const [isOtpVerificatioRequired, setIsOtpVerificationRequired] =
    useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [finalSubmitData, setFinalSubmitData] = useState({} as any);
  const [serviceProvider, setTcChecked] = useState(false);
  const [turnOverDetails, setTurnOverDetails] = useState({} as any);
  const [isMobileNoEditable, setIsMobileNoEditable] = useState(false);

  const [isErrorDisplay, setIsErrorDisplay] = useState("");
  const [isErrorDisplayMobile, setIsErrorDisplayMobile] = useState("");
  const [isErrorDisplayPan, setIsErrorDisplayPan] = useState("");
  const [isErrorDisplayFirstName, setIsErrorDisplayFirstName] = useState("");
  const [isErrorDisplayEmail, setIsErrorDisplayEmail] = useState("");

  const [isOpenErrorModel, setIsOpenErrorModel] = useState(false);

  // const [leadDetail, setLeadDetails] = useState({} as any);

  const seconds = String(countDown % 60).padStart(2, "0");
  const minutes = String(Math.floor(countDown / 60)).padStart(2, "0");
  const stageNameForNoLogo = [
    "bankStatement",
    "applicationDetails",
    "offerMultilender",
    "offer",
    "offerMultilender",
  ];

  const fieldsKeyVal = {
    applicationSubType: "Applicant Type",
    firstName: "Full Name",
    mobileNumber: "Mobile Number",
    emailId: "Email ID",
    pinCode: "Pin Code",
    pan: "PAN",
    panHidden: "",
    dateOfBirth1: "",
    dateOfBirth: "Date Of Birth",
    entityOrEmployerName: "Business Name",
    businessProfile: "Business Profile",
    businessVintage: "No. of Years In Business",
    gender: "Gender",
    gstin: "GSTIN",
    loanAmount: "Required Loan Amount (INR)",
    mobileNumberDisable: "",
    businessTurnOverHide: "",
    businessTurnOver: "Annual Sales",
    city: "City",
  };

  const { type } = useParams() as any;

  useEffect(() => {
    const userStr = localStorage.getItem("user");
    const user = JSON.parse(userStr as string);
    const UserData = {
      ...user,
      ...connectorShortDetails,
      mobile: connectorShortDetails?.mobileNumber ?? user?.mobile,
    };
    localStorage.setItem("user", JSON.stringify(UserData));
    getIPAddress();
    formCheckbox?.setFieldsValue({
      serviceProvider: true,
    });
    formContinue?.setFieldsValue({
      serviceProvider: true,
    });
    if (connectorShortDetails?.mobileNumber) {
      getLeadList(connectorShortDetails?.mobileNumber);
    }
  }, [connectorShortDetails]);

  useEffect(() => {
    if (isPreviousTask) {
      const data = finalSubmitData;
      console.log("data::", data);
      getOtpStatus(data?.mobileNumber, data?.pan);
      setIsPreviousTask(false);
    }
  }, [isPreviousTask]);

  const getLeadList = (mobileNo) => {
    const data = {
      pageNo: 1,
      pageSize: 10,
    };

    let searchParams = new URL(window.location.href).searchParams;
    const urlParams: any = {};
    searchParams?.forEach((value, key) => {
      urlParams[key] = value;
    });

    const id = (urlParams?.sub_connector_id?.length ?? 0) > 0 ? urlParams?.sub_connector_id : complementorUserId;
    const extraparam = `&loanProductShortCode=${loanType}&sourceConnectorUserId=${id ?? ""}`
    API_SERVICE.getLeadList(mobileNo, data, extraparam)
      .then(({ data }) => {
        console.log(">>I>> listLead", data);
        if (data?.payload) {
          const caseData = data?.payload?.content[0];
          const caseStatus = caseData?.caseDetails?.caseStatus ?? "";
          if (data?.payload?.content.length > 1) {
            // localStorage.setItem("user", JSON.stringify(connectorShortDetails));
            setShowList(true);
            console.log("Move to application page");
          } else {
            let dt = dayjs(caseData?.caseDetails?.creationDate, "YYYY-MM-DD");
            const diff = dayjs().diff(dt, "day");
            if (diff > 30) {
              // localStorage.setItem("user", JSON.stringify(connectorShortDetails));
              setShowList(true);
            } else {
              if (
                caseStatus?.toUpperCase() == "LEAD" ||
                caseStatus?.toUpperCase() == "PROSPECT"
              ) {
                setLeadDetail(caseData);
                setTurnOverDetails(
                  caseData?.dsaCaseAdditionalFileds
                    ? caseData?.dsaCaseAdditionalFileds?.turnOverDTO
                    : caseData?.additionalFileds?.turnOverDTO
                );
                // setLeadDetails(caseData);
                getNextTask({
                  caseId: caseData?.caseDetails?.caseId,
                  prospectId: caseData?.caseDetails?.prospectId,
                  processInstanceId: caseData?.caseDetails?.processInstanceId,
                  loanType: caseData?.loanType,
                });
                localStorage.setItem(
                  "prospectId",
                  caseData?.caseDetails?.prospectId
                );
                const mobileNumber =
                  caseData?.contactDetails?.mobileNumber ??
                  finalSubmitData?.mobileNumber;
                const pan = caseData?.contactDetails?.pan;
                getOtpStatus(mobileNumber, pan);
              } else {
                setShowList(true);
              }
            }

            // setBusinessAndpersonalDetails();
          }
        }
      })
      .catch((e) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onFinish = (values: any) => {
    verifyOTP(values);
  };
  const actionResend = () => {
    console.log("countDown", countDown);
    if (countDown === 0) {
      signUp();
      // getOtpMobile(formGetOtp.getFieldValue("mobile"));
    }
  };
  //
  const renderTarraki = () => {
    return (
      <div className="view-tarraki">
        <div className="title-tarraki">
          <span className="tit">Tarraki ki rah par</span>
          <div className="verify">
            <img src={iconVerify} />
            RBI registered Banks and NBFCs
          </div>
        </div>
        <div className="border" />
        <span className="desc">
          100+ lenders, 25,000 + loans, instant sanctions*
        </span>
        {/* <div className="verify">
          <img src={iconVerify} />
          RBI registered Banks and NBFCs
        </div> */}
      </div>
    );
  };

  // const renderApply = () => {
  //   return (
  //     <div className="view-apply">
  //       <span className="tit">Apply For Business Loan</span>
  //       {renderStage()}
  //       <span className="powered-by">Powered by OneInfinity</span>
  //     </div>
  //   );
  // };

  const getOtpStatus = (mobileNo: any, pan: any) => {
    setIsNextDisable(true);
    const payload = {
      mobileNumber: mobileNo,
      panNumber: pan,
      caseId: leadDetail?.caseDetails?.caseId,
    };
    API_SERVICE.getOtpVerificationStatus(payload)
      .then(({ data }) => {
        if (data?.payload) {
          setIsOtpVerificationRequired(data?.payload?.otpverificationRequired);
          setIsGetOtpDisable(false);
          // setIsNextDisable(false);
        }
      })
      .catch((e) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };
  const getIPAddress = () => {
    API_SERVICE.getIPData()
      .then(({ data }) => {
        console.log("ip: ", data?.ip);
        setIpAddress(data.ip);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {});
  };
  const signUpWithOTP = (called) => {
    setLoadingGetOTP(true);
    const values = {
      mobile:
        leadDetail?.contactDetails?.mobileNumber ??
        finalSubmitData.mobileNumber,
      firstName: leadDetail?.contactDetails?.firstName,
    };
    const params = {
      ...values,
      dsaCaseId: connectorShortDetails?.dsaCaseId ?? "",
      processInstanceId: connectorShortDetails?.processInstanceId ?? "",
    };

    const loanTypeTmp =
      (loanType?.length ?? 0) > 0
        ? loanType
        : loanTypeList?.find((item: any) => item?.code == type)?.key ??
          "BUSINESS_INSTALLMENT_LOAN";

    API_SERVICE.singUp(loanTypeTmp, complementorUserId, params)
      .then(({ data }) => {
        if (data) {
          const userStr = localStorage.getItem("user");
          const user = JSON.parse(userStr as string);
          const userModel = {
            ...data.payload,
            ...user,
            ...values,
            ...connectorShortDetails,
          };
          localStorage.setItem("user", JSON.stringify(userModel));
          formGetOtp.setFieldsValue({
            mobile: user?.mobile,
          });
          notification.success({ message: data.message });
          setVerificationId(data?.payload?.id);
          setIsGetOtpDisable(true);
          setIsNextDisable(false);
          setCountDown(time);
          setRunTimer(true);
          setIsTimeCalled(called);
          setIsMobileNoEditable(true);
          setFinalSubmitData({
            ...finalSubmitData,
            mobileNumberDisable: "true",
          });
          setFormData({ ...formData, mobileNumberDisable: "true" });
          const updateLeadDetails = createPayload(finalSubmitData, true);
          setLeadDetail(updateLeadDetails);
          // setLeadDetail({...leadDetail, mobileNumberEditable: false,
          //   contactDetails: {...leadDetail?.contactDetails, mobileNumber: finalSubmitData.mobileNumber, emailId: finalSubmitData?.emailId },
          //   caseDetails: {...leadDetail?.caseDetails, loanDetails: { ...leadDetail?.caseDetails?.loanDetails, loanAmount: finalSubmitData?.loanAmount }}
          // });
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoadingGetOTP(false);
      });
  };
  const signUp = (called = false) => {
    const erroMsg = checkFieldsValues();
    if (erroMsg) {
      setIsOpenErrorModel(true);
    } else {
      if (leadDetail?.mobileNumberEditable) {
        const payload = {
          mobileNumberToBeUpdated: finalSubmitData?.mobileNumber,
          dsaCaseId: leadDetail?.caseDetails?.caseId,
        };
        API_SERVICE.updateMobileNo(payload)
          .then(({ data }) => {
            if (data) {
              signUpWithOTP(called);
            }
          })
          .catch(function (e) {
            // notification.error({ message: API_SERVICE.handleErrors(e) });
          })
          .finally(() => {
            // setLoadingGetOTP(false);
          });
      } else {
        signUpWithOTP(called);
      }
    }
  };
  const verifyOTP = (values: any) => {
    const erroMsg = checkFieldsValues();
    if (erroMsg) {
      setIsOpenErrorModel(true);
    } else {
      const data = {
        mobileOtp: values?.otp,
        emailId: "",
        emailOtp: "",
        mobileNumber: finalSubmitData?.mobileNumber,
        verificationId: verificationId,
        panNumber: finalSubmitData?.pan,
        caseId: leadDetail?.caseDetails?.caseId,
        consentAdditionalInfo: {
          deviceType: window.navigator.platform,
          ipAddress: ipAddress,
          tcMessage:
            "I agree to the T&C, Privacy policy and authorise OneInfinity representatives to contact me and receive my credit information from Equifax. This consent supersedes any DNC registration by me.",
          verificationId: verificationId,
        },
      };

      setLoading(true);
      API_SERVICE.verifyOtpForIndiaMart(data)
        .then(({ data }) => {
          const userStr = localStorage.getItem("user");
          const user = JSON.parse(userStr as string);
          const userModel = {
            ...data.payload,
            ...user,
            ...connectorShortDetails,
            buyLeadsTransactionId: user?.buyLeadsTransactionId,
            mobile: finalSubmitData?.mobileNumber,
          };
          localStorage.setItem("user", JSON.stringify(userModel));
          console.log("Payload finalSubmitData", finalSubmitData);
          const payload = createPayload(finalSubmitData);
          clickedSubmitTask(payload);
          // if (loanType === "BUY_LEADS") {
          //   setSelectedStageName("buyLead");
          //   updateDropOfStageBuyLead("OTP_VERIFIED");
          // } else {
          //   getLeadList();
          // }
        })
        .catch((e: any) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  // submit case via continue methode
  const onSubmit = () => {
    const erroMsg = checkFieldsValues();
    if (erroMsg) {
      setIsOpenErrorModel(true);
    } else {
      if (leadDetail?.mobileNumberEditable) {
        // onChangeMobileNo(finalSubmitData?.mobileNumber);
        const payload = {
          mobileNumberToBeUpdated: finalSubmitData?.mobileNumber,
          dsaCaseId: leadDetail?.caseDetails?.caseId,
        };
        API_SERVICE.updateMobileNo(payload)
          .then(({ data }) => {
            if (data) {
              const userStr = localStorage.getItem("user");
              const user = JSON.parse(userStr as string);
              const UserData = {
                ...user,
                ...connectorShortDetails,
                mobile: finalSubmitData?.mobileNumber,
                mobileNumber: finalSubmitData?.mobileNumber,
              };
              localStorage.setItem("user", JSON.stringify(UserData));
              const payload = createPayload(finalSubmitData);
              clickedSubmitTask(payload);
            }
          })
          .catch(function (e) {
            // notification.error({ message: API_SERVICE.handleErrors(e) });
          })
          .finally(() => {
            // setLoadingGetOTP(false);
          });
      } else {
        const payload = createPayload(finalSubmitData);
        clickedSubmitTask(payload);
      }
    }
  };
  const checkFieldsValues = () => {
    let keyNam = "Please Enter - ";
    const orignalData = {
      firstName: "",
      mobileNumber: "",
      emailId: "",
      pinCode: "",
      pan: "",
      dateOfBirth1: "",
      dateOfBirth: "",
      entityOrEmployerName: "",
      businessProfile: "",
      businessVintage: "",
      loanAmount: "",
      applicationSubType: "",
    };
    // applicationSubType: "LLP",
    // gender: "",
    const ApplicationType = finalSubmitData.applicationSubType;
    const ApplicationTypeList = ["SOLE_PROPRIETOR", "SELF_EMPLOYED"];
    const extraKey = {
      dateOfBirth1: "1990-03-12T01:25:27.000+00:00",
      dobHidden: "1990-03-12T01:25:27.000+00:00",
      panHidden: "OKMYT8823L",
      panName: "",
      pinCodeHidden: "472921",
      state: "",
      gender: "",
      cityExtra: "",
      businessTurnOverHide: "",
      mobileNumberDisable: "",
    };
    const extraKeysForNoTournOver = {
      ...extraKey,
      businessTurnOver: "",
    };
    const extraKeyForIndivisual = {
      dateOfBirth1: "1990-03-12T01:25:27.000+00:00",
      dobHidden: "1990-03-12T01:25:27.000+00:00",
      panHidden: "OKMYT8823L",
      panName: "",
      pinCodeHidden: "472921",
      state: "",
      cityExtra: "",
      businessTurnOverHide: "",
      mobileNumberDisable: "",
    };
    const extraKeysFoNoTurnOverIndividual = {
      ...extraKeyForIndivisual,
      businessTurnOver: "",
    };
    const regexForMobile = new RegExp("^[6,7,8,9][0-9]{9}$");
    const regexMail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const regexForPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
    const regexForName = /^[A-Za-z\\s ]+$/;
    if (finalSubmitData.mobileNumber) {
      const isValid = regexForMobile.test(finalSubmitData.mobileNumber);
      const erroMsg =
        "Mobile Number must have no more than 10 characters. Mobile Number should start with 6,7,8,9.";
      if (!isValid) {
        setIsErrorDisplayMobile(erroMsg);
        // return "Mobile Number must have no more than 10 characters. Mobile Number should start with 6,7,8,9";
      } else {
        setIsErrorDisplayMobile('');
      }
    }
    if (finalSubmitData.firstName) {
      const isValid = regexForName.test(finalSubmitData.firstName);
      const erroMsg =
        "Invalid Full Name, Full Name accept only Alphabets.";
      if (!isValid) {
        setIsErrorDisplayFirstName(erroMsg);
        // return "Full Name is not valid, Full Name accept only Alphabets.";
      } else {
        setIsErrorDisplayFirstName('');
      }
    }
    if (finalSubmitData.emailId) {
      console.log("finalSubmitData", finalSubmitData);
      const isValid = regexMail.test(finalSubmitData.emailId);
      const erroMsg = "Invalid Email, Please enter valid Email.";
      if (!isValid) {
        setIsErrorDisplayEmail(erroMsg);
      } else {
        setIsErrorDisplayEmail('');
      }
    }

    if (finalSubmitData.pan) {
      const isValid = regexForPan.test(finalSubmitData.pan);
      const erroMsg = "Invalid PAN, Please enter valid PAN.";
      if (!isValid) {
        setIsErrorDisplayPan(erroMsg);
      } else {
        setIsErrorDisplayPan('');
      }
    }

    const extraKeys = ApplicationTypeList.includes(ApplicationType)
      ? leadDetail?.dsaCaseAdditionalFileds
        ? !leadDetail?.dsaCaseAdditionalFileds?.turnOverDTO ||
          leadDetail?.dsaCaseAdditionalFileds?.turnOverDTO?.canUIEdit
          ? Object.keys(extraKeyForIndivisual)
          : Object.keys(extraKeysFoNoTurnOverIndividual)
        : !leadDetail?.additionalFileds?.turnOverDTO ||
          leadDetail?.additionalFileds?.turnOverDTO?.canUIEdit
        ? Object.keys(extraKeyForIndivisual)
        : Object.keys(extraKeysFoNoTurnOverIndividual)
      : leadDetail?.dsaCaseAdditionalFileds
      ? !leadDetail?.dsaCaseAdditionalFileds?.turnOverDTO ||
        leadDetail?.dsaCaseAdditionalFileds?.turnOverDTO?.canUIEdit
        ? Object.keys(extraKey)
        : Object.keys(extraKeysForNoTournOver)
      : !leadDetail?.additionalFileds?.turnOverDTO ||
        leadDetail?.additionalFileds?.turnOverDTO?.canUIEdit
      ? Object.keys(extraKey)
      : Object.keys(extraKeysForNoTournOver);
    console.log("extraKeys", extraKeys);
    const keys = Object.keys(finalSubmitData).filter(
      (item) => !extraKeys.includes(item)
    );
    let isError = false;
    keys.filter((itemKey, index) => {
      if (!finalSubmitData[itemKey]) {
        isError = true;
        keyNam = keyNam + fieldsKeyVal[itemKey] + ", ";
      }
    });
    if (isError) {
      // isErrorDisplay.push(keyNam);
      setIsErrorDisplay(keyNam);
    } else {
      setIsErrorDisplay('');
    }
    return isError ? keyNam : "";
  };
  // const onChangeMobileNo = (mobileNo: any) => {
  //   const payload = {
  //     mobileNumberToBeUpdated: mobileNo,
  //     dsaCaseId: leadDetail?.caseDetails?.caseId,
  //   };
  //   API_SERVICE.updateMobileNo(payload)
  //     .then(({ data }) => {
  //       if (data) {
  //         // const userStr = localStorage.getItem("user");
  //         // const user = JSON.parse(userStr as string);
  //         // const userModel = {
  //         //   ...data.payload,
  //         //   ...user,
  //         //   // ...values,
  //         //   ...connectorShortDetails,
  //         // };
  //         // localStorage.setItem("user", JSON.stringify(userModel));
  //         // formGetOtp.setFieldsValue({
  //         //   mobile: user?.mobile,
  //         // });
  //         // notification.success({ message: data.message });
  //         // setVerificationId(data?.payload?.id);
  //         // setIsGetOtpDisable(true);
  //         // setIsNextDisable(false);
  //         // setCountDown(time);
  //         // setRunTimer(true);
  //         // setIsTimeCalled(called);
  //       }
  //     })
  //     .catch(function (e) {
  //       // notification.error({ message: API_SERVICE.handleErrors(e) });
  //     })
  //     .finally(() => {
  //       // setLoadingGetOTP(false);
  //     });
  // };

  //

  const renderFeatures = () => {
    return (
      <div className="view-features">
        <span className="tit">Instant Business Loans</span>
        <div className="border" />
        <div className="main-features">
          <div className="features">
            <img src={iconLoan} />
            <div>
              <span className="tite">Business Loans upto ₹5,00,00,000</span>
              <span>by RBI licensed Banks and NBFCs</span>
            </div>
          </div>
          <div className="features">
            <img src={iconApproval} />
            <div>
              <span className="tite">Easy Approval</span>
              <span>Approval in 3 easy steps</span>
            </div>
          </div>
          <div className="features">
            <img src={iconLowInterest} />
            <div>
              <span className="tite">Low Interest</span>
              <span>Interest as low as 13% per annum</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderCheckBoxDetails = () => {
    return (
      <div style={{ display: "grid" }}>
        <Text>
          I agree to the{" "}
          <Link
            onClick={() => getAppContent("TERMS_AND_CONDITIONS")}
            target="_blank"
          >
            T & C
          </Link>
          ,{" "}
          <Link onClick={() => getAppContent("PRIVACY_POLICY")} target="_blank">
            Privacy policy
          </Link>{" "}
          and authorise OneInfinity representatives to contact me and receive my
          credit information from Equifax. This consent supersedes any DNC
          registration by me.
        </Text>
      </div>
    );
  };
  // const renderCustomComponent = () => {
  //   return (
  //     <div className="mt-4">
  //     <ReactForm
  //         layout="vertical"
  //         autoComplete="off"
  //         name="leadFormVerifyOTP"
  //         style={{ maxWidth: "400px" }}
  //         className="form-persion"
  //         key='leadFormVerifyOTP'
  //         form={formOTP}
  //         onFinish={onFinish}
  //       >
  //         <FormInput
  //           name="otp"
  //           type="password"
  //           label="Enter OTP"
  //           placeholder=" Enter OTP"
  //           inputMode="numeric"
  //           className="otp-input"
  //           isRequired
  //           isDisabled={isNextDisable}
  //           rules={[
  //             {
  //               pattern: NUMBER_REGEX,
  //               message: "Please enter valid OTP",
  //             },
  //           ]}
  //         />

  //         {!isNextDisable && (
  //           <div>
  //             Didn't receive the OTP? &nbsp;
  //             <a onClick={actionResend} style={{ color: "#C71C8E" }}>
  //               {isTimeStoped ? "RESEND OTP" : `${minutes}:${seconds}`}

  //               <br />
  //             </a>
  //           </div>
  //         )}
  //         <Button
  //           type="primary"
  //           htmlType="submit"
  //           size="large"
  //           className="btn-next"
  //           loading={loading}
  //           disabled={isNextDisable}
  //         >
  //           Continue
  //         </Button>
  //       </ReactForm>
  //     </div>
  //   )
  // }
  const onTcChange = () => {
    setTcChecked(!serviceProvider);
    const data = formCheckbox.getFieldValue("serviceProvider");
    console.log("data", data);
    formCheckbox.setFieldsValue({
      serviceProvider: data,
    });
  };
  const onTcChangeContinue = () => {
    setTcChecked(!serviceProvider);
    const data = formContinue.getFieldValue("serviceProvider");
    console.log("data1", data);
    formContinue.setFieldsValue({
      serviceProvider: data,
    });
  };
  const renderFormIo = () => {
    return (
      <div className="view-formio">
        <div className="view-formio-header">Apply For Business Loan</div>
        <div className="view-formio-page">
          <IndiaMartFormIo
            formData={formData}
            finalJSONSchema={finalJSONSchema}
            // handleOnChange={handleOnChange}
            finalSubmitData={finalSubmitData}
            setFinalSubmitData={setFinalSubmitData}
            setFormData={setFormData}
            taskDefinitionKey={taskDefinitionKey}
            finalFormData={finalFormData}
            leadDetail={leadDetail}
            getOtpStatus={getOtpStatus}
            isMobileNoEditable={isMobileNoEditable}
            setIsMobileNoEditable={setIsMobileNoEditable}
            checkFieldsValues={checkFieldsValues}
            // onChangeMobileNo={onChangeMobileNo}
          />
          {/* <FormIo
            submission={{ data: formData }}
            key={"jsonForm"}
            // onSubmit={handleFormSubmit}
            // onCustomEvent={handleCustomEvent}
            form={finalJSONSchema}
            jsonSchema={finalJSONSchema}
            // form={formJson.JSONSchema}
            // jsonSchema={formJson.JSONSchema}
            onChange={handleOnChange}
          /> */}
        </div>
        <div className="view-footer-section">
          <div className="footer-section">
            {isOtpVerificatioRequired ? (
              <>
                <IndiaMartGetOtp
                  isMobile={isMobile}
                  formCheckbox={formCheckbox}
                  signUp={signUp}
                  onTcChange={onTcChange}
                  renderCheckBoxDetails={renderCheckBoxDetails}
                  isGetOtpDisable={isGetOtpDisable}
                  loadingGetOTP={loadingGetOTP}
                  isErrorDisplay={isErrorDisplay}
                  isErrorDisplayMobile={isErrorDisplayMobile}
                  isErrorDisplayEmail={isErrorDisplayEmail}
                  isErrorDisplayPan={isErrorDisplayPan}
                  isErrorDisplayFirstName={isErrorDisplayFirstName}
                />
                {/* {!isNextDisable && renderCustomComponent()} */}
                {!isNextDisable && (
                  <IndiaMartNew
                    formOTP={formOTP}
                    onFinish={onFinish}
                    isNextDisable={isNextDisable}
                    signUp={signUp}
                    loading={loading}
                    runTimer={runTimer}
                    setRunTimer={setRunTimer}
                    setIsTimeCalled={setIsTimeCalled}
                    isTimeCalled={isTimeCalled}
                    loader={loader}
                  />
                )}
                <div className="powered-by">
                  <span>Powered by </span>
                  <div>
                    <img src={imgLogo} height={45} />
                  </div>
                </div>
              </>
            ) : (
              <>
                <ReactForm
                  layout="vertical"
                  autoComplete="off"
                  name="leadFormSubmit"
                  key="leadFormSubmit"
                  // style={{ maxWidth: "400px" }}
                  className="form-persion"
                  form={formContinue}
                  onFinish={onSubmit}
                >
                  <div className="errorMsg">{isErrorDisplayFirstName}</div>
                  <div className="errorMsg">{isErrorDisplayMobile}</div>
                  <div className="errorMsg">{isErrorDisplayEmail}</div>
                  <div className="errorMsg">{isErrorDisplayPan}</div>
                  <div className="errorMsg">{isErrorDisplay}</div>
                  <FormCheckbox
                    name={"serviceProvider"}
                    isRequired
                    msgRequired="Terms and Conditions & Privacy policy"
                    onChange={onTcChangeContinue}
                    textElement={renderCheckBoxDetails()}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="btn-next"
                    loading={loader}
                    disabled={isOtpVerificatioRequired}
                  >
                    Continue
                  </Button>
                </ReactForm>
                <div className="powered-by">
                  <span>Powered by </span>
                  <div>
                    <img src={imgLogo} height={45} />
                  </div>
                </div>
              </>
            )}
            {/* {renderStage()} */}
          </div>
        </div>
      </div>
    );
  };

  // const setBusinessAndpersonalDetails = () => {
  //   console.log('leadDetail', leadDetail);
  //   let contactDetails = leadDetail?.contactDetails;
  //   let applicationSubType =
  //   leadDetail?.applicationSubTypeKey ?? leadDetail?.applicationSubType;
  //   let address = leadDetail?.address ?? finalFormData?.address;

  //   const pan =
  //     (contactDetails?.pan?.length ?? 0) === 0 ||
  //     contactDetails?.pan === "PENDING"
  //       ? ""
  //       : contactDetails.pan;

  //   console.log('leadDetail', leadDetail);
  //   const tempFormData = {
  //     applicationSubType: applicationSubType ?? "",
  //     firstName: contactDetails?.firstName ?? "",
  //     mobileNumber: contactDetails?.mobileNumber ?? "",
  //     emailId: contactDetails?.emailId ?? "",
  //     pinCode: leadDetail?.officePincode,
  //     pan: pan ?? "",
  //     dateOfBirth1: contactDetails?.dateOfBirth,
  //     dateOfBirth: contactDetails?.dateOfBirth,
  //     entityOrEmployerName: leadDetail?.entityOrEmployerName ?? "",
  //     businessProfile: leadDetail?.businessProfile ?? "",
  //     businessVintage: leadDetail?.businessVintage ?? "",
  //     loanAmount: leadDetail?.caseDetails?.loanDetails?.loanAmount == 0 ? null : leadDetail?.caseDetails?.loanDetails?.loanAmount,
  //   }
  //   console.log('tempFormData', tempFormData);
  //   setFormData(tempFormData);
  // }

  // const handleOnChange = (submission: any) => {
  //   console.log('submission', submission.data);
  //   console.log('submission - form', formData);
  //   setFinalSubmitData(submission.data);
  //   if ((submission?.changed?.component?.key ?? "") === "emailId") {
  //     let value = "" as string;
  //     value = submission?.changed?.value ?? "";
  //     console.log("handleOnChange emailId:  ", value);
  //     localStorage.setItem("emailLowCode", value);
  //   } else if ((submission?.changed?.component?.key ?? "") === "gender") {
  //     let value = "" as string;
  //     value = submission?.changed?.value ?? "";
  //     console.log("handleOnChange gender:  ", value);
  //     localStorage.setItem("genderLowCode", value);
  //   } else if (
  //     (submission?.changed?.component?.key ?? "") === "applicationSubType" &&
  //     !["user_task_employer_details_bnpl", "user_task_borrower_and_loan_details_el"].includes(taskDefinitionKey)
  //   ) {
  //     const updateFormData = {
  //       firstName: formData?.firstName ?? "",
  //       mobileNumber: formData?.mobileNumber ?? "",
  //       emailId: formData?.emailId ?? "",
  //       pinCode: formData?.pinCode,
  //       pan: formData?.pan ?? "",
  //       dateOfBirth1: formData?.dateOfBirth,
  //       dateOfBirth: formData?.dateOfBirth,
  //       entityOrEmployerName: formData?.entityOrEmployerName ?? "",
  //       businessProfile: formData?.businessProfile ?? "",
  //       businessVintage: formData?.businessVintage ?? "",
  //       loanAmount: formData?.loanAmount == 0 ? null : formData?.loanAmount,
  //       applicationSubType: submission.data.applicationSubType ?? "",
  //     }
  //     console.log('updateFormData', updateFormData);
  //     setFormData(updateFormData);
  //   } else if (
  //     (submission?.changed?.component?.key ?? "") === "pan1" &&
  //     !["user_task_borrower_details_bnpl"].includes(taskDefinitionKey)
  //   ) {
  //     // const value = submission?.changed?.value;
  //     // console.log("value:   ", value);
  //     // const regex = new RegExp(PAN_REGEX);
  //     // if (regex.test(submission?.changed?.value)) {
  //     //   if (submission?.changed?.value != panDetails?.pan) {
  //     //     console.log("getpan:   ", value);
  //     //     getPanDetails(submission?.changed?.value, submission?.data);
  //     //   } else {
  //     //     setPanDetailToForm(panDetails, submission?.data);
  //     //   }
  //     // } else {
  //     //   // setPanDetails({});
  //     //   // setFormData({
  //     //   //   ...formData,
  //     //   //   ...submission?.data,
  //     //   //   pan: submission?.changed?.value,
  //     //   //   panName: "",
  //     //   //   city: "",
  //     //   //   pinCode: "",
  //     //   //   dateOfBirth: "",
  //     //   //   entityOrEmployerName: "",
  //     //   //   businessVintage: "",
  //     //   // });
  //     // }
  //   }
  // };

  const renderRegisterOTP = () => {
    return (
      <div className="view-width-indiamart">
        <div className="view-header-section">
          {/* <img src={imgBanner} alt="" style={{ width: "100%" }} /> */}
          {renderTarraki()}
          {/* {renderApply()} */}
        </div>
        <div className="view-main">
          <div className="view-main-data">{renderFormIo()}</div>
        </div>
        {renderFeatures()}
      </div>
    );
  };
  const renderThankYouScreen = () => {
    return (
      <div className="Thank-you-screen">
        <div className="thank-you-image">
          <img src={thankYouSvg} height={400} width={400} />
        </div>
        <div className="thank-you-text">
          <span>We are processing your loan request</span>
          <span>and will be in touch shortly!.</span>
        </div>
        <div className="thank-you-btn">
          <button
            className="btn btn-primary next_btn"
            onClick={() => {
              clickedSubmitTask({});
            }}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const renderOfferView = () => {
    return (
      <div className="view-width-indiamart offer-indiamart">
        <div className="offer-user">
          <img src={imgOfferUser} />
        </div>
        <div className="offer-view" style={{ position: "relative" }}>
          {renderStage()}
        </div>
      </div>
    );
  };

  const renderView = () => {
    console.log("selectedStageName", selectedStageName);
    if (
      (selectedStageName === "register" || selectedStageName === "otp") &&
      !showList
    ) {
      return renderRegisterOTP();
    } else if (
      (selectedStageName === "flowable" ||
        selectedStageName === "offerMultilender" ||
        selectedStageName === "offer") &&
      [
        "user_task_offer_details",
        "user_task_offer_details_hl",
        "user_task_bureau_offer_bil",
        "user_task_not_eligible_offer_details_hl",
        "user_task_multi_lender_offer_details_bil",
      ].includes(taskDefinitionKey) &&
      !showList
    ) {
      return renderOfferView();
    } else if (
      selectedStageName === "bankStatement" ||
      selectedStageName === "applicationDetails" ||
      selectedStageName === "offerMultilender" ||
      showList
    ) {
      return <div style={{ paddingBlock: "20px" }}>{renderStage()}</div>;
    } else if (
      selectedStageName === "flowable" &&
      ["user_task_thank_you_page_bil"].includes(taskDefinitionKey)
    ) {
      return renderThankYouScreen();
    } else {
      return <div style={{ paddingBlock: "20px" }}>{renderRegisterOTP()}</div>;
    }
  };

  const createPayload = (formData: any, mobileNumberDisable: any = false) => {
    console.log("formData: ", formData);

    const leadDataTmp = leadDetail ?? finalFormData;

    const caseDetails = leadDataTmp?.caseDetails ?? finalFormData?.caseDetails;
    const address = leadDataTmp?.address ?? finalFormData?.address;

    const loanType = leadDataTmp?.loanType ?? finalFormData?.loanType;
    const loanProductId =
      leadDataTmp?.loanProductId ?? finalFormData?.loanProductId;
    const connectorId =
      leadDataTmp?.connectorId ??
      caseDetails?.connectorDetails?.userDetails?.userId ??
      complementorUserId;
    const assignToUserId =
      leadDataTmp?.assignToUserId ??
      leadDataTmp?.assignedToUserId ??
      caseDetails?.assignedToUserDetails?.userId ??
      "";
    const businessVintage = finalSubmitData?.businessVintage;
    const dob =
      (finalSubmitData?.dateOfBirth?.length ?? 0) > 0
        ? finalSubmitData?.dateOfBirth ?? ""
        : formData.dateOfBirth ??
          leadDataTmp?.contactDetails?.dateOfBirth ??
          "";

    const bnplLoanDetails =
      leadDataTmp?.dsaCaseAdditionalFileds?.bnplLoanDetails;

    const studendDOB =
      finalSubmitData?.studentDOB ?? bnplLoanDetails?.studentDOB ?? "";
    const turnOverDTO = leadDetail?.dsaCaseAdditionalFileds
      ? leadDetail?.dsaCaseAdditionalFileds?.turnOverDTO
      : leadDetail?.additionalFileds?.turnOverDTO;

    const data = {
      dsaCaseId: caseDetails?.caseId ?? "",
      loanType: loanType,
      loanProductId: loanProductId,
      mobileNumberDisable: mobileNumberDisable,
      sourceOfCase: "CONNECTOR",
      address: {
        address: "",
        area: finalSubmitData.city ?? address?.city,
        city: finalSubmitData.city ?? address?.city,
        country: "",
        geoCode: "",
        pinCode: finalSubmitData.pinCode ?? address?.pinCode,
        state: finalSubmitData?.state ?? address?.state ?? "",
      },
      applicationType:
        finalSubmitData.applicationSubType.toUpperCase() === "SELF_EMPLOYED" ||
        finalSubmitData.applicationSubType.toUpperCase() === "SELF EMPLOYED" ||
        finalSubmitData.applicationSubType.toUpperCase() === "SALARIED" ||
        loanType == "Personal Loan" ||
        loanType == "Home Loan"
          ? "INDIVIDUAL"
          : "BUSINESS",
      applicationSubType:
        loanType == "Personal Loan" || loanType == "Home Loan"
          ? "SALARIED"
          : loanType == "Buy Now Pay Later"
          ? finalSubmitData?.applicationSubType
              ?.toUpperCase()
              ?.replace(" ", "_")
          : finalSubmitData.applicationSubType,
      businessProfile:
        (finalSubmitData?.businessProfile?.length ?? 0) === 0
          ? null
          : finalSubmitData?.businessProfile ?? null,
      businessTurnOver: null,
      businessVintage:
        (businessVintage ?? 0) != 0
          ? businessVintage
          : finalSubmitData?.businessVintage ??
            businessVintage ??
            leadDetail?.businessVintage ??
            null,
      monthlyEmi: finalSubmitData.monthlyEmi ?? leadDataTmp?.monthlyEmi ?? null,
      comments: "",
      assignToUserId: assignToUserId,
      backOfficeUserId: "",
      officePincode: finalSubmitData.pinCode ?? "",
      officeCity: finalSubmitData.officeCity ?? "",
      industryType: finalSubmitData?.industryType ?? null,
      currentWorkExperience: finalSubmitData?.currentWorkExperience
        ? finalSubmitData?.currentWorkExperience?.split("/").at(-1) +
          "-" +
          finalSubmitData?.currentWorkExperience?.split("/").at(0)
        : null,
      totalWorkExperience: finalSubmitData?.totalWorkExperience
        ? finalSubmitData?.totalWorkExperience?.split("/").at(-1) +
          "-" +
          finalSubmitData?.totalWorkExperience?.split("/").at(0)
        : null,
      commissionDetail: {
        connectorName: "",
        finalCommissionAmount: 0,
        finalCommissionPercentage: 0,
        tentativeCommissionAmount: 0,
        tentativeCommissionPercentage: 0,
      },
      connectorId: connectorId,
      dsaCustomerId: leadDataTmp?.dsaCustomerId,
      nationality: null,
      bureauScoreStatus: null,
      contactDetails: {
        gender:
          (finalSubmitData?.gender?.length ?? 0) === 0
            ? null
            : finalSubmitData?.gender,
        firstName: finalSubmitData.firstName,
        lastName: "",
        middleName: "",
        dateOfBirth: dob ? moment(dob).format("YYYY-MM-DD").toString() : "",
        emailId: finalSubmitData.emailId,
        mobileNumber:
          leadDataTmp?.contactDetails?.mobileNumber ??
          finalSubmitData?.mobileNumber,
        pan: finalSubmitData.pan,
      },
      entityOrEmployerName: finalSubmitData.entityOrEmployerName,
      caseDetails: {
        ...caseDetails,
        loanDetails: {
          expectedInterestRate:
            caseDetails?.loanDetails?.expectedInterestRate ?? 0,
          loanAmount:
            finalSubmitData?.loanAmount ??
            finalSubmitData?.orderAmount ??
            formData?.loanAmount,
          loanTenureInYears:
            finalSubmitData?.loanTenureInYears ??
            caseDetails?.loanDetails?.loanTenureInYears ??
            0,
          currentEmis:
            finalSubmitData.currentEmis ??
            finalSubmitData.monthlyEmi ??
            caseDetails?.loanDetails?.currentEmis,
          monthlyInHandSalary:
            finalSubmitData?.monthlyInHandSalary ??
            finalSubmitData?.salary ??
            caseDetails?.loanDetails?.monthlyInHandSalary,
          costOfProperty: finalSubmitData?.costOfProperty,
          consentDetails: {
            consentDate:
              caseDetails?.loanDetails?.consentDetails?.consentDate ?? null,
            isConsent:
              finalSubmitData?.isConsent ??
              caseDetails?.loanDetails?.consentDetails?.isConsent,
            ipAddress:
              caseDetails?.loanDetails?.consentDetails?.ipAddress ?? null,
            deviceType:
              caseDetails?.loanDetails?.consentDetails?.deviceType ?? null,
          },
          educationLoanDetails: {
            timeOfStudy: finalSubmitData?.timeofStudy
              ? finalSubmitData?.timeofStudy?.split("/").at(-1) +
                "-" +
                finalSubmitData?.timeofStudy?.split("/").at(0)
              : null,
            placeOfStudy: finalSubmitData?.placeOfStudy ?? null,
            admissionStatus: finalSubmitData?.admissionStatus ?? null,
          },
        },
      },
      loanDetails: {
        expectedInterestRate:
          caseDetails?.loanDetails?.expectedInterestRate ?? 0,
        loanAmount:
          finalSubmitData?.loanAmount ??
          finalSubmitData?.orderAmount ??
          caseDetails?.loanDetails?.loanAmount,
        loanTenureInYears:
          finalSubmitData?.loanTenureInYears ??
          caseDetails?.loanDetails?.loanTenureInYears ??
          0,
        currentEmis:
          finalSubmitData.currentEmis ??
          finalSubmitData.monthlyEmi ??
          caseDetails?.loanDetails?.currentEmis,
        monthlyInHandSalary:
          finalSubmitData?.monthlyInHandSalary ??
          finalSubmitData?.salary ??
          caseDetails?.loanDetails?.monthlyInHandSalary,
        costOfProperty: finalSubmitData?.costOfProperty,
        consentDetails: {
          consentDate:
            caseDetails?.loanDetails?.consentDetails?.consentDate ?? null,
          isConsent:
            finalSubmitData?.isConsent ??
            caseDetails?.loanDetails?.consentDetails?.isConsent,
          ipAddress:
            caseDetails?.loanDetails?.consentDetails?.ipAddress ?? null,
          deviceType:
            caseDetails?.loanDetails?.consentDetails?.deviceType ?? null,
        },
        educationLoanDetails: {
          timeOfStudy: finalSubmitData?.timeofStudy
            ? finalSubmitData?.timeofStudy?.split("/").at(-1) +
              "-" +
              finalSubmitData?.timeofStudy?.split("/").at(0)
            : null,
          placeOfStudy: finalSubmitData?.placeOfStudy ?? null,
          admissionStatus: finalSubmitData?.admissionStatus ?? null,
        },
      },
      propertyOwnership:
        (finalSubmitData?.propertyOwnership?.length ?? 0) === 0
          ? null
          : finalSubmitData?.propertyOwnership,
      dsaCaseAdditionalFileds: {
        bnplLoanDetails: {
          instituteName:
            finalSubmitData?.instituteName ??
            bnplLoanDetails?.instituteName ??
            "",
          branchName:
            finalSubmitData?.branchName ?? bnplLoanDetails?.branchName ?? "",
          courseName:
            finalSubmitData?.courseName ?? bnplLoanDetails?.courseName ?? "",
          studentFullName:
            finalSubmitData?.studentName ??
            bnplLoanDetails?.studentFullName ??
            "",
          studentDOB:
            (studendDOB?.length ?? 0) == 0
              ? ""
              : moment(studendDOB).format("yyyy-MM-DD"),
          bnplApplicantType:
            finalSubmitData?.bnplApplicantType ??
            bnplLoanDetails?.bnplApplicantType ??
            null,
          officePinCode:
            finalSubmitData?.pinCode ?? finalSubmitData?.pinCode ?? "",
          entityOrEmployerName:
            finalSubmitData?.entityOrEmployerName ??
            bnplLoanDetails?.entityOrEmployerName ??
            "",
        },
        turnOverDTO: {
          canUIEdit:
            turnOverDetails?.canUIEdit ?? turnOverDTO?.canUIEdit ?? true,
          financialYearRange: turnOverDetails
            ? turnOverDetails?.financialYearRange
            : null ?? turnOverDTO
            ? turnOverDTO?.financialYearRange
            : null,
          recentFinYear: turnOverDetails
            ? turnOverDetails?.recentFinYear
            : null ?? turnOverDTO
            ? turnOverDTO?.recentFinYear
            : null,
          turnOverSlab: turnOverDetails
            ? turnOverDetails?.turnOverSlab
            : null ?? turnOverDTO
            ? turnOverDTO?.turnOverSlab
            : null,
          turnOverSlabFromUI: finalSubmitData?.businessTurnOver
            ? finalSubmitData?.businessTurnOver
            : null ?? null,
        },
        decryptionDetails: {
          ...leadDataTmp?.dsaCaseAdditionalFileds?.decryptionDetails,
          gstin: finalSubmitData?.gstin ?? formData?.gstin,
        },
      },
    };
    return data;
  };

  return (
    <>
      <div
        className="indiaMart"
        style={{
          position:
            selectedStageName === "bankStatement" ||
            selectedStageName === "applicationDetails" ||
            showList
              ? "unset"
              : "relative",
          height: selectedStageName === "bankStatement" ? "inherit" : "auto",
        }}
      >
        {loader && (
          <div className="loader-view">
            <Spin
              tip="We are processing your request, please wait"
              size="large"
            ></Spin>
          </div>
        )}
        <div
          className="view-logo"
          style={{
            height:
              !stageNameForNoLogo.includes(selectedStageName) &&
              !["user_task_thank_you_page_bil"].includes(taskDefinitionKey)
                ? "auto"
                : "30px",
          }}
        >
          {!stageNameForNoLogo.includes(selectedStageName) &&
            !["user_task_thank_you_page_bil"].includes(taskDefinitionKey) && (
              <img src={imgLogo_IM_blue} alt="" />
            )}
        </div>
        <div
          className="view-width-indiamart"
          style={{
            minHeight:
              ["offerMultilender", "bankStatement"].includes(
                selectedStageName
              ) && isMobile
                ? "calc(100% - 200px)"
                : "calc(100% - 100px)",
          }}
        >
          {renderView()}
        </div>
        <div className="view-width-indiamart view-footer-indiamart">
          <div className="support">
            <img src={iconEmail} />
            <span style={{ marginLeft: "10px" }}>support@oneinfinity.in</span>
          </div>
          <div className="powered-by-web">
            <span>Powered by </span>
            <div>
              <img src={imgLogo} height={40} />
            </div>
          </div>
          <div className="tc-pp">
            <div>
              <Button
                type="text"
                onClick={() => getAppContent("TERMS_AND_CONDITIONS")}
              >
                Terms & Conditions
              </Button>
              <Button
                type="text"
                onClick={() => getAppContent("PRIVACY_POLICY")}
              >
                Privacy Policy
              </Button>
            </div>
            <span className="desc">* Note: For eligible borrowers</span>
          </div>
          <div className="powered-by">
            <span>Powered by </span>
            <div>
              <img src={imgLogo} height={45} />
            </div>
          </div>
        </div>
      </div>
      <Modal
        className={""}
        title="Error Messages"
        centered
        width={600}
        onCancel={() => setIsOpenErrorModel(false)}
        footer={[
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              key="submit"
              type="primary"
              // loading={loading}
              onClick={() => {
                // setAppContentType("")
                // setAppContent("")
                setIsOpenErrorModel(false);
              }}
              style={{ paddingInline: "30px" }}
            >
              OK
            </Button>
          </div>,
        ]}
        open={isOpenErrorModel}
      >
        <div className="errorMsg"> {isErrorDisplayFirstName} </div>
        <div className="errorMsg"> {isErrorDisplayMobile} </div>
        <div className="errorMsg"> {isErrorDisplayEmail} </div>
        <div className="errorMsg"> {isErrorDisplayPan} </div>
        <div className="errorMsg"> {isErrorDisplay} </div>
      </Modal>
    </>
  );
};

export default IndiaMart;
