import React from "react";
import eligibility from "../../assets/eligibility.svg";
import "./BuyLeads.scss";

interface IBuyLeadSuccess {
  isMobile: any;
}

const BuyLeadSuccess: React.FunctionComponent<IBuyLeadSuccess> = (
  props: IBuyLeadSuccess
) => {
  const { isMobile } = props;

  return (
    <div className="buy-lead-offer">
      <div className="logo">
        <img
          src={eligibility}
          width={isMobile ? 280 : ""}
          height={isMobile ? 280 : ""}
        />
      </div>
      <div className="content">
        <label className="thenkyou">Thank You!</label>
        <p className="message">
          Your request for purchase of leads will be processed shortly.
        </p>
        {/* <Button className="btn-complete-now">Close</Button> */}
      </div>
    </div>
  );
};

export default BuyLeadSuccess;
