import { Form, Input } from "antd";
import { Rule } from "antd/es/form";
import iconVerified from "../../assets/right-border.svg";
import iconInfo from "../../assets/info.svg";
import "./FormInput.scss";

interface IFormInput {
  name: string;
  type?: string;
  label?: string;
  inputMode?: "url" | "email" | "text" | "search" | "none" | "tel" | "numeric" | "decimal";
  tooltip?: string;
  placeholder?: string;
  prefix?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isVerified?: boolean;
  isUpperCase?: boolean;
  className?: string
  showVerification?: boolean;
  rules?: Rule[] | undefined;
  defaultValue?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
}

const FormInput = (props: IFormInput) => {
  const {
    name,
    type,
    inputMode,
    label,
    tooltip,
    isRequired,
    prefix,
    showVerification,
    isVerified,
    isDisabled,
    isUpperCase,
    placeholder,
    rules,
    defaultValue,
    className,
    onChange,
    onBlur,
  } = props;

  const toInputUppercase = (e: any) => {
    if (isUpperCase) {
      e.target.value = ("" + e.target.value).toUpperCase();
    }
  };

  return (
    <>
      <Form.Item
        name={name}
        label={label}
        className="indiaMartOtpField"
        // tooltip={tooltip}
        tooltip={!tooltip ? "" : { title: tooltip, icon: <img src={iconInfo} height={20} width={20} /> }}
        rules={[
          {
            required: isRequired,
            message: `Please Enter your ${label ?? placeholder}`,
          },
          ...(rules ?? []),
        ]}
      >
        {type == "password" ? (
          <Input.Password
            size="large"
            placeholder={placeholder}
            onInput={toInputUppercase}
            inputMode={inputMode ?? "text"}
            disabled={isDisabled}
            prefix={prefix}
            className={className}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
          />
        ) : (
          <Input
            size="large"
            type={type ?? "text"}
            placeholder={placeholder}
            onInput={toInputUppercase}
            inputMode={inputMode ?? "text"}
            disabled={isDisabled}
            prefix={prefix}
            className={className}
            defaultValue={defaultValue}
            onChange={onChange}
            onBlur={onBlur}
          />
        )}
      </Form.Item>
      {showVerification &&
        (isVerified ? (
          <div className="label-verified">
            <img src={iconVerified} />
            <label>Verified</label>
          </div>
        ) : (
          <label className="label-verficiation-pending">
            Verification Pending
          </label>
        ))}
    </>
  );
};

export default FormInput;
