import iconUpload from "../../assets/upload.svg";
import iconEye from "../../assets/eye.svg";
import iconDelete from "../../assets/delete.svg";
import iconFile from "../../assets/file.svg";
import iconDownload from "../../assets/download.svg";
import iconAddFile from "../../assets/add-file.svg";
import iconEdit from "../../assets/edit.svg";
import iconFileWhite from "../../assets/document-white.svg";
import iconDownloadWhite from "../../assets/download-white.svg";
import iconEditWhite from "../../assets/edit-white.svg";
import iconAddFileWhite from "../../assets/add-document-white.svg"

interface IFormIcons {
  type: "upload" | "file" | "delete" | "view" | "download" | "addFile" | "edit" | "fileWhite" | "downloadWhite" | "editWhite" | "addFileWhite";
}

const FormIcons = (props: IFormIcons) => {
  const { type } = props;

  const icons: any = {
    upload: iconUpload,
    file: iconFile,
    delete: iconDelete,
    view: iconEye,
    download: iconDownload,
    addFile: iconAddFile,
    edit: iconEdit,
    fileWhite:iconFileWhite,
    downloadWhite:iconDownloadWhite,
    editWhite:iconEditWhite,
    addFileWhite:iconAddFileWhite,
  };

  return <img src={icons[`${type}`]} width="20px" height="20px" alt="" />;
};

export default FormIcons;
