import React, { useCallback, useState, useEffect } from "react";
import {
  Button,
  Modal,
  notification,
  Typography,
  Form as ReactForm,
  Checkbox,
} from "antd";
import {
  NUMBER_REGEX,
  PHONE_NUMBER_REGEX,
  STRING_ONLY,
} from "../../constants/AppConstants";
import FormCheckbox from "../../components/FormCheckbox/FormCheckbox";
import FormInput from "../../components/FormInput/FormInput";
interface IndiaMartGetOtp {
  isMobile: any;
  formCheckbox: any;
  signUp: any;
  renderCheckBoxDetails: any;
  isGetOtpDisable: any;
  onTcChange: any;
  loadingGetOTP;
  isErrorDisplay: any;
  isErrorDisplayMobile: any;
  isErrorDisplayEmail: any;
  isErrorDisplayPan: any;
  isErrorDisplayFirstName: any;
}

const IndiaMartGetOtp: React.FunctionComponent<IndiaMartGetOtp> = ({
  isMobile,
  formCheckbox,
  signUp,
  onTcChange,
  renderCheckBoxDetails,
  isGetOtpDisable,
  loadingGetOTP,
  isErrorDisplay,
  isErrorDisplayMobile,
  isErrorDisplayEmail,
  isErrorDisplayPan,
  isErrorDisplayFirstName,
}) => {
  return (
    <>
    
    <ReactForm
      layout="vertical"
      autoComplete="off"
      name="leadFormOTP"
      key="leadFormOTP"
      style={{ width: isMobile ? "90%" : "100%" }}
      className="form-persion"
      form={formCheckbox}
      onFinish={signUp}
    >
      <div className="errorMsg">{isErrorDisplayFirstName}</div>
      <div className="errorMsg">{isErrorDisplayMobile}</div>
      <div className="errorMsg">{isErrorDisplayEmail}</div>
      <div className="errorMsg">{isErrorDisplayPan}</div>
      <div className="errorMsg">{isErrorDisplay}</div>
      <FormCheckbox 
        name={"serviceProvider"}
        isRequired
        msgRequired="Terms and Conditions & Privacy policy"
        onChange={onTcChange}
        textElement={renderCheckBoxDetails()}
      />
      {!isGetOtpDisable && (
        <Button
          type="primary"
          className="otp-btn"
          disabled={isGetOtpDisable}
          htmlType="submit"
          loading={loadingGetOTP}
          // onClick={signUp}
        >
          Get OTP
        </Button>
      )}
    </ReactForm>
    </>
  );
};

export default IndiaMartGetOtp;
