import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

export const loanTypeList = [
  { key: "HOME_LOAN", label: "Home Loan", code: "hl" },
  { key: "BUSINESS_INSTALLMENT_LOAN", label: "Business Installment Loan", code: "bil" },
  { key: "EDUCATION_LOAN", label: "Education Loan", code: "el" },
  { key: "BUY_NOW_PAY_LATER", label: "BNPL Product", code: "bnpl" },
  { key: "PERSONAL_LOAN_ONLY", label: "Personal Loan", code: "pl" },
  { key: "BUY_LEADS", label: "Buy Lead", code: "buyl" },
  { key: "HEALTH_INSURANCE", label: "Health Insurance", code: "hi" },
  { key: "FOUR_WHEELER_INSURANCE", label: "Four Wheeler Insurance", code: "fwi" },
  { key: "TWO_WHEELER_INSURANCE", label: "Two Wheeler Insurance", code: "twi" },
];

interface IStart {}

const Start: React.FunctionComponent<IStart> = (props: IStart) => {
  let history = useHistory();

  const actionButon = (item: any) => {
    return (
      <Button
        id={item?.key}
        type="primary"
        htmlType="submit"
        size="large"
        className="btn-next"
        style={{ paddingInline: "100px" }}
        onClick={() => {
          history.push(`/${item?.code}`);
          window.location.reload();
        }}
      >
        {(["hl", "pl", "bil", "el"].includes(item?.code)
          ? "Apply for "
          : "") + item?.label}
      </Button>
    );
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          display: "grid",
          alignContent: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        {loanTypeList?.map((item: any) => {
          return actionButon(item);
        })}
      </div>
    </>
  );
};

export default Start;
