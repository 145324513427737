import React, { useCallback, useState, useEffect } from "react";
import { Form as FormIo } from "react-formio";
import debounce from "lodash/debounce";
import formJson from "../low-code/user_task_borrower_and_business_details.json";

interface IIndiaMartFormio {
  formData: any;
  setFinalSubmitData: any;
  taskDefinitionKey: any;
  setFormData: any,
  finalJSONSchema: any;
  finalFormData: any;
  leadDetail: any;
  getOtpStatus: any;
  isMobileNoEditable: any;
  setIsMobileNoEditable: any;
  finalSubmitData: any;
  checkFieldsValues: any;
}
const IndiaMartFormIo: React.FunctionComponent<IIndiaMartFormio> = ({
  formData,
  setFinalSubmitData,
  taskDefinitionKey,
  setFormData,
  finalJSONSchema,
  finalFormData,
  leadDetail,
  getOtpStatus,
  isMobileNoEditable,
  finalSubmitData,
  setIsMobileNoEditable,
  checkFieldsValues,
}) => {
  useEffect(() => {
    if (taskDefinitionKey === "user_task_borrower_and_business_details") {
       setBusinessAndpersonalDetails();
     }
   }, [finalFormData, finalJSONSchema, leadDetail]);
   const ApplicationTypeList = ["SOLE_PROPRIETOR", "SELF_EMPLOYED"];
   
   const checkMobileNo = useCallback(() => {
    return leadDetail?.mobileNumberEditable ? 'true' : '';
   }, [leadDetail, isMobileNoEditable]);

   const setBusinessAndpersonalDetails = () => {
    let contactDetails = leadDetail?.contactDetails;
    let applicationSubType =
    leadDetail?.applicationSubTypeKey ?? leadDetail?.applicationSubType;
    let address = leadDetail?.address ?? finalFormData?.address;

    const pan =
      (contactDetails?.pan?.length ?? 0) === 0 ||
      contactDetails?.pan === "PENDING"
        ? ""
        : contactDetails.pan;

    const tempFormData = {
      applicationSubType: applicationSubType ?? "",
      firstName: contactDetails?.firstName ?? "",
      mobileNumber: contactDetails?.mobileNumber ?? '',
      emailId: contactDetails?.emailId ?? finalSubmitData?.emailId ?? "",
      pinCode: leadDetail?.officePincode ? Number(leadDetail?.officePincode) : finalSubmitData?.pinCode ? finalSubmitData?.pinCode : null,
      pan: pan ?? "",
      panHidden: finalSubmitData?.pan ?? "",
      dateOfBirth1: ApplicationTypeList.includes(applicationSubType) ? '' : contactDetails?.dateOfBirth,
      dateOfBirth: ApplicationTypeList.includes(applicationSubType) ? finalSubmitData?.dateOfBirth ? finalSubmitData?.dateOfBirth : '' : contactDetails?.dateOfBirth,
      entityOrEmployerName: leadDetail?.entityOrEmployerName ?? "",
      businessProfile: leadDetail?.businessProfile ?? "",
      businessVintage: leadDetail?.businessVintage ?? "",
      gender: contactDetails?.gender ?? 'M',
      gstin: leadDetail?.dsaCaseAdditionalFileds ? leadDetail?.dsaCaseAdditionalFileds?.decryptionDetails?.gstin : leadDetail?.additionalFileds?.decryptionDetails?.gstin ?? "",
      loanAmount: leadDetail?.caseDetails?.loanDetails?.loanAmount == 0 ? null : leadDetail?.caseDetails?.loanDetails?.loanAmount,
      mobileNumberDisable: checkMobileNo(),
      businessTurnOverHide: leadDetail?.dsaCaseAdditionalFileds ? leadDetail?.dsaCaseAdditionalFileds?.turnOverDTO?.canUIEdit || !leadDetail?.dsaCaseAdditionalFileds?.turnOverDTO ? '' : 'true' : leadDetail?.additionalFileds?.turnOverDTO?.canUIEdit || !leadDetail?.additionalFileds?.turnOverDTO  ? '' : 'true',
      businessTurnOver: leadDetail?.dsaCaseAdditionalFileds ? leadDetail?.dsaCaseAdditionalFileds?.turnOverDTO?.turnOverSlabFromUI : leadDetail?.additionalFileds?.turnOverDTO?.turnOverSlabFromUI ?? ''
    }
    setFormData(tempFormData);
  }
    const handleOnChange = (submission: any) => {
      checkFieldsValues();
        if ((submission?.changed?.component?.key ?? "")) {
          setFormData({ ...formData, ...submission.data, });
        }
        if ((submission?.changed?.component?.key ?? "") === "loanAmount") {
          const updateFormData = {
            firstName: formData?.firstName ?? undefined,
            mobileNumber: formData?.mobileNumber ?? undefined,
            emailId: formData?.emailId ?? submission?.data?.emailId ?? '',
            pinCode: formData?.pinCode,
            panHidden: submission.data?.pan ?? undefined,
            pan: submission.data?.pan ?? undefined,
            gender: formData?.gender ?? undefined,
            dateOfBirth1: submission.data?.dateOfBirth,
            dateOfBirth: submission.data?.dateOfBirth,
            entityOrEmployerName: formData?.entityOrEmployerName ?? undefined,
            businessProfile: formData?.businessProfile ?? undefined,
            businessVintage: submission.data?.businessVintage ?? undefined,
            loanAmount: submission.data?.loanAmount <= 0 ? null : submission.data?.loanAmount,
            city: submission.data.city ?? undefined,
            gstin: formData?.gstin ?? undefined,
            businessTurnOver: submission.data?.businessTurnOver ?? undefined,
            applicationSubType: submission.data.applicationSubType ?? undefined,
            mobileNumberDisable: checkMobileNo()
          }
          setFormData({...updateFormData, ...submission.data});
        }
        if ((submission?.changed?.component?.key ?? "") === "businessVintage") {
          const updateFormData = {
            firstName: formData?.firstName ?? undefined,
            mobileNumber: formData?.mobileNumber ?? undefined,
            emailId: formData?.emailId ?? submission?.data?.emailId ?? '',
            pinCode: formData?.pinCode,
            gender: submission.data?.gender ?? undefined,
            panHidden: submission.data?.pan ?? undefined,
            pan: submission.data?.pan ?? undefined,
            dateOfBirth1: submission.data?.dateOfBirth ?? submission.data?.dobHidden,
            dateOfBirth: submission.data?.dateOfBirth ?? submission.data?.dobHidden,
            entityOrEmployerName: formData?.entityOrEmployerName ?? undefined,
            businessProfile: formData?.businessProfile ?? undefined,
            businessVintage: submission.data?.businessVintage ?? undefined,
            loanAmount: submission.data?.loanAmount <= 0 ? null : submission.data?.loanAmount,
            city: submission.data.city ?? undefined,
            gstin: formData?.gstin ?? undefined,
            businessTurnOver: submission.data?.businessTurnOver ?? undefined,
            applicationSubType: submission.data.applicationSubType ?? undefined,
            mobileNumberDisable: checkMobileNo()
          }
          setFormData({...updateFormData, ...submission.data});
        }
        setFinalSubmitData(submission.data);
        if ((submission?.changed?.component?.key ?? "") === "emailId") {
          let value = "" as string;
          value = submission?.changed?.value ?? "";
          localStorage.setItem("emailLowCode", value);
        } else if ((submission?.changed?.component?.key ?? "") === "gender") {
          let value = "" as string;
          value = submission?.changed?.value ?? "";
          localStorage.setItem("genderLowCode", value);
        } else if (
          (submission?.changed?.component?.key ?? "") === "applicationSubType" &&
          !["user_task_employer_details_bnpl", "user_task_borrower_and_loan_details_el"].includes(taskDefinitionKey)
        ) {
          const updateFormData = {
            firstName: formData?.firstName ?? undefined,
            mobileNumber: formData?.mobileNumber ?? undefined,
            emailId: formData?.emailId ?? submission?.data?.emailId ?? '',
            pinCode: formData?.pinCode,
            gender: submission.data?.gender ?? undefined,
            pan: formData?.pan ?? undefined,
            panHidden: submission.data?.pan ?? undefined,
            dateOfBirth1: submission.data?.dateOfBirth ?? submission.data?.dobHidden,
            dateOfBirth: submission.data?.dateOfBirth ?? submission.data?.dobHidden,
            entityOrEmployerName: formData?.entityOrEmployerName ?? undefined,
            businessProfile: formData?.businessProfile ?? undefined,
            businessVintage: submission.data?.businessVintage ?? undefined,
            loanAmount: formData?.loanAmount == 0 ? null : formData?.loanAmount,
            city: submission.data.city ?? undefined,
            gstin: formData?.gstin ?? undefined,
            businessTurnOver: submission.data?.businessTurnOver ?? undefined,
            applicationSubType: submission.data.applicationSubType ?? undefined,
            mobileNumberDisable: checkMobileNo()
          }
          setFormData({...updateFormData, ...submission.data});
        } else if (
          (submission?.changed?.component?.key ?? "") === "pan" &&
          !["user_task_borrower_details_bnpl"].includes(taskDefinitionKey)
        ) {
          const updateFormData = {
            firstName: formData?.firstName ?? undefined,
            mobileNumber: formData?.mobileNumber ?? undefined,
            emailId: formData?.emailId ?? submission?.data?.emailId ?? '',
            pinCode: formData?.pinCode,
            gender: submission.data?.gender ?? undefined,
            panHidden: submission.data?.pan ?? undefined,
            pan: submission.data?.pan ?? undefined,
            dateOfBirth1: submission.data?.dateOfBirth ?? submission.data?.dobHidden,
            dateOfBirth: submission.data?.dateOfBirth ?? submission.data?.dobHidden,
            entityOrEmployerName: formData?.entityOrEmployerName ?? undefined,
            businessProfile: formData?.businessProfile ?? undefined,
            businessVintage: submission.data?.businessVintage ?? undefined,
            loanAmount: formData?.loanAmount == 0 ? null : formData?.loanAmount,
            city: submission.data.city ?? undefined,
            gstin: formData?.gstin ?? undefined,
            businessTurnOver: submission.data?.businessTurnOver ?? undefined,
            applicationSubType: submission.data.applicationSubType ?? undefined,
            mobileNumberDisable: checkMobileNo()
          }
          setFormData({...updateFormData, ...submission.data});
          if (submission.data.pan?.length === 10) {
            getOtpStatus(submission.data.mobileNumber, submission.data.pan);
          }
        } else if (
          (submission?.changed?.component?.key ?? "") === "mobileNumber" &&
          !["user_task_borrower_details_bnpl"].includes(taskDefinitionKey)
        ) {
          const updateFormData = {
            firstName: formData?.firstName ?? undefined,
            mobileNumber: formData?.mobileNumber ?? undefined,
            emailId: formData?.emailId ?? submission?.data?.emailId ?? '',
            pinCode: formData?.pinCode,
            panHidden: submission.data?.pan ?? undefined,
            pan: submission.data?.pan ?? undefined,
            gender: submission.data?.gender ?? undefined,
            dateOfBirth1: submission.data?.dateOfBirth ?? submission.data?.dobHidden,
            dateOfBirth: submission.data?.dateOfBirth ?? submission.data?.dobHidden,
            entityOrEmployerName: formData?.entityOrEmployerName ?? undefined,
            businessProfile: formData?.businessProfile ?? undefined,
            businessVintage: submission.data?.businessVintage ?? undefined,
            loanAmount: formData?.loanAmount == 0 ? null : formData?.loanAmount,
            city: submission.data.city ?? undefined,
            gstin: formData?.gstin ?? undefined,
            businessTurnOver: submission.data?.businessTurnOver ?? undefined,
            applicationSubType: submission.data.applicationSubType ?? undefined,
            mobileNumberDisable: checkMobileNo()
          }
          setFormData({...updateFormData, ...submission.data});
          if (submission.data.mobileNumber?.length === 10) {
            getOtpStatus(submission.data.mobileNumber, submission.data.pan);
          }
        }
      };
      const handleFormSubmit =() => {

      }
      const handleCustomEvent = () => {

      }
  return (
    <div>
      <FormIo
        submission={{ data: formData }}
        key="jsonForm"
        onSubmit={handleFormSubmit}
        onCustomEvent={handleCustomEvent}
        form={finalJSONSchema}
        jsonSchema={finalJSONSchema}
        // form={formJson?.JSONSchema}
        // jsonSchema={formJson?.JSONSchema}
        onChange={debounce(handleOnChange, 1000)}
      />
    </div>
  );
}

export default IndiaMartFormIo;
