import { Button, Col, Collapse, Popconfirm, Row, Tooltip, notification } from "antd";
import React, { useEffect, useState } from "react";
import iconUpload from "../../assets/upload.svg";
import iconDelete from "../../assets/delete.svg";
import iconDeleteDisable from "../../assets/delete_disable.svg";
import iconDownload from "../../assets/download-green.svg";
import iconPlus from "../../assets/add_round.svg";
import { downloadDocument, isRazorPay } from "../../services/Utility";

const { Panel } = Collapse;

interface IDocCategoryV2 {
  documentCategoryList: any;
  actionOnDelete: any;
  actionOnUpload: any;
  actionAdd: any;
  complementorUserId?:any;
}

const DocCategoryV2: React.FunctionComponent<IDocCategoryV2> = (
  props: IDocCategoryV2
) => {
  const { documentCategoryList, actionOnDelete, actionOnUpload, actionAdd,complementorUserId } =
    props;

  const renderButton = (icon, onClick, tooltip, disabled) => {
    return (
      <Tooltip title={tooltip}>
        <Button
          type="text"
          onClick={onClick}
          disabled={disabled}
          icon={<img src={icon} width="20px" height="20px" alt="" />}
        />
      </Tooltip>
    );
  };

  const renderActions = (doc: any, index: any) => {
    const isDocUploaded = doc?.lenderDocumentStatus == "UPLOADED";
    return (
      <div
        className="action-btns"
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "transparent",
        }}
      >
        {!doc?.documentDetails?.documentName &&
          renderButton(
            iconUpload,
            () => actionOnUpload(doc),
            "Upload document",
            false
          )}

        {doc?.documentDetails &&
          renderButton(
            iconDownload,
            () => downloadDocument( doc?.documentDetails?.businessId,
              doc?.documentDetails?.businessDocumentId),
            "Download the document",
            false
          )}

        {doc?.documentDetails != null && (
          <Popconfirm
            placement="topRight"
            title={"Sure to delete document?"}
            onConfirm={(event) => actionOnDelete(doc)}
            disabled={isDocUploaded}
          >
            {renderButton(
              isDocUploaded ? iconDeleteDisable : iconDelete,
              () => {},
              "Delete the document",
              isDocUploaded
            )}
          </Popconfirm>
        )}

        <Tooltip title={"Add another file for the same document"}>
          <Button
            type="text"
            onClick={() => {
              if (doc?.documentDetails) {
                actionAdd(doc, index);
              } else {
                notification.error({ message: "Please Upload Document" });
              }
            }}
            icon={<img src={iconPlus} width="20px" height="20px" alt="" />}
          />
        </Tooltip>
      </div>
    );
  };

  const renderItem = (doc: any, index: any) => {
    const docType = doc?.documentOrgType ?? doc?.documentType;
    const docName = doc?.documentTypeName ?? docType?.split("~").at(-1);
    const fileName = doc?.documentDetails?.documentName;

    return (
      <>
        <Row
          key={`${doc?.documentType}_${index}`}
          className={
            doc?.documentDetails &&
            doc?.maximumSizeInMB <= doc?.documentDetails?.fileSize
              ? "error"
              : ""
          }
        >
          <Col
            span={7}
            style={{
              textOverflow: "ellipsis",
              flexWrap: "nowrap",
              overflow: "hidden",
              paddingLeft: "15px",
            }}
          >
            <Tooltip title={docName}>
              <span style={{ color: "red" }}>
                {doc?.mandatory ? "* " : <>&nbsp;&nbsp;&nbsp;</>}
              </span>
              <span>{docName}</span>
            </Tooltip>
          </Col>
          <Col
            span={7}
            style={{
              textOverflow: "ellipsis",
              flexWrap: "nowrap",
              overflow: "hidden",
            }}
          >
            <div className="action-btns">
              <span style={{ lineBreak: "anywhere" }}>{fileName}</span>
            </div>
          </Col>
          <Col span={4} className="h-center">
            <Tooltip
              title={
                doc?.appliedFileTypes ? doc?.appliedFileTypes?.join("/") : "-"
              }
            >
              <p
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  marginBottom: "0px",
                  paddingInline: "10px",
                }}
              >
                {doc?.appliedFileTypes
                  ? doc?.appliedFileTypes?.join(", ")
                  : "-"}
              </p>
            </Tooltip>
          </Col>
          <Col span={3} className="h-center">
            {doc?.maximumSizeInMB ? doc?.maximumSizeInMB + "MB" : "-"}
          </Col>
          <Col span={3}>{renderActions(doc, index)}</Col>
        </Row>
      </>
    );
  };

  const getHeader = () => {
    let isRequired = false;
    let mandatoryCount = 0;
    documentCategoryList?.documentTypeWithUploadedDetails?.map((item) => {
      if (!isRequired && item?.mandatory) {
        isRequired = true;
      }
      if (item?.mandatory && item.documentDetails !== null) {
        mandatoryCount = mandatoryCount + 1;
      }
    });

    const reqSign = (
      <span style={{ color: "red" }}>{isRequired ? "* " : "  "}</span>
    );

    return (
      <>
        {reqSign}
        <span className={isRazorPay(complementorUserId) ? 'razorPay-upload-docList' :''}>{`${documentCategoryList?.documentCategoryName} `}</span>
        {/* {
          <span className="doc-category-tags">
            Mandatory: {mandatoryCount ?? 0}/
            {documentCategoryList?.totalMandatory}
          </span>
        } */}
        {
          <span className="doc-category-tags">
            Uploaded: {documentCategoryList?.totalUploaded}
          </span>
        }
      </>
    );
  };

  return (
    <Collapse
      className="doc-collapse"
      expandIconPosition="end"
      key={`${documentCategoryList?.documentCategoryId}`}
    >
      <Panel header={getHeader()} key={"1"}>
        {documentCategoryList?.documentTypeWithUploadedDetails?.map(
          (item, index) => {
            return renderItem(item, index);
          }
        )}
      </Panel>
    </Collapse>
  );
};

export default DocCategoryV2;
