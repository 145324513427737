import { Form, Input } from "antd";
import { Rule } from "antd/es/form";
import InputNumber from "rc-input-number";
import iconVerified from "../../assets/right-border.svg";
import "./FormInputNumber.scss";

interface IFormInput {
  name: string;
  type?: string;
  label?: string;
  tooltip?: string;
  placeholder?: string;
  prefix?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  isVerified?: boolean;
  isUpperCase?: boolean;
  showVerification?: boolean;
  rules?: Rule[] | undefined;
  defaultValue?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined | any;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  formatter: number | any;
  inputMode?: "url" | "email" | "text" | "search" | "none" | "tel" | "numeric" | "decimal";
}

const FormInputNumber = (props: IFormInput) => {
  const {
    name,
    type,
    label,
    tooltip,
    isRequired,
    inputMode,
    prefix,
    showVerification,
    isVerified,
    isDisabled,
    isUpperCase,
    placeholder,
    rules,
    defaultValue,
    onChange,
    onBlur,
    formatter,
  } = props;

  const toInputUppercase = (e: any) => {
    if (isUpperCase) {
      e.target.value = ("" + e.target.value).toUpperCase();
    }
  };

  return (
    <>
      <Form.Item
        name={name}
        label={label}
        tooltip={tooltip}
        rules={[
          {
            required: isRequired,
            message: `Please Enter your ${label ?? placeholder}`,
          },
          ...(rules ?? []),
        ]}
      >
        <InputNumber
        //   size="large"
          type={type ?? "text"}
          placeholder={placeholder}
          onInput={toInputUppercase}
          disabled={isDisabled}
          prefix={prefix}
          inputMode={inputMode ?? "text"}
        //   className="form-input"
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          formatter={formatter}
        />
      </Form.Item>
      {showVerification &&
        (isVerified ? (
          <div className="label-verified">
            <img src={iconVerified} />
            <label>Verified</label> 
          </div>
        ) : (
          <label className="label-verficiation-pending">
            Verification Pending
          </label>
        ))}
    </>
  );
};

export default FormInputNumber;
