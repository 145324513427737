import { Button, Col, Form, Modal, Row } from "antd";
import FormInputNumber from "../../components/FormInputNumber/FormInputNumber";
import { NUMBER_REGEX } from "../../constants/AppConstants";
import { isRazorPay, numberWithCommas } from "../../services/Utility";
import { useEffect } from "react";

const EdotLoanAmount = (props: any) => {
  const {
    editLoanAmountModal,
    setEditLoanAmountModal,
    loanAmount,
    actionCalculate,
    maxAllowedLoanAmount,
    complementorUserId,
  } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("loanAmount", loanAmount);
  }, []);

  const onFinishForm = (value: any) => {
    console.log("Form values", value);
    actionCalculate(value);
  };

  const checkLoanAmount = (_: any, value: any) => {
    if (value == "") {
      return Promise.resolve();
    }
    if (value <= maxAllowedLoanAmount) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(`Enter loan amount less then eligible Loan`));
    }
  };

  return (
    <>
      <Modal
        className={isRazorPay(complementorUserId) ? 'razor_pay_modal' : ''}
        centered
        title={"Edit Loan Amount"}
        open={editLoanAmountModal}
        onCancel={() => setEditLoanAmountModal(false)}
        footer={null}
      >
        <Form onFinish={onFinishForm} form={form}>
          <FormInputNumber
            name="loanAmount"
            label="Loan Amount (INR)"
            formatter={(value: any) => numberWithCommas(value)}
            inputMode="numeric"
            isRequired
            rules={[
              {
                pattern: NUMBER_REGEX,
                message: "Please enter only number",
              },
              { validator: checkLoanAmount }
            ]}
          />
          <Row style={{ display: "flex", justifyContent: "end" }}>
            <Col>
              <Button type="primary" htmlType="submit" className={isRazorPay(complementorUserId) ? 'razorPay_btn' : ''}>
                Calculate
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default EdotLoanAmount;
