import { Steps, Typography } from "antd";
import React, { useState } from "react";
import iconLock from "../../assets/lock.svg";
import imgRightWhite from "../../assets/right-white.svg";
import imgLogo from "../../assets/logo-white.svg";
import imgCompleteVisibility from "../../assets/feature-completeVisibility.svg";
import imgDigitisedProcess from "../../assets/feature-digitisedProcess.svg";
import imgMinimumDocumentation from "../../assets/feature-minimumDocumentation.svg";
import imgQuickestDisbursement from "../../assets/feature-quickestDisbursement.svg";
import imgSimpleProcess from "../../assets/feature-simpleProcess.svg";
import imgTrustedNetwork from "../../assets/feature-trustedNetwork.svg";
import imgWhoCanApply from "../../assets/feature-whoCanApply.svg";
import imgSuperQuickHealthPolicies from "../../assets/feature-superQuickHealthPolicies.svg";
import imgSuperQuickCarInsurance from "../../assets/feature-superQuickCarInsurance.svg";
import imgSuperQuickVehicleInsurance from "../../assets/feature-superQuickVehicleInsurance.svg";
import "./ProductFeatures.scss";
import { isRazorPay } from "../../services/Utility";
import { stages } from "../complementor-widget/ComplementorWidget";

const { Title } = Typography;

export type ProductFeaturesMsg =
  | "whoCanApply"
  | "simpleProcess"
  | "trustedNetwork"
  | "minimumDocumentation"
  | "digitisedProcess"
  | "quickestDisbursement"
  | "completeVisibility"
  | "superQuickHealthPolicies"
  | "superQuickCarInsurance"
  | "superQuickVehicleInsurance";

interface IProductFeatures {
  selectedStageName: stages;
  productFeaturesMsg: ProductFeaturesMsg;
  loanType: String;
  connectorLogo: string | undefined;
  complementorUserId: string | undefined;
  isV2: boolean;
  extraData: any;
  productFormMetadata: any;
}

const ProductFeatures: React.FunctionComponent<IProductFeatures> = (
  props: IProductFeatures
) => {
  const {
    selectedStageName,
    productFeaturesMsg,
    loanType,
    connectorLogo,
    complementorUserId,
    isV2,
    extraData,
    productFormMetadata,
  } = props;

  const renderStpes = () => {
    const currentStep = extraData?.stepStatus?.findIndex((step: any) => {
      return (
        step?.key == productFormMetadata?.stageKey ||
        step?.key == productFormMetadata?.stepKey
      );
    });

    return (
      <>
        <Title className="title-product-features" level={3}>
          {"Application Process"}
        </Title>
        <div className="footer-border h-1" />

        <Steps
          className="custome-step"
          direction="vertical"
          size="small"
          current={currentStep}
          items={extraData?.stepStatus?.map((step: any, index: number) => {
            return {
              title: step?.name,
              icon:
                currentStep > index ? null : (
                  <div>
                    <img src={iconLock} />
                  </div>
                ),
            };
          })}
        />
      </>
    );
  };

  const renderStageV2 = () => {
    let title = "Business Loan";
    let desc = [
      "No collateral",
      "Disbursed under 48 hours",
      "Partner network of over 70 lenders",
    ];

    return (
      <>
        {["register", "otp"].includes(selectedStageName) ? (
          <>
            <Title className="title-product-features" level={3}>
              {title}
            </Title>
            <div className={"footer-border v2"} />
            <ul className="list">
              {desc?.map((item: string, index: number) => {
                return (
                  <li key={index} className={"features-desc"}>
                    <img src={imgRightWhite} /> <span>{item}</span>
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          renderStpes()
        )}
      </>
    );
  };

  const renderStage = () => {
    let title = "Who Can Apply";
    let desc = [""];

    if (productFeaturesMsg == "whoCanApply") {
      title = "Who Can Apply";
      if (complementorUserId == "CONN4128778344") {
        desc = [
          "Access to 100+ banks & NBFCs",
          "Quick sanctions and disbursals",
          "Low interest rates and higher limits",
        ];
      } else {
        desc = [
          loanType === "PERSONAL_LOAN_ONLY"
            ? "All salaried individuals now have access to personal loans in 10 mins*"
            : "All types of businesses now have access to unsecured loans in 48 hours!",
          "Best offers",
          "Transparent policies",
          "*T & C Apply",
        ];
      }
    } else if (productFeaturesMsg == "simpleProcess") {
      title = "Simple Process";
      desc = ["Three steps to offers from Banks & NBFCs"];
    } else if (productFeaturesMsg == "trustedNetwork") {
      title = "Trusted Network";
      desc = [
        "Receive offers from over 70 Lenders",
        "Total disbursement, more than 10,000 cr till date",
      ];
    } else if (productFeaturesMsg == "minimumDocumentation") {
      title = "Minimum Documentation";
      desc = ["Receive an offer with just one document"];
    } else if (productFeaturesMsg == "digitisedProcess") {
      title = "Digitised Process";
      desc = ["Proceed to complete journey digitally"];
    } else if (productFeaturesMsg == "quickestDisbursement") {
      title = "Quickest Disbursement";
      desc = [
        "Complete documentation digitally",
        "Get sanction as early 24 hours",
        "Get disbursement as quickly as 48 hours",
      ];
    } else if (productFeaturesMsg == "completeVisibility") {
      title = "Complete Visibility";
      desc = [
        "View & edit details ",
        "View multiple offers",
        "Live case status",
      ];
    } else if (productFeaturesMsg == "superQuickHealthPolicies") {
      title = "Super Quick Health Policies";
      desc = [
        "No medical check-up  ",
        "Instant policy",
        "7500+ healthcare providers",
      ];
    } else if (productFeaturesMsg == "superQuickCarInsurance") {
      title = "Super Quick Car Insurance";
      desc = ["Doorstep cashless repair", "5700+ cashless network garages"];
    } else if (productFeaturesMsg == "superQuickVehicleInsurance") {
      title = "Super Quick Vehicle Insurance";
      desc = ["Doorstep cashless repair", "5700+ cashless network garages"];
    }

    return (
      <>
        <Title className="title-product-features" level={3}>
          {title}
        </Title>
        <div className="footer-border" />

        <ul className="list">
          {desc?.map((item: string, index: number) => {
            return (
              <li key={index} className="features-desc">
                {item}
              </li>
            );
          })}

          {complementorUserId == "CONN4128778344" && (
            <li
              key={"Powered_By"}
              className="features-desc"
              style={{ fontSize: "14px", marginTop: "15px" }}
            >
              Powered By One Infinity
            </li>
          )}
        </ul>
      </>
    );
  };

  const getImage = () => {
    if (productFeaturesMsg == "simpleProcess") {
      return imgSimpleProcess;
    } else if (productFeaturesMsg == "trustedNetwork") {
      return imgTrustedNetwork;
    } else if (productFeaturesMsg == "minimumDocumentation") {
      return imgMinimumDocumentation;
    } else if (productFeaturesMsg == "digitisedProcess") {
      return imgDigitisedProcess;
    } else if (productFeaturesMsg == "quickestDisbursement") {
      return imgQuickestDisbursement;
    } else if (productFeaturesMsg == "completeVisibility") {
      return imgCompleteVisibility;
    } else if (productFeaturesMsg == "superQuickHealthPolicies") {
      return imgSuperQuickHealthPolicies;
    } else if (productFeaturesMsg == "superQuickCarInsurance") {
      return imgSuperQuickCarInsurance;
    } else if (productFeaturesMsg == "superQuickVehicleInsurance") {
      return imgSuperQuickVehicleInsurance;
    }
    return imgWhoCanApply;
  };

  return (
    <>
      <div className="" />
      {/* <div className="bg" /> */}
      <div style={{ backgroundColor: "", height: "100%", width: "100%" }}>
        <img
          src={getImage()}
          alt=""
          style={{ width: "100%", position: "absolute", bottom: "30px" }}
        />
      </div>
      <div
        className={
          "bg" + (isRazorPay(complementorUserId) ? " bg_razorPay" : "")
        }
        style={{ position: "absolute", inset: 0, overflowY: "auto" }}
      >
        {connectorLogo ? (
          <div
            className="logo-view"
            style={{
              justifyContent: isRazorPay(complementorUserId)
                ? "left"
                : "center",
            }}
          >
            <img
              src={connectorLogo}
              alt=""
              className="logo"
              style={{
                width: isRazorPay(complementorUserId) ? "200px" : "130px",
                height: "130px",
                objectFit: "contain",
              }}
            />
          </div>
        ) : (
          <div className="logo-view">
            <img src={imgLogo} alt="" className="logo" />
          </div>
        )}
        {/* <img src={connectorLogo} alt="" className="logo" /> */}

        {loanType !== "BUY_NOW_PAY_LATER" && (
          <div className="product-features">
            {isV2 ? renderStageV2() : renderStage()}
          </div>
        )}
      </div>
    </>
  );
};

export default ProductFeatures;
