import { Checkbox, Form, Input, Space, Typography } from "antd";
import "./FormCheckbox.scss";

interface IFormCheckbox {
  name: string;
  text?: string;
  textElement?: JSX.Element;
  isRequired?: boolean;
  msgRequired?: string;
  tooltip?: string;
  lable?: string;
  className?: string;
  onChange?: any;
  disabled?: boolean;
}

const FormCheckbox = (props: IFormCheckbox) => {
  const {
    name,
    text,
    textElement,
    isRequired,
    msgRequired,
    tooltip,
    lable,
    className,
    onChange,
    disabled,
  } = props;

  return (
    <div className={className}>
      <Form.Item
        name={name}
        label={lable}
        valuePropName="checked"
        tooltip={tooltip}
        rules={[
          {
            validator: (_, value) =>
              value || !isRequired
                ? Promise.resolve()
                : Promise.reject(new Error(`Please accept ${msgRequired}`)),
          },
        ]}
      >
        <Checkbox disabled={disabled} onChange={onChange}>
          <span className="checkbox-text">
            {textElement ?? text}  
          </span>
          {/* {textElement ?? text} */}
        </Checkbox>
      </Form.Item>
    </div>
  );
};

export default FormCheckbox;
