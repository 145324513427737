import AXIOS from "axios";
import { API_CONFIG } from "./config";
import parameters, { BUSINESS_ID } from "./parameters";
import dayjs from "dayjs";

function getPublicInstance(firstServer = true) {
  const apiUrl = firstServer ? parameters.ApiUrlOne : parameters.ApiUrlTwo;
  const headers = {};
  return AXIOS.create({
    // @ts-ignore
    accept: "application/json",
    baseURL: apiUrl + "/",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
  });
}

function getProtectedInstance(firstServer = true) {
  // debugger
  const user = localStorage.getItem("user");
  const headers: any = {};
  const apiUrl = firstServer ? parameters.ApiUrlOne : parameters.ApiUrlTwo;
  if (user) {
    headers["api_key"] = JSON.parse(user as string).apiKey;
    headers["api_id"] = JSON.parse(user as string).apiId;
    // headers['api_key'] = "9857b26f64df7c67e7cb75d6cc857a78";
    // headers['api_id'] = "2feccf832a8bf2f6a23520230b5c2aba";

    // static key and id for upload document

    // headers['api_key'] = "63608e3167cef63aa47709c3235534ab";
    // headers['api_id'] = "eb93f00ca66e88cf13340149c102b09b";
  }
  // headers['api_key'] = "6743e0681860ce20c8d98b7fcd76bd4b";
  // headers['api_id'] = "16e40d1ae463ca1fb138da75c41f3856";
  return AXIOS.create({
    // @ts-ignore
    accept: "application/json",
    baseURL: apiUrl,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
  });
}

function getProtectedInstanceForDownload(firstServer = true) {
  const user = localStorage.getItem("user");
  const headers: any = {};
  const apiUrl = firstServer ? parameters.ApiUrlOne : parameters.ApiUrlTwo;
  if (user) {
    headers["api_key"] = JSON.parse(user as string).apikey;
    headers["api_id"] = JSON.parse(user as string).apiId;
    // headers['api_key'] = "9857b26f64df7c67e7cb75d6cc857a78";
    // headers['api_id'] = "2feccf832a8bf2f6a23520230b5c2aba";
  }
  return AXIOS.create({
    // @ts-ignore
    // 'method': 'GET',
    accept: "application/octet-stream",
    baseURL: apiUrl,
    headers: {
      Accept: "application/octet-stream",
      "Content-Type": "application/octet-stream",
      ...headers,
    },
    responseType: "blob",
  });
}

function getProtectedInstanceForTherdServer(thirdServer = true) {
  const user = localStorage.getItem("user");
  const headers: any = {};
  const apiUrl = thirdServer ? parameters.ApiUrlThree : parameters.ApiUrlTwo;
  if (user) {
    headers["api_key"] = JSON.parse(user as string).apiKey;
    headers["api_id"] = JSON.parse(user as string).apiId;
    // headers['api_key'] = "9857b26f64df7c67e7cb75d6cc857a78";
    // headers['api_id'] = "2feccf832a8bf2f6a23520230b5c2aba";
  }
  return AXIOS.create({
    // @ts-ignore
    accept: "application/json",
    baseURL: apiUrl,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
  });
}

function getProtectedInstanceForTherdServerImage(thirdServer = true) {
  const user = localStorage.getItem("user");
  const headers: any = {};
  const apiUrl = thirdServer ? parameters.ApiUrlThree : parameters.ApiUrlTwo;
  if (user) {
    headers["api_key"] = JSON.parse(user as string).apiKey;
    headers["api_id"] = JSON.parse(user as string).apiId;
    // headers['api_key'] = "9857b26f64df7c67e7cb75d6cc857a78";
    // headers['api_id'] = "2feccf832a8bf2f6a23520230b5c2aba";

    // api key and id for upload document
    // headers['api_key'] = "63608e3167cef63aa47709c3235534ab";
    // headers['api_id'] = "eb93f00ca66e88cf13340149c102b09b";
  }
  return AXIOS.create({
    // @ts-ignore
    accept: "application/json",
    baseURL: apiUrl,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      ...headers,
    },
  });
}

function getProtectedInstanceForFinbox(headers = {}) {
  return AXIOS.create({
    // @ts-ignore
    accept: "application/json",
    // 'baseURL': apiUrl,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      ...headers,
    },
  });
}

function handleErrors(error: any) {
  let message = "Something went wrong!!";
  if (error && error.response && error?.response?.error) {
    let parsedError: any = "";
    message = error.response.data.error;
    if (typeof error.response.data.error === "string") {
      try {
        parsedError = JSON.parse(error.response.data.error);
        if (parsedError["errorMessage"]) {
          message = parsedError["errorMessage"];
        } else {
          const allErrors = Array();
          for (let i in parsedError) {
            allErrors.push(parsedError[i]);
          }
          message = allErrors.join(", ");
        }
      } catch (e) { }
    }
  } else if (error?.response?.data?.payload?.errorMessage) {
    message = error.response.data.payload.errorMessage;
  } else if (
    error &&
    error.response &&
    error.response.data &&
    error.response?.data?.message
  ) {
    return (message = error.response.data.message);
  } else if (
    error &&
    error.response &&
    error.response.data &&
    error?.response?.data?.errors
  ) {
    let e = Array();
    for (let i in error.response?.data?.errors) {
      e.push(`${i}: ${error.response.data.errors[i]}`);
    }
    message = e.join(",");
  } else if (typeof error === "string") {
    message = error;
  }
  return message;
}

async function singUp(
  loanProductShortCode: string,
  complementorUserId: String,
  params: any
) {
  const isCallLenderAPI = ["PERSONAL_LOAN_ONLY", "BUY_NOW_PAY_LATER"].includes(loanProductShortCode);
  const instance = isCallLenderAPI ? getProtectedInstanceForTherdServer() : getProtectedInstance();
  const isLoan = (loanProductShortCode?.length ?? 0) > 0;

  const urlMain = isCallLenderAPI ? "/complementor/" : ""
  let url =
    urlMain + API_CONFIG.SingUp +
    (isLoan ? `?loanProductShortCode=${loanProductShortCode}` : "");
  if ((complementorUserId?.length ?? 0) > 0) {
    url =
      url +
      (isLoan ? "&" : "?") +
      `sourceConnectorUserId=${complementorUserId}`;
  }
  return await instance.post(url, params);
}

async function verifyOtp(payload: any) {
  const instance = getProtectedInstance(false);
  return await instance.post(API_CONFIG.verifyOtp, payload);
}

async function getEnumValues() {
  const instance = getProtectedInstance(false);
  return await instance.get(API_CONFIG.enumValues);
}

// async function getPanDetails(params: any) {
//   const instance = getProtectedInstance(false);
//   return await instance.get(API_CONFIG.getPANDetails, { params });
// }

async function getPANdobDetails(params: any) {
  const instance = getProtectedInstance(false);
  return await instance.get(API_CONFIG.getPANdobDetails, { params });
}

async function checkPincode(params: any) {
  const instance = getProtectedInstance(false);
  return await instance.get("free/get/pin-code-details", { params });
}

async function addLog(params: any) {
  const instance = getProtectedInstance(false);
  return await instance.post("log/free/add",  params);
}

async function getLeadList(mobile: String, params: any, extraparams:string = "") {
  const instance = getProtectedInstance();
  return await instance.post(
    API_CONFIG.leadList +
    mobile +
    `?pageNo=${params?.pageNo}&pageSize=${params?.pageSize}&workFlowType=BORROWER_DIRECT${extraparams}`
  );
}

async function updateCase(caseId: String, params: any) {
  const instance = getProtectedInstance();
  return await instance.post(API_CONFIG.updateCase + caseId, params);
}

async function updateCaseStatus(caseId: String, params: any) {
  const instance = getProtectedInstance();
  return await instance.post(
    API_CONFIG.updateCaseStatus + caseId + "/status",
    params
  );
}
async function fileUpload(params: any) {
  const instance = getProtectedInstanceForTherdServerImage(false);
  return await instance.post("fileupload", params);
}

async function addDocument(params: any, documentSetId: any) {
  const instance = getProtectedInstanceForTherdServer(false);
  return await instance.post(
    API_CONFIG.addDocument +
    `${BUSINESS_ID}` +
    `?document_set_id=${documentSetId}`,
    params,
    {}
  );
}

async function showBankStatementAnalysis(documentSetId: any, caseId: any) {
  const instance = getProtectedInstance(false);
  return await instance.get(
    API_CONFIG.showBankStatementAnalysis +
    `${documentSetId}` +
    "?caseId=" +
    `${caseId}`
  );
}

async function showBankStatementAnalysisForEntityId(
  entityId: any,
  caseId: any
) {
  const instance = getProtectedInstance(false);
  return await instance.get(
    API_CONFIG.showBankStatementAnalysisForEntityId +
    `${entityId}` +
    "?caseId=" +
    `${caseId}`
  );
}

async function downloadDocument(businessId: any, documentId: any) {
  const instance = getProtectedInstanceForTherdServer(false);
  const url = `document-upload/get/${documentId}`;
  return await instance.get(url);
}

async function deleteDocument(businessId: any, documentSetId: any) {
  const instance = getProtectedInstanceForTherdServer(false);
  return await instance.post(
    API_CONFIG.deleteDoc + businessId + "/" + documentSetId
  );
}

async function getCompanyDetails(cinNumber: String) {
  const instance = getProtectedInstanceForTherdServer(false);
  return await instance.get(API_CONFIG.companyDetails + cinNumber);
}

async function getFinboxURL(userId: String) {
  const date = dayjs().subtract(1, "year").startOf("month");
  const toDate = dayjs().subtract(1, "month").endOf("month");
  const params = {
    link_id: userId,
    api_key: "sC5eBP7oZe8x0wF7fbqC0bY0EAQOjPRIWhztZIli",
    from_date: date.format("DD/MM/YYYY"),
    to_date: toDate.format("DD/MM/YYYY"),
    logo_url:
      "https://oneflo.in/static/media/dashboard-logo.4ffa136039642bb9b45b.jpeg",
  };
  // to_date
  const instance = getProtectedInstanceForFinbox();
  return await instance.post(
    "https://portal.finbox.in/bank-connect/v1/session/",
    params
  );
}

async function getDocumentHierarchyList(params: any) {
  const instance = getProtectedInstance(false);
  return await instance.get(API_CONFIG.documentHierarchyList, { params });
}

async function getDocumentList(documentSetId: any) {
  const instance = getProtectedInstance(false);
  return await instance.get(API_CONFIG.documentList + documentSetId + "/list");
}

async function getMobileOtp(mobile: any) {
  const instance = getProtectedInstance(false);
  return await instance.get(API_CONFIG.getMobileOtp + "?phoneNumber=" + mobile);
}

async function getNextTask(
  objectId: String,
  processInstanceId: String,
  complementorUserId: String,
  isCallLenderAPI: boolean,
  caseId?: String
) {
  const instance = isCallLenderAPI ? getProtectedInstanceForTherdServer() : getProtectedInstance();
  const urlMain = isCallLenderAPI ? "/complementor" : "/flowable"
  let url = `${urlMain}/get-next-task?objectId=${objectId ?? ""}&processInstanceId=${processInstanceId ?? ""}`;
  if ((complementorUserId?.length ?? 0) !== 0) {
    url = url + `&complementorUserId=${complementorUserId}`;
  }if ((caseId?.length ?? 0) !== 0) {
    url = url + `&caseId=${caseId}`;
  }
  return await instance.get(url);
}

async function getNextTaskV2(payload?: any) {
  const instance = getProtectedInstance();
  let url = `complementor-widget-workflow/get-task`;
  return await instance.post(url, payload);
}

async function getPreviousTask(taskId: String, complementorUserId: String, isCallLenderAPI: boolean) {
  const instance = isCallLenderAPI ? getProtectedInstanceForTherdServer() : getProtectedInstance();
  const urlMain = isCallLenderAPI ? "/complementor" : "/flowable"
  let url = urlMain + `/previous-task?taskId=${taskId}`;
  if ((complementorUserId?.length ?? 0) !== 0) {
    url = url + `&complementorUserId=${complementorUserId}`;
  }
  return await instance.get(url);
}

async function submitTask(payload: any, isCallLenderAPI: boolean) {
  const instance = isCallLenderAPI ? getProtectedInstanceForTherdServer() : getProtectedInstance();
  const url = isCallLenderAPI ? "/complementor" : "/flowable"
  return await instance.post(url + `/submit-task`, payload);
}

async function submitTaskV2(payload: any) {
  const instance = getProtectedInstance();
  return await instance.post(`complementor-widget-workflow/submit-task`, payload);
}

async function addCase(loanProductShortCode: String, complementorUserId: String, 
  encryptedData: String = "", params: any) {
  let instance = getProtectedInstance();
  // if(loanProductShortCode == "BUY_NOW_PAY_LATER") {
  //   instance = getProtectedInstanceForTherdServer();
  // }
  const isLoan = (loanProductShortCode?.length ?? 0) > 0;
  let url =
    "add/case" +
    (isLoan ? `?loanProductShortCode=${loanProductShortCode}` : "");
  if ((complementorUserId?.length ?? 0) > 0) {
    url =
      url +
      (isLoan ? "&" : "?") +
      `sourceConnectorUserId=${complementorUserId}`;
  }
  if ((encryptedData?.length ?? 0) > 0) {
    url =
      url +
      ((isLoan || (complementorUserId?.length ?? 0) > 0) ? "&" : "?") +
      `encryptedData=${encryptedData}`;
  }
  return await instance.post(url, params);
}

async function getLoanOffers(caseId: any) {
  const instance = getProtectedInstance();
  return await instance.get(`offers/case/${caseId}`);
}

async function getJourneyOffer(params: any) {
  const instance = getProtectedInstance();
  return await instance.get(`case/journey/offer`, {params});
}

async function updateProspect(prospectId: any, verificationId: any) {
  const instance = getProtectedInstance();
  return await instance.post(
    `verify-id/update-prospect?prospectId=${prospectId}&verificationId=${verificationId}`
  );
}

async function updateDropOfStage(prospectId: any, dropOfStage: any) {
  const instance = getProtectedInstance();
  return await instance.post(
    `comp/update-drop-of-stage?dropOfStage=${dropOfStage}&prospectId=${prospectId}`
  );
}

async function getAppContent(type: string) {
  const instance = getProtectedInstance(false);
  return await instance.get(
    `business-communication/get/free/app_content/${BUSINESS_ID}/${type}`
  );
}

async function getAnalysis(entityId: string, caseId: string) {
  const instance = getProtectedInstance(false);
  return await instance.get(
    `bank/statement/create-document/get-analysis/entity/${entityId}/case/${caseId}`
  );
}

async function getAnalysisCheck(caseId:string) {
  const instance = getProtectedInstance(false);
  return await instance.put(`bank/statement/test-data/get-analysis/case/${caseId}`);
}

async function  getShowFinboxStatus() {
  const instance = getProtectedInstance(false);
  return await instance.get('bank/show-widget/status');
}

async function getLenders(params?: any) {
  const instance = getProtectedInstanceForTherdServer();
  return await instance.get("dsa/lender/list/pl", { params });
}

async function updateLenderDetails(
  caseId: any,
  caseStatus: any,
  payload: any,
  productId: any
) {
  const instance = getProtectedInstanceForTherdServer();
  return await instance.post(
    `dsa/lender/case/${caseId}/status/${caseStatus}/lender-details/update?loanProductId=${productId}`,
    { ...payload }
  );
}

async function updateLenderAdditonalField(
  lenderId: string,
  caseId: string,
  productType: string,
  payload: any
) {
  const instance = getProtectedInstanceForTherdServer();
  const user = localStorage.getItem("user");
  let userId: any = "";
  if (user) {
    userId = JSON.parse(user as string).userId;
  }
  const url = `dsa/lender/${lenderId}/${productType}/case/${caseId}/${userId}`;
  return await instance.post(url, payload);
}

async function getConnectorShortDetails(shortCode: string, urlParams: string, params: any, connectorId?: any) {
  const isCallLenderAPI = ["pl", "bnpl"].includes(shortCode);
  const instance = isCallLenderAPI ? getProtectedInstanceForTherdServer() : getProtectedInstance();
  const urlMain = isCallLenderAPI ? "/complementor/" : ""
  return await instance.post(urlMain + "free/get/short-details/connector/" + connectorId + urlParams, params);
}

async function getPreFlowableProcess(shortCode: string, urlParams: string, params: any, connectorId?: any) {
  const instance = getProtectedInstance();
  return await instance.post("free/pre-flowable-process/connector" + urlParams, params);
}

async function getBuyLeadFilter(payload?: any) {
  const instance = getProtectedInstance();
  return await instance.post("buy-leads/filter/cost", payload );
}

async function getCities(cityType:any,params?: any) {
  const instance = getProtectedInstance();
  return await instance.get(`buy-leads/list/cities?cityType=${cityType}`, { params });
}

async function updateTransaction(transactionId:any,payload?: any) {
  const instance = getProtectedInstance();
  return await instance.put(`buy-leads/transaction/${transactionId}`, payload );
}

async function getBillingDetailsList(transactionId:any,params?: any) {
  const instance = getProtectedInstance();
  return await instance.get(`buy-leads/transaction/${transactionId}`, { params });
}

async function authoriseCase(payload?: any) {
  const instance = getProtectedInstance();
  return await instance.post(`free/authorise-case/`, payload);
}
async function getOtpVerificationStatus(payload: any) {
  const instance = getProtectedInstance();
  const url = 'check/otp-verification-required';
  return await instance.post(url, payload);
}
async function verifyOtpForIndiaMart(payload: any) {
  const instance = getProtectedInstance();
  const url = 'verify-otp/save-consent';
  return await instance.post(url, payload);
}
async function getIPData() {
  return await AXIOS.get("https://api.ipify.org/?format=json");
};
async function updateMobileNo(payload: any) {
  const instance = getProtectedInstance();
  const url = 'update/user-mobile';
  return await instance.post(url, payload);
}

async function listDocumentV2(caseId:string) {
  const instance = getProtectedInstance();
  const url = `list/document/case/${caseId}`;
  return await instance.get(url);
}

async function checkRecaptchaResponse(payload: any) {
  const instance = getPublicInstance(false);
  return await instance.post('free/check/re-captcha-is-required', payload);
}

const API_SERVICE = {
  handleErrors,
  singUp,
  verifyOtp,
  getEnumValues,
  getPANdobDetails,
  checkPincode,
  addLog,
  getLeadList,
  updateCase,
  fileUpload,
  addDocument,
  showBankStatementAnalysis,
  showBankStatementAnalysisForEntityId,
  downloadDocument,
  deleteDocument,
  getCompanyDetails,
  getFinboxURL,
  getDocumentHierarchyList,
  getDocumentList,
  getMobileOtp,
  addCase,
  getLoanOffers,
  getJourneyOffer,
  updateProspect,
  updateDropOfStage,
  updateCaseStatus,
  getAppContent,
  getAnalysis,
  getAnalysisCheck,
  getShowFinboxStatus,
  getNextTask,
  getNextTaskV2,
  getPreviousTask,
  submitTask,
  submitTaskV2,
  getLenders,
  updateLenderDetails,
  updateLenderAdditonalField,
  getConnectorShortDetails,
  getPreFlowableProcess,
  getBuyLeadFilter,
  getCities,
  updateTransaction,
  getBillingDetailsList,
  authoriseCase,
  getOtpVerificationStatus,
  verifyOtpForIndiaMart,
  getIPData,
  updateMobileNo,
  listDocumentV2,
  checkRecaptchaResponse
};

export default API_SERVICE;
