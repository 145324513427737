import { DatePicker, Form, Input, Space } from "antd";
import { Rule } from "antd/es/form";
import { useEffect, useState } from "react";

interface IFormDOB {
  name: string;
  type?: string;
  label?: string;
  tooltip?: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  defaultValue?: string;
  disableDate?:any;
  rules?: Rule[] | undefined;
  onChange: (day?: string, month?: string, year?: string) => void;
  className?:any;
}

const FormDOB = (props: IFormDOB) => {
  const {
    name,
    type,
    label,
    tooltip,
    isRequired,
    isDisabled,
    placeholder,
    defaultValue,
    disableDate,
    rules,
    onChange,
    className,
  } = props;

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    const date = defaultValue?.split("-") ?? [];
    console.log("Date: ", date);
    setYear(date[0]);
    setMonth(date[1]);
    setDay(date[2]);
  }, [defaultValue]);

  const checkDOB = (_: any, value: { number: number }) => {
    let isValid = true;

    if (day == "" && month == "" && year == "") {
      return Promise.reject(
        new Error(`Please Enter your ${label ?? placeholder}`)
      );
    } else if (day == "" || month == "" || year == "") {
      isValid = false;
    } else {
      let isNumDay = /^\d+$/.test(day);
      let isNumMonth = /^\d+$/.test(month);
      let isNumYear = /^\d+$/.test(year);
      if (!(isNumDay && isNumMonth && isNumYear)) {
        isValid = false;
      } else {
        const dayInt = parseInt(day);
        const monthInt = parseInt(month);
        const yearInt = parseInt(year);
        if (dayInt <= 0 || monthInt <= 0 || yearInt <= 1900) {
          isValid = false;
        } else if (
          dayInt > 31 ||
          monthInt > 12 ||
          yearInt > new Date().getFullYear()
        ) {
          isValid = false;
        }
      }
    }

    if (isValid) {
      return Promise.resolve();
    } else {
      return Promise.reject(
        new Error(`Please Enter valid ${label ?? placeholder}`)
      );
    }
  };

  return (
    <Form.Item
    className={className}
      name={name}
      label={label}
      tooltip={tooltip}
      rules={[
        {
          required: isRequired,
          message: `Please Enter your ${label ?? "Date"}`,
        },
        ...(rules ?? []),
        // { validator: checkDOB },
      ]}
    >
      <DatePicker
        disabledDate={disableDate}
        // className="custom-input"
        style={{ width: "100%" }}
        placeholder="DD/MM/YYYY"
        size="large"
        format={"DD/MM/YYYY"}
        // onChange={() => setIsChangeCaseField(true)}
      />
      {/* <Space>
        <Input
          size="large"
          type={type ?? "text"}
          placeholder="DD"
          disabled={isDisabled}
          value={day}
          onChange={(e) => {
            setDay(e.target.value);
            onChange(e.target.value, month, year);
          }}
        />
        <Input
          size="large"
          type={type ?? "text"}
          placeholder="MM"
          disabled={isDisabled}
          value={month}
          onChange={(e) => {
            setMonth(e.target.value);
            onChange(day, e.target.value, year);
          }}
        />
        <Input
          size="large"
          type={type ?? "text"}
          placeholder="YYYY"
          disabled={isDisabled}
          value={year}
          onChange={(e) => {
            setYear(e.target.value);
            onChange(day, month, e.target.value);
          }}
        />
      </Space> */}
    </Form.Item>
  );
};

export default FormDOB;
