import React, { useCallback, useState } from "react";
import eligibility from "../../assets/eligibility.svg";
import icicLombard from "../../assets/icicLombard.svg";
import { Button } from "antd";
import "./iciciLombard.scss";
import { updateDropOfStage } from "../../services/Utility";
import { loanTypeList } from "../start/start";

interface IcicLombard {
  loanType: string;
}

const IcicLombard: React.FunctionComponent<IcicLombard> = (
  props: IcicLombard
) => {
  const { loanType } = props;

  const getLabel = (key: string) => {
    return loanTypeList?.find((item: any) => item?.key == key)?.label ?? "";
  };

  const href =
    loanType == "HEALTH_INSURANCE"
      ? "https://www.ilgi.co/23D1E76016"
      : loanType == "FOUR_WHEELER_INSURANCE"
      ? "https://www.ilgi.co/594A0305AB"
      : "https://www.ilgi.co/5DE4860E66";

  return (
    <>
      <div style={{ display: "grid", justifyContent: "center" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={icicLombard} />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={eligibility} />
        </div>
        <div
          style={{
            width: "100%",
            color: "green",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            style={{ fontSize: "30px", marginBottom: "0px", marginTop: "10px" }}
          >
            Congratulations!
          </p>
          <p
            style={{
              fontSize: "20px",
              width: "75%",
              fontWeight: "375",
              marginTop: "15px",
              marginBottom: "20px",
            }}
          >
            {`You are qualified for a ${getLabel(
              loanType
            )} policy with ICICI Lombard`}
          </p>
          <a
            className="btn-complete-now"
            href={href}
            target="_blank"
            onClick={() => {
              updateDropOfStage("VIEW_APPLICATION");
            }}
          >
            Complete Now
          </a>
        </div>
      </div>
    </>
  );
};

export default IcicLombard;
