import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  List,
  Row,
  Typography,
  notification,
} from "antd";
import React, { useState, useEffect } from "react";
import FormInput from "../../components/FormInput/FormInput";
import FormSelect from "../../components/FormSelect/FormSelect";
import CouponModal from "./CouponModal";
import FromRadioButton from "../../components/FormRadioButton/FormRadioButton";
import FormCheckbox from "../../components/FormCheckbox/FormCheckbox";
import { stages } from "../complementor-widget/ComplementorWidget";
import BillingDetails from "./BillingDetails";
import API_SERVICE from "../../services/api-service";
import { NUMBER_REGEX } from "../../constants/AppConstants";
import "./BuyLeads.scss";

const { Title } = Typography;

interface IBuyLeads {
  isMobile: boolean;
  setSelectedStageName: React.Dispatch<React.SetStateAction<stages>>;
}

const BuyLeads: React.FunctionComponent<IBuyLeads> = (props: IBuyLeads) => {
  const { setSelectedStageName, isMobile } = props;

  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [applicantTypeValue, setApplicantTypeValue] = useState("INDIVIDUAL");
  const [employmentTypeValue, setEmploymentTypeValue] = useState("SALARIED");
  const [cityTypeValue, setCityTypeValue] = useState("ALL");
  const [fetchDataApply, setFetchDataApply] = useState(null as any);
  const [openBillingDetails, setOpenBillingDetails] = useState(false);
  const [cityNameList, setCityNameList] = useState(null as any);
  const [costPerLead, setCostPerLead] = useState(0);
  const [noOfLead, setNoOfLead] = useState(0);
  const [price, setPrice] = useState(0);
  const [filterDataList, setFilterDataList] = useState(null as any);
  const [updateTransactionList, setUpdateTransactionList] = useState(
    null as any
  );
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [formFilterData] = Form.useForm() as any;

  const userStr = localStorage.getItem("user");
  const user = JSON.parse(userStr as string);

  useEffect(() => {
    formFilterData.setFieldsValue({
      cityType: "ALL",
      applicantType: "INDIVIDUAL",
      employmentType: "SALARIED",
      gender: "ALL",
    });
  }, []);

  const ageOption: any = [
    { key: "ALL", value: "All" },
    { key: "GREATER_THAN_0_LESS_THAN_EQUAL_TO_18", value: "0 < Age <= 18" },
    { key: "GREATER_THAN_18_LESS_THAN_EQUAL_TO_30", value: "18 < Age <= 30" },
    { key: "GREATER_THAN_30_LESS_THAN_EQUAL_TO_45", value: "30 < Age <= 45" },
    { key: "GREATER_THAN_45", value: "45 < Age" },
  ];

  const genderOption: any = [
    { key: "ALL", value: "All" },
    { key: "MALE", value: "Male" },
    { key: "FEMALE", value: "Female" },
    { key: "OTHER", value: "Other" },
  ];

  const monthlySalaryList: any = [
    { key: "ALL", value: "All" },
    {
      key: "GREATER_THAN_0_LESS_THAN_EQUAL_TO_15000",
      value: "0 < Month Salary <= 15000",
    },
    {
      key: "GREATER_THAN_15000_LESS_THAN_EQUAL_TO_30000",
      value: "15000 < Monthly Salary <= 30000",
    },
    {
      key: "GREATER_THAN_30000_LESS_THAN_EQUAL_TO_50000",
      value: "30000 < Monthly Salary <= 50000",
    },
    { key: "GREATER_THAN_50000", value: "50000 < Monthly Salary" },
  ];

  const annualTurnoverList: any = [
    { key: "ALL", value: "All" },
    { key: "LESS_THAN_10_LACS", value: "Turnover < 10L" },
    {
      key: "GREATER_THAN_EQUAL_TO_10L_LESS_THAN_25L",
      value: "10L <= Turnover < 25L",
    },
    {
      key: "GREATER_THAN_EQUAL_TO_25L_LESS_THAN_TO_50L",
      value: "25L <= Turnover < 50L",
    },
    {
      key: "GREATER_THAN_EQUAL_TO_50L_LESS_THAN_TO_3CR",
      value: "50L <= Turnover < 3CR",
    },
    { key: "GREATER_THAN_EQUAL_TO_3CR", value: ">= 3CR" },
  ];

  const applicantTypeChange = (e: any) => {
    setApplicantTypeValue(e.target.value);
    formFilterData.resetFields(["age", "monthlySalary", "annualTurnover"]);
  };

  const employmentTypeChange = (e: any) => {
    setEmploymentTypeValue(e.target.value);
    formFilterData.resetFields(["age", "monthlySalary", "annualTurnover"]);
  };

  const onBillingDetails = (value) => {
    const payload = {
      ...filterDataList,
      requiredNumberOfLeads: value?.requiredNumberOfLeads,
      dropOffStage: "PURCHASE_ORDER",
    };
    updateTransaction(payload, true);
  };

  const updateTransaction = (payload: any, moveNext: boolean = false) => {
    setUpdateTransactionList(payload);
    API_SERVICE.updateTransaction(user.buyLeadsTransactionId, payload)
      .then(({ data }) => {
        if (moveNext) {
          setOpenBillingDetails(true);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const getCities = () => {
    const params = {
      pageNo: 1,
      pageSize: 500,
    };

    API_SERVICE.getCities(formFilterData.getFieldValue("cityType"), params)
      .then(({ data }) => {
        const cityListData = data?.payload?.content?.map(
          (value: any, key: any) => ({ key: value, value })
        );
        setCityNameList([{ key: "ALL", value: "All" }, ...cityListData]);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const onFilterData = (value: any) => {
    if (isFilterApplied) {
      setIsFilterApplied(false);
      return false;
    }

    const payload = {
      cities: value?.cityList,
      dropOffStage: "FILTERS_APPLIED",
      filterMap: {
        EXCLUSIVE_LEADS: value?.primeLead ? "YES" : "NO",
        VERIFIED_NUMBER: value?.verifyNumber ? "YES" : "NO",
        LOCATION: value?.cityType,
        APPLICATION_TYPE: value?.applicantType,
        AGE: value?.age,
        EMPLOYMENT_TYPE: value?.employmentType,
        GENDER: value?.gender,
        MONTHLY_SALARY: value?.monthlySalary,
        ANNUAL_TURNOVER: value?.annualTurnover,
      },
    };
    API_SERVICE.getBuyLeadFilter(payload)
      .then(({ data }) => {
        setIsFilterApplied(true);
        setCostPerLead(data?.payload);
        setFilterDataList(payload);
        // updateDropOfStageBuyLead("FILTERS_APPLIED");
        updateTransaction(payload);
        if (noOfLead > 0) {
          setPrice(data?.payload * noOfLead);
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      });
  };

  const GSTCount = (price - (fetchDataApply?.maxDiscount ?? 0)) * (18 / 100);
  const total =
    price - (fetchDataApply?.maxDiscount ?? 0) + Math.round(GSTCount);

  const listData = [
    <>
      <FormCheckbox
        lable="Prime Lead/s"
        name="primeLead"
        tooltip="Select this option to purchase exclusive leads which have not been shared with any other partners."
        className="checkBox-style"
        disabled={isFilterApplied}
      />
      <FormCheckbox
        name="verifyNumber"
        lable="Verified Number"
        className="checkBox-style"
        disabled={isFilterApplied}
      />
    </>,

    <FromRadioButton
      label="City Type"
      name="cityType"
      isDisabled={isFilterApplied}
      radioList={[
        { key: "ALL", value: "All" },
        { key: "METRO", value: "Metro" },
        { key: "NON_METRO", value: "Non-Metro" },
      ]}
      onChange={(e) => {
        formFilterData.resetFields(["cityList"]);
        setCityTypeValue(e?.target?.value);
        if (e?.target?.value !== "ALL") {
          getCities();
        }
      }}
    />,
    <>
      {cityTypeValue != "ALL" ? (
        <FormSelect
          label="City List"
          name="cityList"
          placeholder="Select City"
          options={cityNameList}
          mode="multiple"
          maxTagCount="responsive"
          isRequired
          disabled={isFilterApplied}
          onChange={(e) => {
            if (e?.includes("ALL")) {
              if (e?.at(0) == "ALL" && e?.length > 1) {
                const tmp = e?.slice(1);
                formFilterData.setFieldValue("cityList", tmp);
              } else {
                formFilterData.resetFields(["cityList"]);
                formFilterData.setFieldValue("cityList", ["ALL"]);
              }
            }
          }}
        />
      ) : null}
    </>,
    <FromRadioButton
      label="Applicant Type"
      name="applicantType"
      isDisabled={isFilterApplied}
      radioList={[
        { key: "INDIVIDUAL", value: "Individual" },
        { key: "BUSINESS", value: "Business" },
      ]}
      onChange={applicantTypeChange}
    />,
    <>
      {applicantTypeValue === "INDIVIDUAL" && (
        <FromRadioButton
          label="Employment Type"
          name="employmentType"
          isDisabled={isFilterApplied}
          radioList={[
            { key: "SALARIED", value: "Salaried" },
            { key: "SELF_EMPLOYED", value: "Self-Employed" },
          ]}
          onChange={employmentTypeChange}
        />
      )}
    </>,
    <>
      {applicantTypeValue === "INDIVIDUAL" && (
        // <FromRadioButton
        //   label="Gender"
        //   name="gender"
        //   isDisabled={isFilterApplied}
        //   radioList={genderOption}
        // />
        <FormSelect
          label="Gender"
          name="gender"
          disabled={isFilterApplied}
          placeholder="Select Gender"
          options={genderOption}
        />
      )}
    </>,
    <>
      {applicantTypeValue === "INDIVIDUAL" &&
        employmentTypeValue === "SALARIED" && (
          <FormSelect
            label="Age"
            name="age"
            disabled={isFilterApplied}
            placeholder="Select Age"
            options={ageOption}
            isRequired
          />
        )}
    </>,
    <>
      {applicantTypeValue === "INDIVIDUAL" &&
        employmentTypeValue === "SALARIED" && (
          <FormSelect
            label="Monthly Salary"
            name="monthlySalary"
            placeholder="Select Monthly Salary"
            disabled={isFilterApplied}
            options={monthlySalaryList}
            isRequired
          />
        )}
    </>,
    <>
      {((applicantTypeValue === "INDIVIDUAL" &&
        employmentTypeValue === "SELF_EMPLOYED") ||
        applicantTypeValue === "BUSINESS") && (
        <FormSelect
          label="Annual Turnover"
          name="annualTurnover"
          disabled={isFilterApplied}
          placeholder="Select Annual Turnover"
          options={annualTurnoverList}
          isRequired
        />
      )}
    </>,
    <Form.Item label=" ">
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        className="filter-btn"
      >
        {isFilterApplied ? "Edit Filter" : "Apply Filter"}
      </Button>
    </Form.Item>,
  ];

  const listDataFilter = listData.filter((item: any) => {
    return item?.props?.children === false;
  });
  const finalListData = listData.filter((item: any) => {
    return !listDataFilter.includes(item);
  });

  const renderBuyLead = () => {
    return (
      <div className="main-div">
        <div style={{ width: isMobile ? "" : "65%" }}>
          <Card title="Filter By" className="card-style">
            <div>
              <Form
                form={formFilterData}
                layout="vertical"
                onFinish={onFilterData}
              >
                <List
                  className="list-Filter-By"
                  grid={{ gutter: 0, column: 3, xs: 1, sm: 1, md: 1, lg: 3 }}
                  dataSource={finalListData}
                  renderItem={(item: any) => <List.Item>{item}</List.Item>}
                />
              </Form>
            </div>
          </Card>

          <Card className="card-style" style={{ paddingInline: "10px" }}>
            <Form layout="vertical" onFinish={onBillingDetails}>
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={13}
                  className="card-column"
                  style={{ paddingInline: "10px" }}
                >
                  <div>
                    <Title level={4} className="card-title">
                      Cost Per Lead
                    </Title>
                    <b>
                      <h3 className="card-value-title">
                        {costPerLead == 0 ? "-" : `Rs. ${costPerLead}`}
                      </h3>
                    </b>
                  </div>
                  <Row className="card-input-div">
                    <Col span={24}>
                      <FormInput
                        label="Required No. of Leads"
                        name="requiredNumberOfLeads"
                        placeholder="Enter Leads"
                        isRequired
                        isDisabled={!isFilterApplied}
                        rules={[
                          {
                            pattern: NUMBER_REGEX,
                            message: "Please enter number only ",
                          },
                          () => ({
                            validator(_, value) {
                              if (value?.length > 0 && value == 0) {
                                return Promise.reject(
                                  "Please enter valid Requried No. of Leads"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        onChange={(e: any) => {
                          setNoOfLead(e.target.value);
                          setPrice(costPerLead * e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={1} className="divider-class">
                  <Divider type="vertical" className="divider-hight" />
                </Col>
                <Col xs={24} sm={24} md={24} lg={10}>
                  <div className="summary-div">
                    <div>
                      <Title level={4} className="summary-title">
                        Payment Summary
                      </Title>
                    </div>
                    <div>
                      <div className="price-style">
                        <label>Price</label>
                        {noOfLead > 0 && <label>{`Rs.${price}`}</label>}
                      </div>
                      {fetchDataApply?.maxDiscount.length > 0 && (
                        <>
                          <hr className="devider-space" />
                          <div className="price-style">
                            <label>Item Discount</label>
                            <label style={{ color: "green" }}>
                              -Rs.{fetchDataApply?.maxDiscount}
                            </label>
                          </div>
                        </>
                      )}
                      <hr className="devider-space" />
                      <div className="price-style">
                        <label>GST(18%)</label>
                        {noOfLead > 0 && (
                          <label>+ Rs.{Math.round(GSTCount)}</label>
                        )}
                      </div>
                      <hr className="devider-space" />
                      <b>
                        <div className="total-price-style">
                          <label>Final Price</label>
                          {noOfLead > 0 && <label>Rs.{total}</label>}
                        </div>
                      </b>
                      <div>
                        <div className="buyLead-btn">
                          <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className="buyLead-width"
                            disabled={!isFilterApplied}
                          >
                            Proceed Now
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <>
      {openBillingDetails ? (
        <BillingDetails
          isMobile={isMobile}
          costPerLead={costPerLead}
          updateTransactionList={updateTransactionList}
          setSelectedStageName={setSelectedStageName}
          numberOfLeads={noOfLead}
        />
      ) : (
        renderBuyLead()
      )}
      {openCouponModal && (
        <CouponModal
          openCouponModal={openCouponModal}
          setOpenCouponModal={setOpenCouponModal}
          setFetchDataApply={setFetchDataApply}
          isMobile={isMobile}
        />
      )}
    </>
  );
};

export default BuyLeads;
