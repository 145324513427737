import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    notification,
    Row,
    Space,
    Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import API_SERVICE from "../../services/api-service";
import iconSearch from "../../assets/search.png";
import iconSearchWhite from "../../assets/search-white.svg";
import UploadDocument from "../../screens/upload-documents/UploadDocument";
import DocCategoryV2 from "./DocCategoryV2";
import './doc-category.scss'
import { isRazorPay } from "../../services/Utility";

interface IDocument {
    setIsLoading?: any;
    leadData?: any;
    getTaskData?: any;
    submitTask?: any;
    isCurrentDocument?: any;
    setCurrentStage?: any;
    complementorUserId?: any;
    isLoading?: any;
}

const Document: React.FunctionComponent<IDocument> = (props: IDocument) => {
    const {
        setIsLoading,
        leadData,
        getTaskData,
        submitTask,
        isCurrentDocument,
        setCurrentStage,
        complementorUserId,
        isLoading
    } = props;

    const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
    const [showUploadDocument, setShowUploadDocument] = useState(false);
    const [documentListCat, setDocumentListCat] = useState([] as any);
    const [filterDocumentList, setFilterDocumentList] = useState([]) as any;
    const [docLoader, setDocLoader] = useState(false);


    useEffect(() => {
        getDocumentList();
    }, []);

    const getDocumentList = (isLoading = true) => {
        setIsLoading(true);
        setDocLoader(true);
        API_SERVICE.listDocumentV2(leadData?.caseDetails?.caseId)
            .then(({ data }) => {
                if (data) {
                    const docConfig = data?.payload?.documentConfigDetails
                    setDocumentListCat(docConfig);
                    setFilterDocumentList(docConfig);
                    let tmpIsDocAvailable = false
                    docConfig?.map((item: any) => {
                        item?.documentTypeWithUploadedDetails.map((data: any) => {
                            if (data && data?.documentDetails != null) {
                                tmpIsDocAvailable = true

                            }
                        })
                    });
                    // setIsDocumentAvailable(tmpIsDocAvailable)
                }
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            })
            .finally(() => {
                setIsLoading(false)
                setDocLoader(false);
            });
    };

    const handleSearch = (value: any) => {
        let documentTypeNameFilter = documentListCat?.map((item: any) => {
            const filterData = item?.documentTypeWithUploadedDetails?.filter(
                (data: any) => {
                    return data?.documentTypeName
                        ?.toLowerCase()
                        ?.includes(value?.searchParam?.toLowerCase());
                }
            );
            return { ...item, documentTypeWithUploadedDetails: filterData };
        });
        let getDocumentCategroy = documentTypeNameFilter?.filter((item: any) => {
            return item?.documentTypeWithUploadedDetails?.length > 0;
        });
        setFilterDocumentList(getDocumentCategroy);
    };

    const actionOnDelete = (doc: any) => {
        setIsLoading(true);
        API_SERVICE.deleteDocument(
            doc.documentDetails.businessId,
            doc.documentDetails.businessDocumentId
        )
            .then(({ data }) => {
                getDocumentList();
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
                setIsLoading(false);
            });
    };

    const actionAdd = (doc, index, cat, indexCat) => {
        const docList = cat?.documentTypeWithUploadedDetails ?? [];

        const docListTmp = [
            ...docList.slice(0, index + 1),
            { ...doc, documentDetails: null },
            ...docList.slice(index + 1),
        ];

        const tmp = [...filterDocumentList];
        tmp[indexCat] = { ...cat, documentTypeWithUploadedDetails: docListTmp };
        setFilterDocumentList(tmp);
    };



    const renderUploadDocument = () => {
        return (
            <Modal
                className={isRazorPay(complementorUserId) ? 'razor_pay_modal' : ''}
                title="Upload New Document:"
                centered
                width={400}
                onCancel={() => setShowUploadDocument(false)}
                footer={null}
                visible={showUploadDocument}
            >
                <UploadDocument
                    selectedType={selectedDocument?.documentTypeType ?? ""}
                    documentTypeId={selectedDocument?.documentTypeId}
                    formatDocument={
                        selectedDocument?.appliedFileTypes?.map((v: any) =>
                            v.toLowerCase()
                        ) ?? ""
                    }
                    maxSize={selectedDocument?.maximumSizeInMB ?? 10}
                    loanProductId={leadData?.loanProductId}
                    documentSetId={leadData?.caseDetails?.documentSetId}
                    individualType={leadData?.applicationSubType}
                    complementorUserId={complementorUserId}
                    callBack={(isUpload) => {
                        setShowUploadDocument(false);
                        if (isUpload) {
                            getDocumentList();
                        }
                    }}
                />
            </Modal>
        );
    };

    const renderDocumentSearch = () => (
        <Form
            layout="inline"
            style={{ marginBottom: "10px" }}
            onFinish={handleSearch}
        >
            <div className="doc-header-msg">
                <span>Uploading your KYC documents expedites your loan process. However, this is optional.</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex' }}>
                    <Form.Item name="searchParam">
                        <Input
                            size="large"
                            style={{ borderRadius: "8px" }}
                            prefix={<img src={isRazorPay(complementorUserId) ? iconSearchWhite : iconSearch} alt="" width={17} />}
                            placeholder="Search Name"
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" size="large">
                            Search
                        </Button>
                    </Form.Item>
                </div>
            </div>
        </Form>
    );

    return (
        <>
            {showUploadDocument && renderUploadDocument()}
            {renderDocumentSearch()}
            {docLoader &&
               <div className={"loader-view" + (isRazorPay(complementorUserId) ? ' loader-view-new' :'')}>
               <Spin
                 tip="We are processing your request, please wait"
                 size="large"
               />
             </div>
            }
            <div>
                <Row style={{ paddingInline: "16px" }}>
                    <Col span={7} className={isRazorPay(complementorUserId) ? 'razorPay-uploadText' : ''}>{"Name"}</Col>
                    <Col span={7} className={isRazorPay(complementorUserId) ? 'razorPay-uploadText' : ''}>{"File Name"}</Col>
                    <Col span={4} className={"h-center" + (isRazorPay(complementorUserId) ? ' razorPay-uploadText' : '')}>
                        {"Format"}
                    </Col>
                    <Col span={3} className={"h-center" + (isRazorPay(complementorUserId) ? ' razorPay-uploadText' : '')}>
                        {"Max Size"}
                    </Col>
                    <Col span={3} style={{ textAlign: "center" }} className={"h-center" + (isRazorPay(complementorUserId) ? ' razorPay-uploadText' : '')}>
                        {"Actions"}
                    </Col>
                </Row>

                {filterDocumentList?.map((item, indexCat: number) => {
                    return (
                        <DocCategoryV2
                            documentCategoryList={item}
                            actionOnDelete={actionOnDelete}
                            actionOnUpload={(doc) => {
                                setSelectedDocument(doc);
                                setShowUploadDocument(true);
                            }}
                            actionAdd={(doc, index) => {
                                actionAdd(doc, index, item, indexCat);
                            }}
                            complementorUserId={complementorUserId}
                        />
                    );
                })}
            </div>
        </>
    );
};

export default Document;
